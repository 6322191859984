export const getLoginUrl = (loginBaseUrl: string, context: string): string => {
  const loginUrl =
    context === 'privatperson' ? loginBaseUrl : loginBaseUrl + '/arbetsgivare'

  return loginUrl
}

export const getLoggedInContext = (
  role: string,
  visitorContext: string
): string => {
  switch (role) {
    case 'klient':
      return 'privatperson'
    case 'kund':
      return 'arbetsgivare'
    case 'local':
      return 'medarbetare'
    default:
      return visitorContext
  }
}

export const getLoggedInProfileUrl = (context: string): string => {
  if (context === 'arbetsgivare') {
    return '/trr-foretag'
  } else if (context === 'medarbetare') {
    return '/medarbetare'
  } else {
    return '/mitt-trr'
  }
}
