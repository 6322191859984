import {
  AccessTokenCallback,
  SigninRedirectArgs,
  SigninSilentArgs,
  SignoutRedirectArgs,
  UserLoadedCallback,
  UserManager,
} from 'oidc-client-ts'

import {
  klientUserManager,
  kundUserManager,
  medarbetareUserManager,
} from './clientManagers'

type IdServerUserResponse = Promise<{
  id_token: string
  access_token: string
  expired: boolean
  expires_at: number
  profile: {
    roles: string[]
    idp: string
    name: string
    given_name: string
    sub: string
    iat: number
  }
  state: {
    redirectUrl?: string
    arbetsgivarinfoLinkId?: string | null
    refreshUserProfile?: boolean
    expiringTokenWasRefreshed?: boolean
  }
}>

type ExtendedSigninSilentArgs = SigninSilentArgs & {
  state: Awaited<IdServerUserResponse>['state']
}

export type IdServerUser = Awaited<IdServerUserResponse>

// @ts-expect-error Patch window for test purposes
window.signInSilent = async () => {
  const userManager = await getCurrentUserManager()
  userManager.signinSilent().then(console.log).catch(console.log)
}

let currentUserManager: UserManager = undefined

export const setKundUserManager = () => {
  currentUserManager = kundUserManager
}

export const setKlientUserManager = () => {
  currentUserManager = klientUserManager
}

export const setMedarbetareUserManager = () => {
  currentUserManager = medarbetareUserManager
}

export const getCurrentUserManager = async (): Promise<UserManager> => {
  const klientUser = await klientUserManager.getUser()
  const kundUser = await kundUserManager.getUser()
  const medarbetareUser = await medarbetareUserManager.getUser()
  if (klientUser !== null) {
    currentUserManager = klientUserManager
  } else if (kundUser !== null) {
    currentUserManager = kundUserManager
  } else if (medarbetareUser !== null) {
    currentUserManager = medarbetareUserManager
  }
  if (currentUserManager === undefined) {
    currentUserManager = klientUserManager
  }

  return currentUserManager
}

const userManagerAdapter = {
  getUser: async () => {
    const userManager = await getCurrentUserManager()
    return userManager.getUser() as unknown as IdServerUserResponse
  },
  removeUser: async () => {
    const userManager = await getCurrentUserManager()
    return userManager.removeUser()
  },
  clearStaleState: async () => {
    const userManager = await getCurrentUserManager()
    return userManager.clearStaleState()
  },
  signoutRedirect: async (args?: SignoutRedirectArgs) => {
    const userManager = await getCurrentUserManager()
    return userManager.signoutRedirect(args)
  },
  signinRedirect: async (args?: SigninRedirectArgs) => {
    const userManager = await getCurrentUserManager()
    return userManager.signinRedirect(args)
  },
  signinRedirectCallback: async (url?: string) => {
    const userManager = await getCurrentUserManager()
    return userManager.signinRedirectCallback(
      url
    ) as unknown as IdServerUserResponse
  },
  signinSilent: async (args?: ExtendedSigninSilentArgs) => {
    const userManager = await getCurrentUserManager()
    return userManager.signinSilent(args) as unknown as IdServerUserResponse
  },
  startSilentRenew: () => {
    void getCurrentUserManager().then((userManager) =>
      userManager.startSilentRenew()
    )
  },
  stopSilentRenew: () => {
    void getCurrentUserManager().then((userManager) =>
      userManager.stopSilentRenew()
    )
  },
  events: {
    addUserLoaded: (
      callback: (user: Awaited<IdServerUserResponse>) => void
    ) => {
      const removeKlientUserLoaded = klientUserManager.events.addUserLoaded(
        callback as unknown as UserLoadedCallback
      )
      const removeKundUserLoaded = kundUserManager.events.addUserLoaded(
        callback as unknown as UserLoadedCallback
      )
      const removeMedarbetareUserLoaded =
        medarbetareUserManager.events.addUserLoaded(
          callback as unknown as UserLoadedCallback
        )
      return () => {
        removeKlientUserLoaded()
        removeKundUserLoaded()
        removeMedarbetareUserLoaded()
      }
    },
    removeUserLoaded: (
      callback: (user: Awaited<IdServerUserResponse>) => void
    ) => {
      klientUserManager.events.removeUserLoaded(
        callback as unknown as UserLoadedCallback
      )
      kundUserManager.events.removeUserLoaded(
        callback as unknown as UserLoadedCallback
      )
      medarbetareUserManager.events.removeUserLoaded(
        callback as unknown as UserLoadedCallback
      )
    },
    addAccessTokenExpired: (callback: AccessTokenCallback) => {
      const removeKlientTokenExpired =
        klientUserManager.events.addAccessTokenExpired(callback)
      const removeKundTokenExpired =
        kundUserManager.events.addAccessTokenExpired(callback)
      const removeMedarbetareTokenExpired =
        medarbetareUserManager.events.addAccessTokenExpired(callback)
      return () => {
        removeKlientTokenExpired()
        removeKundTokenExpired()
        removeMedarbetareTokenExpired()
      }
    },
    removeAccessTokenExpired: (
      callback: (callback: AccessTokenCallback) => void
    ) => {
      klientUserManager.events.removeAccessTokenExpired(callback)
      kundUserManager.events.removeAccessTokenExpired(callback)
      medarbetareUserManager.events.removeAccessTokenExpired(callback)
    },
    addAccessTokenExpiring: (callback: AccessTokenCallback) => {
      const removeKlientAccessTokenExpiring =
        klientUserManager.events.addAccessTokenExpiring(callback)
      const removeKundAccessTokenExpiring =
        kundUserManager.events.addAccessTokenExpiring(callback)
      const removeMedarbetareAccessTokenExpiring =
        medarbetareUserManager.events.addAccessTokenExpiring(callback)

      return () => {
        removeKlientAccessTokenExpiring()
        removeKundAccessTokenExpiring()
        removeMedarbetareAccessTokenExpiring()
      }
    },
    removeAccessTokenExpiring: (callback: AccessTokenCallback) => {
      klientUserManager.events.removeAccessTokenExpiring(callback)
      kundUserManager.events.removeAccessTokenExpiring(callback)
      medarbetareUserManager.events.removeAccessTokenExpiring(callback)
    },
  },
}

export default userManagerAdapter
