import userManager from 'features/Authentication/userManager'
import { getCookieValue } from 'utils/cookies'

const trrUseOptimizelyNext = () => {
  const isCookieSet = getCookieValue('trr-use-optimizely-next') === 'true'

  return isCookieSet ? { 'trr-use-optimizely-next': 'true' } : {}
}

// append header to bypass cache if cookie set
const shouldUseCache = () =>
  sessionStorage.getItem('trr-force-pass') === null
    ? {}
    : { 'x-trr-force-pass': 'true' }

// Loopback parameter for previewing content in Episervers edit-mode
const hasLoopback = () =>
  new URLSearchParams(window.location.search).get('lb') === null
    ? {}
    : { lb: new URLSearchParams(window.location.search).get('lb') }

const setupHeaders = async (headers: Headers) => {
  const user = await userManager.getUser()
  const isInvalidUser =
    user === null || user.expires_at < Math.floor(Date.now() / 1000)

  const options = {
    ...shouldUseCache(),
    ...hasLoopback(),
    ...trrUseOptimizelyNext(),
    'Accept-Language': 'sv',
    ...(!isInvalidUser && { Authorization: `Bearer ${user.id_token}` }),
  }

  Object.entries(options).forEach((opt) => headers.set(opt[0], opt[1]))

  return headers
}

export default setupHeaders
