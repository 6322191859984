import {
  Article as ArticlePage,
  Function as FunctionPage,
  FullFunction as FunctionPageFull,
  Block as BlockPage,
  Guide as GuidePage,
  Faq as FaqPage,
  News as NewsPage,
  PostList as PostListPage,
  NewsList as NewsListPage,
  Dashboard as DashboardPage,
  PublicLanding as PublicLandingPage,
  Collection as CollectionPage,
} from '../PageTypes'

interface IPageTypeMap {
  [key: string]: unknown
}

const pageTypeMap: IPageTypeMap = {
  ArticlePage,
  FunctionPage,
  FunctionPageFull,
  BlockPage,
  GuidePage,
  FaqPage,
  NewsPage,
  PostListPage,
  NewsListPage,
  DashboardPage,
  PublicLandingPage,
  CollectionPage,
}

export default pageTypeMap
