import { useRef, useEffect } from 'react'
import { getWashedDocumentReferrer } from '../../Helpers'
import { useHistory, useLocation } from 'react-router-dom'
import { type LocationDescriptor } from 'history'

const blockedReferrer = 'blockedReferrer'

type BlockedReferrer = typeof blockedReferrer

export const useReplaceWithoutDocumentReferrer = () => {
  const history = useHistory<BlockedReferrer>()
  const blockedReferrerReplace = (
    location: LocationDescriptor<BlockedReferrer>
  ) => history.replace(location, blockedReferrer)
  return { blockedReferrerReplace }
}

const useOverrideDocumentReferrer = () => {
  const location = useLocation()
  const pathAndSearch = location.pathname + location.search
  const trackedNavigations = useRef<{
    previousReferrer: string
    currentReferrer: string
  }>({
    previousReferrer: undefined,
    currentReferrer: getWashedDocumentReferrer(document.referrer),
  })
  useEffect(() => {
    if (location.state !== blockedReferrer) {
      trackedNavigations.current = {
        previousReferrer: trackedNavigations.current.currentReferrer,
        currentReferrer: getWashedDocumentReferrer(
          window.location.origin + pathAndSearch
        ),
      }
      Object.defineProperty(document, 'referrer', {
        value: trackedNavigations.current.previousReferrer,
        writable: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathAndSearch])
}

export default useOverrideDocumentReferrer
