import {
  createImageAltTextFromFileName,
  getFullImageUrl,
  getStandardSrcset,
  LazyLoadWrapper,
} from '@trr/internal-helpers'
import { Box, styled } from '@mui/material'

import ArticleVideoBlock, {
  ArticleVideoBlockProps,
} from '../ArticleVideoBlock/ArticleVideoBlock'

export interface ArticleTopMediaProps {
  image?: string
  altText?: string
  videoProperties?: ArticleVideoBlockProps
}

const Image = styled('img')(() => ({
  width: '100%',
  objectFit: 'cover',
}))

const ArticleTopImage = ({
  image,
  altText,
  videoProperties,
}: ArticleTopMediaProps) => {
  const { MEDIA_URL } = window.APP_CONFIGURATION.COMMON
  const hasVideo = videoProperties?.properties?.videoUrl
  return (
    <Box mb={5}>
      {hasVideo ? (
        <ArticleVideoBlock {...videoProperties} />
      ) : (
        <LazyLoadWrapper>
          <Image
            src={getFullImageUrl(MEDIA_URL, image, 768)}
            alt={altText || createImageAltTextFromFileName(image)}
            srcSet={getStandardSrcset(MEDIA_URL, image, [1140])}
            sizes="(max-width: 1236px) 100vw, 1140px"
            // @ts-expect-error Not accepted by tslint for now
            fetchpriority="high"
          />
        </LazyLoadWrapper>
      )}
    </Box>
  )
}

export default ArticleTopImage
