import { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  DropdownMenu,
  DropdownMenuToggle,
  DropdownMenuListSection,
  DropdownListItem,
} from 'apps/Site/components/DropdownMenu'
import { isLinkExternal } from '@trr/internal-helpers'
import { useCachedUserProfileState } from 'state/PublicWebApi/UserProfile'

import s from './SecondaryNavigation.module.scss'
import { NavigationRoute } from 'state/PublicWebApi/Settings/types'
import { Box, Avatar } from '@mui/material'
import AccountCircle from '@mui/icons-material/AccountCircleOutlined'
import OpenInNew from '@mui/icons-material/OpenInNew'

interface SecondaryNavigationProps {
  logout: () => void
  routes: NavigationRoute[]
  secondaryToggleAction?: () => void
}

export const SecondaryNavigation = ({
  logout,
  routes,
  secondaryToggleAction,
}: SecondaryNavigationProps) => {
  const { firstName, preferredFirstName, profileImages, imageFileName } =
    useCachedUserProfileState()
  const name = preferredFirstName || firstName

  const [isSecondaryNavigationVisible, setIsSecondaryNavigationVisible] =
    useState(false)

  const toggleDropdownMenu = (setAsVisible: boolean) =>
    setIsSecondaryNavigationVisible(setAsVisible)

  const closeDropdownMenu = () => setIsSecondaryNavigationVisible(false)

  return (
    <DropdownMenu
      testSelector="secondaryNavigation"
      showDropdown={isSecondaryNavigationVisible}
      toggleDropdown={toggleDropdownMenu}
    >
      {{
        action: (
          <DropdownMenuToggle
            testSelector="secondaryNavigationToggle"
            className={s.DropdownMenuToggle}
            secondaryAction={secondaryToggleAction}
            data-gtm-label="Medarbetare dropdown"
          >
            <>
              <Box
                sx={{
                  lineClamp: 1,
                  WebkitLineClamp: 1,
                  maxWidth: '100px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                }}
                component="span"
                className={s.UserProfileName}
                data-hj-suppress
                data-testid="user-profile-name"
              >
                {name}
              </Box>
              <Box mx={1}>
                {imageFileName ? (
                  <Avatar
                    title="Avatar"
                    src={profileImages?.small}
                    alt="Profilbild"
                    data-hj-suppress
                  />
                ) : (
                  <Avatar
                    title="Avatar"
                    alt="Profilbild"
                    sx={{
                      bgcolor: 'surface.purple',
                      height: '48px',
                      width: '48px',
                    }}
                  >
                    <AccountCircle sx={{ fontSize: '28px' }} color="primary" />
                  </Avatar>
                )}
              </Box>
            </>
          </DropdownMenuToggle>
        ),
        dropdownList: (
          <>
            <DropdownMenuListSection>
              {routes?.map(({ name, url, showIcon }, i) => (
                <DropdownListItem
                  key={`dropdown-item-${i}`}
                  onClick={closeDropdownMenu}
                >
                  {isLinkExternal(url) ? (
                    <a
                      href={url}
                      role="menuitem"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {showIcon && <OpenInNew />}
                      {name}
                    </a>
                  ) : (
                    <Link to={url} role="menuitem">
                      {name}
                    </Link>
                  )}
                </DropdownListItem>
              ))}
            </DropdownMenuListSection>
            <DropdownMenuListSection displaySeparator={false}>
              <DropdownListItem>
                <button
                  data-testid="logoutClick"
                  role="menuitem"
                  onClick={logout}
                >
                  Logga ut
                </button>
              </DropdownListItem>
            </DropdownMenuListSection>
          </>
        ),
      }}
    </DropdownMenu>
  )
}
