import { useSliceDispatch } from 'slices/hooks'
import userManager from '../userManager'
import { useEffect } from 'react'

const useDispatchAuthOnMount = () => {
  const trigger = useSliceDispatch()

  useEffect(() => {
    void userManager
      .getUser()
      .then((user) => {
        if (user && !user.expired) trigger('setAuthState', user)
      })
      .catch(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useDispatchAuthOnMount
