import { FontIcon } from '@trr/internal-helpers'
import { Box } from '@mui/material'
import { useAuthentication } from 'features/Authentication/Hooks'
import { useSliceStateSelector } from 'slices/hooks'
import AvatarButton from '../../AvatarButton'
import { StyledSecondaryActionButton } from '../../NewHeader.styles'
import { getLoggedInContext, getLoginUrl } from '../../NewHeader.hooks'
import { useTrackCustomClick } from 'features/Telemetry/GoogleTagManager/Hooks/useTrackCustomClick'

interface SecondaryActionButtonProps {
  icon: string
  title: string
  url: string
  isLoginButton?: boolean
}

const getSecondaryActionButtonsData = (): SecondaryActionButtonProps[] => {
  const isSiteInEnglish = location.pathname.includes('/trr-in-english/')
  return [
    {
      icon: 'Search',
      title: 'Sök',
      url: '/sok',
    },
    {
      icon: 'AccountCircle',
      title: 'Logga in',
      url: '/login',
      isLoginButton: true,
    },
    {
      icon: 'Language',
      title: isSiteInEnglish ? 'På Svenska' : 'In English',
      url: isSiteInEnglish ? '/' : '/trr-in-english/',
    },
  ]
}

const SecondaryActionButtons = () => {
  const secondaryActionButtonsData = getSecondaryActionButtonsData()

  const { visitorContextStatus } = useSliceStateSelector(
    (state) => state.slices.visitorContexts
  )

  const { isLoggedIn, profile } = useAuthentication()
  const userRole = profile.idp

  const trackCustomClick = useTrackCustomClick()

  return (
    <Box display="flex">
      {secondaryActionButtonsData?.map(({ icon, title, url, isLoginButton }) =>
        isLoginButton && isLoggedIn ? (
          <AvatarButton
            key={'avatarbutton-component'}
            visitorContext={getLoggedInContext(userRole, visitorContextStatus)}
          />
        ) : (
          <StyledSecondaryActionButton
            href={
              isLoginButton
                ? getLoginUrl(url, visitorContextStatus as string)
                : url
            }
            onClick={() => {
              trackCustomClick('Menu action button', {
                label: title,
                href: isLoginButton
                  ? getLoginUrl(url, visitorContextStatus as string)
                  : url,
              })
            }}
            disableRipple
            key={`secondaryactionbutton-${title}`}
            data-testid={
              isLoginButton
                ? 'header-avatar-button'
                : `secondaryactionbutton-${title}`
            }
          >
            <FontIcon iconName={icon} />
            {title}
          </StyledSecondaryActionButton>
        )
      )}
    </Box>
  )
}

export default SecondaryActionButtons
