import { Box, styled } from '@mui/material'
import { getBackgroundColor } from 'apps/Site/hooks/getBackgroundColor'

interface BackgroundColor {
  bgColor: string
  useColor: boolean
  hasImage: boolean
  useLeftAlignedHeadingIfImageNotProvided: boolean
}

export const PhotoTextBlockWrapper = styled(Box, {
  shouldForwardProp: (prop: string) =>
    ![
      'bgColor',
      'useColor',
      'hasImage',
      'useLeftAlignedHeadingIfImageNotProvided',
    ].includes(prop),
})<BackgroundColor>(
  ({
    theme,
    bgColor,
    useColor,
    hasImage,
    useLeftAlignedHeadingIfImageNotProvided,
  }) => ({
    display: 'flex',
    minHeight: '400px',
    flexDirection: 'column',
    ...(!hasImage && {
      minHeight: 0,
    }),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      ...(!hasImage &&
        !useLeftAlignedHeadingIfImageNotProvided && {
          flexDirection: 'column',
          alignItems: 'center',
        }),
    },
    backgroundColor: getBackgroundColor(theme, bgColor, useColor),
  })
)

interface TextWrapper {
  useColor: boolean
  imagePosition: 'left' | 'right'
  hasImage: boolean
  useLeftAlignedHeadingIfImageNotProvided: boolean
}

export const TextWrapper = styled(Box, {
  shouldForwardProp: (prop: string) =>
    ![
      'imagePosition',
      'useColor',
      'hasImage',
      'useLeftAlignedHeadingIfImageNotProvided',
    ].includes(prop),
})<TextWrapper>(
  ({
    theme,
    useColor,
    imagePosition,
    hasImage,
    useLeftAlignedHeadingIfImageNotProvided,
  }) => ({
    display: 'flex',
    flexGrow: 5,
    flexShrink: 1,
    flexBasis: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3),

    ...(!useColor && {
      padding: `${theme.spacing(3)} 0`,
    }),

    ...(hasImage
      ? {
          ...(imagePosition === 'left' && {
            paddingTop: theme.spacing(5),
            [theme.breakpoints.up('md')]: {
              padding: theme.spacing(8, 0),
              ...(!useColor && {
                paddingRight: 0,
              }),
            },
          }),
          ...(imagePosition === 'right' && {
            paddingBottom: theme.spacing(5),
            [theme.breakpoints.up('md')]: {
              padding: theme.spacing(8, 0),
              ...(!useColor && {
                paddingLeft: 0,
              }),
            },
          }),
        }
      : {
          [theme.breakpoints.up('md')]: {
            ...(useColor
              ? {
                  padding: theme.spacing(7),
                }
              : {
                  padding: `${theme.spacing(3)} 0`,
                }),
            ...(useLeftAlignedHeadingIfImageNotProvided && {
              flexDirection: 'row',
              gap: theme.spacing(7),
            }),
          },
        }),
  })
)

interface HeadingWrapper {
  hasImage: boolean
  useLeftAlignedHeadingIfImageNotProvided: boolean
}

export const HeadingWrapper = styled(Box, {
  shouldForwardProp: (prop: string) =>
    !['hasImage', 'useLeftAlignedHeadingIfImageNotProvided'].includes(prop),
})<HeadingWrapper>(
  ({ theme, hasImage, useLeftAlignedHeadingIfImageNotProvided }) => ({
    marginBottom: theme.spacing(2),
    overflowWrap: 'break-word',
    hyphens: 'auto',
    maxWidth: '752px',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 8, 2, 8),
      ...(!hasImage &&
        useLeftAlignedHeadingIfImageNotProvided && {
          margin: 0,
          flexGrow: 4,
          flexShrink: 1,
          flexBasis: 0,
        }),
    },
  })
)

interface BodyWrapper {
  hasImage: boolean
  useLeftAlignedHeadingIfImageNotProvided: boolean
}

export const BodyWrapper = styled(Box, {
  shouldForwardProp: (prop: string) =>
    !['hasImage', 'useLeftAlignedHeadingIfImageNotProvided'].includes(prop),
})<BodyWrapper>(
  ({ theme, hasImage, useLeftAlignedHeadingIfImageNotProvided }) => ({
    maxWidth: '752px',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 8),
      ...(!hasImage &&
        useLeftAlignedHeadingIfImageNotProvided && {
          margin: 0,
          flexGrow: 7,
          flexShrink: 1,
          flexBasis: 0,
        }),
    },
  })
)

export const ImageWrapper = styled(Box, {
  shouldForwardProp: (prop: string) => !['useLargeImage'].includes(prop),
})<{ useLargeImage: boolean }>(({ useLargeImage }) => ({
  display: 'flex',
  flexGrow: useLargeImage ? 2 : 5,
  flexShrink: 1,
  flexBasis: useLargeImage ? '60%' : 0,
  margin: '0',
  img: {
    height: '100%',
    objectFit: 'cover',
    verticalAlign: 'middle',
    width: '100%',
  },
  '.LazyLoadWrapper': {
    width: '100%',
  },
}))
