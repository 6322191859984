import { useEffect } from 'react'
import { useAuthentication } from 'features/Authentication/Hooks'
import { userProfileApi } from 'state/PublicWebApi/UserProfile'
import useUserRules from 'utils/hooks/useUserRules'

const useFetchUserProfile = () => {
  const [fetchUserProfile, userData] =
    userProfileApi.endpoints.getUserProfile.useLazyQuery()

  const userRules = useUserRules()

  const {
    isLoggedIn,
    profile: { userRoles },
  } = useAuthentication()

  // When a user has these roles they cannot yet fetch a userprofile because it does not yet exist.
  const isUserComplete = userRules.isUserProfileComplete()

  useEffect(() => {
    const tryFetchUserProfile = async () => {
      if (userData.isUninitialized && isLoggedIn && isUserComplete) {
        await fetchUserProfile()
      }
    }
    void tryFetchUserProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, userRoles])
  return {
    isSuccess: userData.isSuccess,
    isAnonymous: !isLoggedIn && !userData.isLoading && !userData.isFetching,
    isInLoginFlow: userRules.isUserProfileIncomplete(),
    isError: userData.isError,
  }
}

export default useFetchUserProfile
