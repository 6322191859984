import { useAuthentication } from 'features/Authentication/Hooks'
import { useEffect, useRef } from 'react'
import { useApplicationInsights } from './useApplicationInsights'

/**
 * Custom hook that sets the authenticated user context in Application Insights.
 * It uses the `useAuthentication` and `useApplicationInsights` hooks internally.
 */
const useSetAuthenticatedUser = (): void => {
  const lastSubRef = useRef<string | undefined>()
  const { profile } = useAuthentication()
  const { appInsights } = useApplicationInsights()
  const sub = profile?.sub

  useEffect(() => {
    if (sub?.length) {
      appInsights.setAuthenticatedUserContext(sub, sub, true)
      lastSubRef.current = sub
    } else if (lastSubRef.current !== undefined) {
      appInsights.clearAuthenticatedUserContext()
      lastSubRef.current = undefined
    }
  }, [appInsights, sub])
}

export default useSetAuthenticatedUser
