import { setupHeaders } from 'state/helpers'

const fetchPossibleJmtLink = async (
  setter: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
  const signal = new AbortController().signal
  const headers = await setupHeaders(new Headers())
  await fetch(`${window.APP_CONFIGURATION.COMMON.KLIENT_API_URL}/jmt/link`, {
    headers,
    signal,
  })
    .then((res) => res.json())
    .then((data: { link: string }) => setter(data.link))
    .catch(() => setter(undefined))
}

export default fetchPossibleJmtLink
