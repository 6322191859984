import { buildEvent } from 'features/Telemetry/GoogleTagManager/Helpers/buildEvent'

import { tryToGetElementMfName } from '../helpers/tryToGetElementMfName'
import { getWashedLabel } from 'features/Telemetry/GoogleTagManager/Helpers'

export const isTrackable = () => true

export const assembleDataEntries = (element: HTMLElement) => {
  const label =
    element.getAttribute('data-gtm-label') ??
    getWashedLabel(element.textContent)
  return {
    label,
  }
}

export const item = 'button'

export const extractEventData = (element: HTMLElement) => {
  const mfName = tryToGetElementMfName(element)
  return buildEvent({
    action: 'click',
    item,
    mfName,
    assembledData: assembleDataEntries(element),
  })
}
