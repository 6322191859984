import { SyntheticEvent, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { TableOfContentLink } from 'state/PublicWebApi/Content'
import { TableOfContentLinkStyled } from './TableOfContentsLink.styles'

interface TableOfContentsLinkProps {
  item: TableOfContentLink
  isActive?: boolean
}

export const TableOfContentsLink = ({
  item,
  isActive,
}: TableOfContentsLinkProps) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { replace } = useHistory()
  const clickHandler = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault()
      const target = document.getElementById(`${item.headingId}`)
      if (target) {
        replace({ hash: `${item.headingId}` })
        target.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    },
    [item.headingId, replace]
  )

  return (
    <li>
      <TableOfContentLinkStyled
        isInView={isActive}
        onClick={clickHandler}
        href={`#${item.headingId}`}
      >
        {item.text}
      </TableOfContentLinkStyled>
    </li>
  )
}
