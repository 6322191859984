import { type PropsWithChildren } from 'react'
import blockMapper from 'apps/Site/Epi/BlockMapper'
import HTMLMapper from 'apps/Site/Epi/HTMLMapper'
import { FunctionPageProperties } from 'state/PublicWebApi/Content'
import { Box, Typography, styled } from '@mui/material'

const PremableWrapper = styled(Box)(({ theme }) => ({
  p: {
    ...theme.typography.preamble,
  },
}))

const FunctionPageWrapper = ({
  heading,
  preamble,
  topContentBlocks,
  bottomContentBlocks,
  ...props
}: PropsWithChildren<FunctionPageProperties>) => {
  return (
    <Box>
      {(heading || preamble) && (
        <Box mb={4} sx={{ maxWidth: '752px' }}>
          {heading && (
            <Typography
              variant="h1"
              mb={3}
              sx={{
                overflowWrap: 'break-word',
                hyphens: 'auto',
              }}
            >
              {heading}
            </Typography>
          )}
          {preamble && (
            <PremableWrapper>
              <HTMLMapper body={preamble} />
            </PremableWrapper>
          )}
        </Box>
      )}
      {topContentBlocks && topContentBlocks.length > 0 && (
        <Box>{blockMapper({ blocks: topContentBlocks })}</Box>
      )}
      {props.children}
      {bottomContentBlocks && bottomContentBlocks.length > 0 && (
        <Box mt={6}>{blockMapper({ blocks: bottomContentBlocks })}</Box>
      )}
    </Box>
  )
}

export default FunctionPageWrapper
