import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import setupHeaders from './setupHeaders'

const baseQueryWithHeaders = (apiBaseUrl: string) =>
  fetchBaseQuery({
    baseUrl: apiBaseUrl,
    prepareHeaders: (headers) => setupHeaders(headers),
  })

export default baseQueryWithHeaders
