import { Box, styled } from '@mui/material'

export const ArticleVideoBlockWrapper = styled('section')(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.surface?.purple,

  '.LazyLoadWrapper': {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
}))

export const VideoInformationWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}))

export const TitleRowWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  gap: theme.spacing(1),
}))
