import {
  Avatar,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'
import { useCachedSettingsState } from 'state/PublicWebApi'
import WarningIcon from '@mui/icons-material/WarningRounded'
import HTMLMapper from 'apps/Site/Epi/HTMLMapper'

export const WidgetError = ({ widgetName }: { widgetName: string }) => {
  const httpErrorContent =
    useCachedSettingsState().errorPageSettings['widgetError']

  return (
    <Card
      className="widgetError"
      data-testid={`error${httpErrorContent.errorCode.replace(' ', '')}`}
      sx={{ flexGrow: 1, maxWidth: '1140px', m: 'auto' }}
    >
      <CardContent>
        <Typography variant="h6" component={'h2'} mb={1}>
          {widgetName}
        </Typography>
        <List>
          <ListItem
            alignItems="flex-start"
            disablePadding
            disableGutters
            divider={false}
          >
            <ListItemAvatar sx={{ display: { xs: 'none', sm: 'initial' } }}>
              <Avatar sx={{ bgcolor: 'warning.light' }}>
                <WarningIcon color="warning" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="subtitle1">
                  {httpErrorContent.heading}
                </Typography>
              }
              secondary={
                <Box
                  component="span"
                  sx={{
                    '& p': { fontSize: '14px !important', mb: '0 !important' },
                  }}
                >
                  <HTMLMapper body={httpErrorContent.ingress} />
                </Box>
              }
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  )
}

export default WidgetError
