import { Box, CircularProgress } from '@mui/material'
import { type PropsWithChildren } from 'react'
import { useFetchUserProfile, useKeepSettingsUpToDate } from './Hooks'
import { SiteErrorPage } from 'features/ErrorHandling/SiteErrorPage'

const PrepareAppShell = ({ children }: PropsWithChildren) => {
  const { isSuccess: isSettingsSuccess, isError: isSettingsError } =
    useKeepSettingsUpToDate()

  const {
    isSuccess: isUserProfileSuccess,
    isError: isUserProfileError,
    isInLoginFlow: isUserInLoginFlow,
    isAnonymous: isUserAnonymous,
  } = useFetchUserProfile()

  if (
    isSettingsSuccess &&
    (isUserProfileSuccess || isUserAnonymous || isUserInLoginFlow)
  ) {
    return <>{children}</>
  }
  if (isSettingsError || isUserProfileError) {
    return <SiteErrorPage />
  }
  return (
    <Box display="flex" flex="1 1 auto" justifyContent="center" py="10%">
      <CircularProgress />
    </Box>
  )
}

export default PrepareAppShell
