import { RootState } from 'store'
import createUserRules from 'utils/userRules/createUserRules'

const selectUserRoles = (state: RootState) =>
  state.slices.auth.profile.userRoles

const selectUserIdp = (state: RootState) => state.slices.auth.profile.idp

export const selectShouldEnforceRoleForFunctionTypes =
  (functionTypes: string[], currentFunctionType: string) =>
  (state: RootState) => {
    const userRoles = selectUserRoles(state)
    const userIdp = selectUserIdp(state)

    const shouldEnforceForFunctionType = functionTypes.some(
      (functionType) => functionType === currentFunctionType
    )
    const userRules = createUserRules(userRoles, userIdp)
    const shouldEnforceForRole =
      userRules.isMissingEmail() || userRules.isMissingMobile()
    const shouldEnforce = shouldEnforceForFunctionType && shouldEnforceForRole

    return shouldEnforce
  }
