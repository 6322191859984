/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useEffect, useState } from 'react'
import { useCachedSettingsState } from 'state/PublicWebApi'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined'
import IllustrationFokus from './IllustrationFokus'
import { HTMLMapper } from '@trr/html-mapper'

const Surfly = () => {
  const [isSurflyLoaded, setIsSurflyLoaded] = useState(false)
  const [open, setOpen] = useState(false)
  const [pin, setPin] = useState(0)
  const close = () => setOpen(false)

  const surflySettings = useCachedSettingsState().customSettings.surfly

  const settings = {
    widget_key: surflySettings?.widgetKey,
    verbose_console: surflySettings?.debug,
    url:
      process.env.NODE_ENV === 'production'
        ? document?.location?.href
        : 'https://dev-next.trr.se', // This can't be a local adress like localhost:3000, because then Surflys proxy can't react us
    private_session: true,
    hide_until_agent_joins: true,
    block_until_agent_joins: false,
    language: 'sv',
    auto_restore: false,
    videochat: false,
    filesharing_enabled: false,
    docked_only: true,
  }

  useEffect(() => {
    if (surflySettings?.scriptURL && !isSurflyLoaded) {
      const surflyScript = document.createElement('script')
      surflyScript.type = 'text/javascript'
      surflyScript.src = surflySettings?.scriptURL
      surflyScript.async = true
      surflyScript.id = 'surfly-api-frame'
      document.body.appendChild(surflyScript)
    }
  }, [isSurflyLoaded, surflySettings])

  const startSession = () => {
    if (!isSurflyLoaded) {
      // Init Surfly
      initSurfly()
      setIsSurflyLoaded(true)
    }
  }

  const initSurfly = () => {
    window?.Surfly?.init(settings, (initResult: { success: unknown }) => {
      if (initResult.success && !window?.Surfly?.isInsideSession) {
        // Start a new Surfly session
        window?.Surfly?.session()
          .on('session_created', (session: { pin?: number }) => {
            setPin(session?.pin)
            setOpen(false)
          })
          .startLeader()
      }
    })
  }

  return (
    <>
      {pin > 0 ? (
        <Typography variant="subtitle2" component="span" color="#fff">
          {pin}
        </Typography>
      ) : (
        <IconButton
          data-testid="surfly-eye-button"
          sx={{ color: 'rgba(255,255,255, 0.75)' }}
          onClick={() => setOpen(true)}
        >
          <VisibilityOutlined />
        </IconButton>
      )}

      {open && (
        <Dialog
          data-testid="surfly-dialog"
          maxWidth="xs"
          open={open}
          onClose={close}
          aria-labelledby="surfly-dialog-title"
          aria-describedby="surfly-dialog-description"
        >
          <DialogTitle id="surfly-dialog-title">
            {surflySettings?.dialogTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="surfly-dialog-description">
              <IllustrationFokus />
              <HTMLMapper
                mediaUrl={window.APP_CONFIGURATION.COMMON.MEDIA_URL}
                body={surflySettings?.dialogBody}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={close}
              data-testid="surfly-cancel-button"
            >
              {surflySettings?.dialogCancelButtonLabel}
            </Button>
            <Button onClick={startSession} data-testid="surfly-confirm-button">
              {surflySettings?.dialogConfirmButtonLabel}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default Surfly
