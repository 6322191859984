import { styled, ButtonBase, alpha } from '@mui/material'

interface NavItemProps {
  onClick?: () => void
  href?: string
  isFoldoutOpen?: boolean
}

export const NavItemWrapper = styled(ButtonBase, {
  shouldForwardProp: (prop: string) => !['isFoldoutOpen'].includes(prop),
})<NavItemProps>(({ theme, isFoldoutOpen }) => ({
  ...theme.typography.subtitle1,
  padding: `0 ${theme.spacing(1)}`,
  gap: theme.spacing(0.5),
  textwrap: 'no-wrap',
  border: 'solid',
  borderRadius: '8px',
  borderColor: 'transparent',
  borderWidth: '1px',
  height: '100%',

  ...(isFoldoutOpen && {
    color: theme.palette.primary?.main,
  }),

  '&:hover': {
    color: theme.palette.primary?.main,
  },

  '&:focus-visible': {
    backgroundColor: alpha(theme.palette.primary?.main, 0.3),
    outline: 'none',
    color: !isFoldoutOpen && theme.palette.text?.primary,
  },
}))
