import { useEffect } from 'react'
import {
  extractEventData,
  findInteractiveElement,
  isElementTrackable,
} from './helpers'
import TagManager from 'features/Telemetry/GoogleTagManager/TagManager'
import { useSliceStateSelector } from 'slices/hooks'

export const useTrackElement = (): void => {
  const isTrrCookiesApproved = useSliceStateSelector(
    (state) => state.slices.cookies?.isTrrCookiesApproved
  )

  const trackClick = (e: MouseEvent) => {
    const clickedElement = e.target as HTMLElement

    // If the user for example clicks a SVG and it's wrapped inside a buttom, find the button
    const interactiveElement = findInteractiveElement(clickedElement)

    if (interactiveElement && isElementTrackable(interactiveElement)) {
      const eventData = extractEventData(interactiveElement)
      if (eventData) {
        TagManager.dataLayer(eventData)
      }
    }
  }

  useEffect(() => {
    if (isTrrCookiesApproved) {
      document.body.addEventListener('click', trackClick, { capture: true })
    }

    return () => {
      document.body.removeEventListener('click', trackClick, { capture: true })
    }
  }, [isTrrCookiesApproved])
}
