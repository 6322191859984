import { createElement } from 'react'
import PrimaryContentBlock from '../CollectionPageBlocks/PrimaryContentBlock/PrimaryContentBlock'
import { CollectionPageBlock } from '../types'

interface CollectionPageBlockMap {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

const blockTypeMap: CollectionPageBlockMap = {
  PrimaryContentBlock,
}

export interface CollectionPageBlockMapper {
  blocks: CollectionPageBlock[]
}

const CollectionPageBlockMapper = ({
  blocks = [],
}: CollectionPageBlockMapper) => {
  return (
    blocks.length > 0 &&
    blocks.map(
      ({ blockType, content }, index) =>
        !!blockTypeMap[blockType] &&
        createElement(blockTypeMap[blockType], {
          ...content,
          key: `${blockType}${index}`,
        })
    )
  )
}

export default CollectionPageBlockMapper
