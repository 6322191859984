import {
  useCachedContentQuery,
  useCachedSettingsState,
} from 'state/PublicWebApi'
import { useEffect, useState } from 'react'

const validZendeskPageTypes = [
  'TRRStartPage',
  'ArticlePage',
  'NavigationPage',
  'ListPage',
  'PublicWebStartPage',
  'BlockPage',
  'DashboardPage',
  'GuidePage',
  'FaqPage',
  'NewsPage',
  'PostListPage',
  'NewsListPage',
  'PublicLandingPage',
]

const ZendeskChat = (): JSX.Element => {
  const [isZendeskLoaded, setIsZendeskLoaded] = useState(false)
  const [pageType, setPageType] = useState('')

  const zendeskUrl = useCachedSettingsState().customSettings.zenDesk?.url

  const { data: contentData } = useCachedContentQuery()

  const currentPageEpiContentTypes = contentData.contentType

  // Looking directly at redux store causes flickering of chat bubble
  // as redux state gets reset during request to an empty array.
  // Using local state ensures that we only care about the content of the state.
  useEffect(() => {
    if (currentPageEpiContentTypes?.length > 0) {
      setPageType(currentPageEpiContentTypes[1])
    }
  }, [currentPageEpiContentTypes])

  const showWebWidget = () => {
    window?.zE?.('messenger', 'show')
  }

  const hideWebWidget = () => {
    window?.zE?.('messenger', 'hide')
  }

  useEffect(() => {
    if (zendeskUrl && !isZendeskLoaded) {
      const zendeskScript = document.createElement('script')
      zendeskScript.src = zendeskUrl
      zendeskScript.id = 'ze-snippet'
      zendeskScript.onload = () => {
        hideWebWidget()
        setIsZendeskLoaded(true)
      }

      document.body.appendChild(zendeskScript)
    }
  }, [zendeskUrl, isZendeskLoaded])

  useEffect(() => {
    if (isZendeskLoaded) {
      if (validZendeskPageTypes.includes(pageType)) {
        showWebWidget()
      } else {
        hideWebWidget()
      }
    }
  }, [pageType, isZendeskLoaded])

  return null
}
export default ZendeskChat
