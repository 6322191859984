import { Box, Typography, styled } from '@mui/material'
import Error from '@mui/icons-material/Error'

import { useCachedSettingsState } from 'PublicWebApi'

const PreviewBar = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.info?.main,
  color: 'white',
  display: 'flex',
  gap: theme.spacing(2),
  height: '52px',
  padding: `0 ${theme.spacing(4)}`,
  width: '100vw',
}))

const Preview = () => {
  const { previewSettings, context } = useCachedSettingsState()
  const { workId, visitorGroup } = previewSettings || {}
  const { message } = context || {}

  let text

  if (message) {
    text = message
  }

  if (workId) {
    text = `Du förhandsgranskar som - "${visitorGroup || 'Ingen'}"`
  }

  if (!text) {
    return <></>
  }

  return (
    <PreviewBar data-testid="preview-background">
      <Error />
      <Typography sx={{ fontWeight: 500, fontSize: 14 }}>{text}</Typography>
    </PreviewBar>
  )
}

export default Preview
