import { Typography, Box, useMediaQuery, useTheme } from '@mui/material'
import { AppshellLink } from '@trr/internal-helpers'
import {
  FeatureListItem,
  FeatureListWrapper,
  HeadingAndPreambleWrapper,
} from './FeaturesBlock.styled'
import FeaturePuff from './FeaturePuff'

export interface FeaturesBlockProps {
  content: {
    heading?: string
    preamble?: string
    isNumberedList?: boolean
    isIconList?: boolean
    isVerticalList?: boolean
    link?: {
      text: string
      url: string
    }
    featurePuffs: {
      id: number
      properties: {
        iconName?: string
        heading: string
        body: string
      }
    }[]
  }
}

const FeaturesBlock = ({
  content: {
    heading = undefined,
    preamble = undefined,
    isNumberedList = false,
    isIconList = false,
    isVerticalList = false,
    featurePuffs,
    link,
  },
}: FeaturesBlockProps) => {
  const theme = useTheme()
  const mdBreakpoint = useMediaQuery(theme.breakpoints.up('md'))

  const useVerticalListDesign = isVerticalList && mdBreakpoint

  const getNumberInRow = () => {
    if (useVerticalListDesign) {
      return ''
    }
    return featurePuffs?.length % 3 === 0 ? 'triple' : 'double'
  }

  const numberInRow = getNumberInRow()

  const getVariant = () => {
    if (isNumberedList) {
      return 'enumerated'
    }
    if (isIconList) {
      return 'icon'
    }
    return 'text'
  }

  const renderLinkUnderPreamble =
    useVerticalListDesign && link?.url && link?.text

  return (
    <section>
      <Box {...(useVerticalListDesign && { display: 'flex' })}>
        <HeadingAndPreambleWrapper
          useVerticalListDesign={useVerticalListDesign}
        >
          {heading && (
            <Typography variant="h3" component="h2" mb={{ xs: 2, md: 3 }}>
              {heading}
            </Typography>
          )}
          {preamble && (
            <Typography variant="body1" mb={2}>
              {preamble}
            </Typography>
          )}
          {renderLinkUnderPreamble && (
            <AppshellLink url={link?.url} text={link?.text} />
          )}
        </HeadingAndPreambleWrapper>
        {featurePuffs?.length > 0 && (
          <FeatureListWrapper
            useVerticalListDesign={useVerticalListDesign}
            data-testid="feature-block"
          >
            {featurePuffs.map(({ id, properties }, index) => (
              <FeatureListItem key={`feature-puff-${id}`} variant={numberInRow}>
                <FeaturePuff
                  isVerticalList={useVerticalListDesign}
                  index={index + 1}
                  heading={properties.heading}
                  body={properties.body}
                  iconName={properties.iconName}
                  variant={getVariant()}
                />
              </FeatureListItem>
            ))}
          </FeatureListWrapper>
        )}
        {!useVerticalListDesign && (
          <AppshellLink url={link?.url} text={link?.text} mt={5} />
        )}
      </Box>
    </section>
  )
}

export default FeaturesBlock
