import { createElement, FC } from 'react'

import PageTypeMap from './PageTypeMap'

interface IPageTypeMapper {
  properties: Record<string, unknown>
  pageType: string
}

const PageTypeMapper = ({ properties, pageType }: IPageTypeMapper) =>
  !!PageTypeMap[pageType] &&
  createElement(PageTypeMap[pageType] as FC, properties)

export default PageTypeMapper
