export enum cookieName {
  trrCookie = 'TrrCookiesApproved',
  clearCookies = 'clearCookies',
  newNavigationSystemCookie = 'new-navigation-system',
}

export const getCookieValue = (key: string): string | undefined => {
  const cookies = document.cookie
    .split(';')
    .reduce((acc: Record<string, string>, cookie) => {
      const [cookieKey, ...rest] = cookie.split('=')
      acc[cookieKey.trim()] = rest.join('=')
      return acc
    }, {})

  return cookies[key]
}

export const deleteCookie = (key: string): boolean => {
  const cookieValue = getCookieValue(key)

  if (cookieValue) {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
    return true
  }

  return false
}

export const clearAllCookies = () => {
  document.cookie.split(';').forEach((cookie) => {
    const [cookieKey] = cookie.split('=')
    deleteCookie(cookieKey.trim())
  })
}
