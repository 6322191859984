import { useCachedSettingsState, widgetType } from 'state/PublicWebApi/Settings'

const useWidgetSettings = (widgetName: widgetType) => {
  const widgetSettings = useCachedSettingsState().widgetSettings?.[widgetName]
  if (widgetSettings) {
    const { ...content } = widgetSettings
    return { content }
  }
  return { content: undefined }
}

export default useWidgetSettings
