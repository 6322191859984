import { useMemo } from 'react'
import { useAuthentication } from 'features/Authentication/Hooks'
import createUserRules from 'utils/userRules'

const useUserRules = () => {
  const { profile } = useAuthentication()

  const userRoles = profile.userRoles
  const idp = profile.idp

  const userRules = useMemo(() => {
    return createUserRules(userRoles, idp)
  }, [userRoles, idp])

  return userRules
}

export default useUserRules
