import { Box, styled } from '@mui/material'
import { FooterNavigationItem } from 'PublicWebApi/Settings/types'
import Section from './Section'

interface SectionLinkProps {
  contexts: FooterNavigationItem[]
}

const SectionsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  [theme.breakpoints.up('lg')]: {
    maxWidth: theme.breakpoints.values.lg,
  },
}))

const SectionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  borderLeft: `1px solid ${theme.palette.divider}`,
  padding: `0 ${theme.spacing(3)} 0 ${theme.spacing(3)}`,
  [theme.breakpoints.up('lg')]: {
    width: '356px',
  },
}))

const Sections = ({ contexts }: SectionLinkProps) => {
  const privatpersonSection = contexts?.slice(0, 1)
  const arbetsgivareFortroendevaldSection = contexts?.slice(1, 3)
  const omOssSection = contexts?.slice(3, 4)
  const allSections = [
    privatpersonSection,
    arbetsgivareFortroendevaldSection,
    omOssSection,
  ]

  return (
    <SectionsWrapper>
      {allSections.map(
        (contextSection, index) =>
          contextSection?.length > 0 && (
            <SectionWrapper key={`section-${index.toString()}`}>
              {contextSection?.map((section) => (
                <Section
                  section={section}
                  key={`footercontextsection-${section.title.toString()}`}
                />
              ))}
            </SectionWrapper>
          )
      )}
    </SectionsWrapper>
  )
}

export default Sections
