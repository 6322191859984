import { Box, ButtonBase, Drawer, Link, alpha, styled } from '@mui/material'

interface DrawerWrapperProps {
  isMittTrrMenu?: boolean
}

export const DrawerWrapper = styled(Drawer, {
  shouldForwardProp: (props: string) => !['isMittTrrMenu'].includes(props),
})<DrawerWrapperProps>(({ isMittTrrMenu, theme }) => ({
  top: isMittTrrMenu ? '56px' : 0,
  height: isMittTrrMenu ? 'calc(100% - 56px)' : '100%',

  [theme.breakpoints.up('sm')]: {
    top: isMittTrrMenu ? '72px' : 0,
    height: isMittTrrMenu ? 'calc(100% - 72px)' : '100%',
  },

  '& .MuiDrawer-paper': {
    [theme.breakpoints.up('sm')]: {
      top: isMittTrrMenu ? '72px' : 0,
    },
    top: isMittTrrMenu ? '56px' : 0,
    height: 'inherit',
    width: '100%',
    boxShadow: 'none',
  },
}))

export const MenuWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  margin: theme.spacing(2),
}))

export const StyledMenuLink = styled(Link)(({ theme }) => ({
  ...theme.typography.h6,
  color: theme.palette.text?.primary,
  padding: `${theme.spacing(1, 1)}`,
  width: '100%',

  '&:focus-visible': {
    outline: 'none',
    color: theme.palette.text?.primary,
    backgroundColor: alpha(theme.palette.primary?.main, 0.3),
  },
  '&:hover': {
    backgroundColor: theme.palette.surface?.purple,
    color: theme.palette.primary?.main,
    svg: {
      color: theme.palette.primary?.main,
    },
  },
}))

export const StyledMenuFoldoutItem = styled(ButtonBase)(({ theme }) => ({
  ...theme.typography.h6,
  color: theme.palette.text?.primary,
  width: '100%',
  padding: `${theme.spacing(1, 1)}`,
  display: 'flex',
  justifyContent: 'space-between',

  '&:focus-visible': {
    outline: 'none',
    color: theme.palette.text?.primary,
    backgroundColor: alpha(theme.palette.primary?.main, 0.3),
    svg: {
      color: theme.palette.primary?.main,
    },
  },

  '&:hover': {
    backgroundColor: theme.palette.surface?.purple,
    color: theme.palette.primary?.main,
    svg: {
      color: theme.palette.primary?.main,
    },
  },
}))
