export const removeTrailingSlash = (str: string): string => {
  // Remove trailing slash expect if on /
  const modifiedPath = str.endsWith('/') ? str.slice(0, -1) : str
  if (modifiedPath.endsWith('/')) {
    return removeTrailingSlash(modifiedPath)
  }
  if (modifiedPath === '') return '/'

  return modifiedPath
}
