import {
  TextStatisticsBox,
  TextStatisticsBoxProps,
  IconStatisticsBox,
  IconStatisticsBoxProps,
} from './StatisticsBox'
import { Box, Typography, styled } from '@mui/material'
import { AppshellLink } from '@trr/internal-helpers'

const TextWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  marginBottom: theme.spacing(5),
  gap: theme.spacing(12),
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(6),
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}))

const HeadingAndPreambleWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '752px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(3),
  },
}))

const StatisticsRowsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}))

const StatisticsRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(0.5),
}))

interface StatisticsBoxProps {
  variant: 'topRow' | 'bottomRow'
}

const StatisticsBox = styled(Box)<StatisticsBoxProps>(({ theme, variant }) => ({
  display: 'flex',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 0,
  [theme.breakpoints.down('md')]: {
    ...(variant === 'topRow' && {
      flexBasis: '100%',
    }),
    ...(variant === 'bottomRow' && {
      flexBasis: `calc(50% - ${theme.spacing(0.5)}px)`,
    }),
  },
  [theme.breakpoints.down('sm')]: {
    flexBasis: '100%',
  },
}))

interface BoxContent {
  type: 'StatisticsInformationBlock' | 'StatisticsIconBlock'
  properties: TextStatisticsBoxProps | IconStatisticsBoxProps
}

const BoxComponent = ({ type, properties }: BoxContent) => {
  switch (type) {
    case 'StatisticsIconBlock':
      return <IconStatisticsBox {...(properties as IconStatisticsBoxProps)} />
    case 'StatisticsInformationBlock':
      return <TextStatisticsBox {...(properties as TextStatisticsBoxProps)} />
    default:
      return null
  }
}

interface StatisticsBlockProps {
  content: {
    heading?: string
    preamble?: string
    link?: {
      text: string
      url: string
    }
    topRow: BoxContent[]
    bottomRow: BoxContent[]
    color?: string
  }
}

const getKey = (boxProps: BoxContent) => {
  const { type, properties } = boxProps
  switch (type) {
    case 'StatisticsIconBlock':
      return `${type}-${(properties as IconStatisticsBoxProps).text}`
    case 'StatisticsInformationBlock':
      return `${type}-${(properties as TextStatisticsBoxProps).highlightText}`
    default:
      return ''
  }
}

const StatisticsBlock = ({
  content: { heading, preamble, link, topRow, bottomRow, color },
}: StatisticsBlockProps) => {
  const sliceTopRow = topRow?.slice(0, 4)
  const slicedBottomRow = bottomRow?.slice(0, 4)

  return (
    <Box component="section">
      <TextWrapper>
        <HeadingAndPreambleWrapper>
          {heading && (
            <Typography variant="h3" component="h2" mb={3}>
              {heading}
            </Typography>
          )}
          {preamble && <Typography variant="body1">{preamble}</Typography>}
        </HeadingAndPreambleWrapper>
        <Box display="flex" minWidth={'110px'} justifyContent="flex-end">
          <AppshellLink url={link.url} text={link.text} />
        </Box>
      </TextWrapper>
      <StatisticsRowsWrapper>
        {sliceTopRow?.length > 0 && (
          <StatisticsRow>
            {sliceTopRow.map((boxProps) => (
              <StatisticsBox key={getKey(boxProps)} variant="topRow">
                <BoxComponent
                  type={boxProps.type}
                  properties={{ ...boxProps.properties, color: color }}
                />
              </StatisticsBox>
            ))}
          </StatisticsRow>
        )}
        {slicedBottomRow?.length > 0 && (
          <StatisticsRow>
            {slicedBottomRow.map((boxProps) => (
              <StatisticsBox key={getKey(boxProps)} variant="bottomRow">
                <BoxComponent
                  type={boxProps.type}
                  properties={{ ...boxProps.properties, color: color }}
                />
              </StatisticsBox>
            ))}
          </StatisticsRow>
        )}
      </StatisticsRowsWrapper>
    </Box>
  )
}

export default StatisticsBlock
