import InformationBox from 'apps/Site/components/InformationBox'
import { TableBody, TableHead, TableRow } from '@mui/material'
import { FontIcon } from '@trr/internal-helpers'
import {
  ScreenReaderOnlyText,
  StyledTable,
  TableBodyCell,
  TableHeadCell,
  TableTypography,
} from './ComparisonBlock.styles'

interface ComparisonProductProps {
  properties: {
    productName: string
    isAvailableInSegmentA: boolean
    isAvailableInSegmentB: boolean
    name: string
  }
}

export interface ComparisonTableProps {
  productHeading: string
  segmentAHeading: string
  segmentBHeading: string
  comparisonTable: ComparisonProductProps[]
}
export interface ComparisonBlockContentProps extends ComparisonTableProps {
  heading: string
  preamble?: string
  link?: {
    text: string
    url: string
  }
  color?: string
  useColor?: boolean
}

export interface ComparisonBlockProps {
  content: ComparisonBlockContentProps
}

interface ComparisonCellProps {
  isAvailable: boolean
}

const ComparisonCell = ({ isAvailable }: ComparisonCellProps) => (
  <TableBodyCell>
    {isAvailable ? (
      <>
        <ScreenReaderOnlyText>Ingår</ScreenReaderOnlyText>
        <span aria-hidden="true">
          <FontIcon iconName="Check" customColor="secondary.main" />
        </span>
      </>
    ) : (
      <ScreenReaderOnlyText>Ingår ej</ScreenReaderOnlyText>
    )}
  </TableBodyCell>
)

const ComparisonTable = ({
  productHeading,
  segmentAHeading,
  segmentBHeading,
  comparisonTable,
}: ComparisonTableProps) => (
  <StyledTable>
    <TableHead>
      <TableRow>
        <TableHeadCell variant="head" scope="col">
          <TableTypography bold>{productHeading}</TableTypography>
        </TableHeadCell>
        <TableHeadCell variant="head" scope="col">
          <TableTypography>{segmentAHeading}</TableTypography>
        </TableHeadCell>
        <TableHeadCell variant="head" scope="col">
          <TableTypography>{segmentBHeading}</TableTypography>
        </TableHeadCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {comparisonTable?.map(
        ({
          properties: {
            productName,
            isAvailableInSegmentA,
            isAvailableInSegmentB,
          },
        }) => (
          <TableRow key={`row-${productName}`}>
            <TableBodyCell component="th" scope="row">
              <TableTypography>{productName}</TableTypography>
            </TableBodyCell>
            <ComparisonCell isAvailable={isAvailableInSegmentA} />
            <ComparisonCell isAvailable={isAvailableInSegmentB} />
          </TableRow>
        )
      )}
    </TableBody>
  </StyledTable>
)

const ComparisonBlock = ({
  content: {
    heading,
    preamble,
    link,
    color,
    useColor,
    productHeading,
    segmentAHeading,
    segmentBHeading,
    comparisonTable,
  },
}: ComparisonBlockProps) => (
  <InformationBox
    heading={heading}
    body={preamble}
    link={link}
    color={color}
    useColor={useColor}
  >
    <ComparisonTable
      productHeading={productHeading}
      segmentAHeading={segmentAHeading}
      segmentBHeading={segmentBHeading}
      comparisonTable={comparisonTable}
    />
  </InformationBox>
)

export default ComparisonBlock
