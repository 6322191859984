import { Fragment, SyntheticEvent, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  Typography,
  styled,
} from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { FooterNavigationItem } from 'PublicWebApi/Settings/types'
import { SectionLink } from '../Sections/Section/Section.styles'
import SubSection from '../Sections/SubSection'

interface MobileSectionsProps {
  sections: FooterNavigationItem[]
}

const SectionWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

const LinksWrapper = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  paddingTop: 0,
}))

const StyledAccordion = styled(Accordion)(() => ({
  boxShadow: 'none',
  backgroundColor: 'transparent',
}))

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: 0,
  border: 'solid',
  borderRadius: '2px',
  borderColor: 'transparent',
  borderWidth: '1px',

  '&:hover': {
    color: theme.palette.primary?.main,
  },

  '&.Mui-focusVisible': {
    outline: 'none',
    borderColor: theme.palette.primary?.main,
    backgroundColor: 'transparent',
  },

  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}))

const MobileSections = ({ sections }: MobileSectionsProps) => {
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  const showContextLink = (context: string) => {
    return (
      context === 'Privatperson' ||
      context === 'Arbetsgivare' ||
      context === 'Förtroendevald'
    )
  }
  return (
    <Box px={2}>
      {sections?.map((section, index) => (
        <SectionWrapper
          key={`mobilesection-${index.toString()}`}
          data-testid={`section-${section.title}`}
        >
          <StyledAccordion
            data-testid={`sectionaccordion-${section.title}`}
            disableGutters
            expanded={expanded === `section-${index.toString()}`}
            onChange={handleChange(`section-${index.toString()}`)}
          >
            <StyledAccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`accordion-${section.title}`}
              id={`accordion-${section.title}`}
            >
              <Typography variant="h6" paddingY={1}>
                {section.title}
              </Typography>
            </StyledAccordionSummary>
            <AccordionDetails sx={{ padding: 0, margin: 0 }}>
              <LinksWrapper>
                {showContextLink(section.title) && (
                  <SectionLink
                    data-testid={`contextlink-${section.title}`}
                    url={section.url}
                    text={section.title}
                    sx={{ paddingBottom: 1 }}
                  />
                )}
                {section.children?.map((sectionChild, index) => (
                  <Fragment key={`mobile-contextsection-${index.toString()}`}>
                    {sectionChild?.url ? (
                      <ListItem>
                        <SectionLink
                          data-testid={`link-without-subtitle-${sectionChild.title}`}
                          url={sectionChild.url}
                          key={`mobilesectionchild-${index.toString()}`}
                          text={sectionChild.title}
                        />
                      </ListItem>
                    ) : (
                      <SubSection
                        key={`mobile-subsection-${index.toString()}`}
                        section={sectionChild}
                      />
                    )}
                  </Fragment>
                ))}
              </LinksWrapper>
            </AccordionDetails>
          </StyledAccordion>
        </SectionWrapper>
      ))}
    </Box>
  )
}
export default MobileSections
