import { removeTrailingSlash } from './removeTrailingSlash'

export const getWashedDocumentReferrer = (href: string) => {
  try {
    const url = new URL(href)
    url.searchParams.forEach((_val, key) => {
      if (!key.startsWith('utm_')) {
        url.searchParams.delete(key)
      }
    })
    url.pathname = removeTrailingSlash(url.pathname)
    return url.href
  } catch {
    return ''
  }
}
