import { useEffect } from 'react'
import { useApplicationInsights } from './useApplicationInsights'
import {
  __api_internals__,
  applicationInsights,
  type ApplicationInsightsCustomEvent,
  type IApplicationInsightsDependencyDataPayload,
  type IApplicationInsightsEvent,
  type IApplicationInsightsEventPayload,
  type IApplicationInsightsExceptionPayload,
} from '@trr/app-shell-communication'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

const { AI_VERSION, AI_ACTIONS } = applicationInsights

const executeAIAction = (
  eventDetails: IApplicationInsightsEvent<unknown>,
  appInsights: ApplicationInsights
) => {
  switch (eventDetails.action) {
    case AI_ACTIONS.trackEvent: {
      const payload = eventDetails.payload as IApplicationInsightsEventPayload
      appInsights.trackEvent(payload.eventTelemetry, {
        releaseId: eventDetails.azureReleaseId,
        ...payload.customProperties,
      })
      break
    }
    case AI_ACTIONS.trackException: {
      const payload =
        eventDetails.payload as IApplicationInsightsExceptionPayload
      appInsights.trackException(payload.exceptionTelemetry, {
        releaseId: eventDetails.azureReleaseId,
      })
      break
    }
    case AI_ACTIONS.trackDependencyData: {
      const payload =
        eventDetails.payload as IApplicationInsightsDependencyDataPayload
      appInsights.trackDependencyData(payload.dependencyTelemetry)
      break
    }
    case AI_ACTIONS.__internal.clearAuthenticatedUserContext: {
      appInsights.clearAuthenticatedUserContext()
    }
  }
}

const useApplicationInsightsEventListener = (): void => {
  const { appInsights, isEnabled } = useApplicationInsights()

  useEffect(() => {
    const eventCallback = (e: ApplicationInsightsCustomEvent) => {
      if (e.detail.aiVersion === AI_VERSION) {
        executeAIAction(e.detail, appInsights)
      }
    }

    if (isEnabled) {
      // Listen to events from micro frontends that wants to log with AI
      addEventListener(
        __api_internals__.eventNames.applicationInsightsEventName,
        eventCallback
      )
    }

    return () => {
      removeEventListener(
        __api_internals__.eventNames.applicationInsightsEventName,
        eventCallback
      )
    }
  }, [appInsights, isEnabled])

  return null
}

export default useApplicationInsightsEventListener
