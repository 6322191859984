const washUserId = (userId?: string, userRoles?: string[]) => {
  if (!userId) return undefined
  if (!userRoles) return undefined
  if (userRoles?.some((role) => role?.toLocaleLowerCase() === 'onboarding'))
    return undefined

  return userId
}

export default washUserId
