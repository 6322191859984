import { Table, TableCell, Typography, styled } from '@mui/material'

export const StyledTable = styled(Table)(() => ({
  borderCollapse: 'collapse',
  width: '100%',
}))

export const TableHeadCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  padding: 0,
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(2),
  border: 0,
  minHeight: '48px',

  '&:first-of-type': {
    textAlign: 'left',
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(2),
    },
  },

  '&:not(:last-child)': {
    borderRight: `1px solid ${theme.palette.divider}`,
  },

  '&:not(:first-of-type)': {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },

  [theme.breakpoints.down('md')]: {
    '&:last-child': {
      paddingRight: 0,
    },
  },
}))

export const TableBodyCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  padding: 0,
  paddingBottom: theme.spacing(1),
  paddingTop: theme.spacing(1),
  border: 0,
  minHeight: '48px',

  '& *:first-of-type': {
    textAlign: 'left',
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(2),
    },
  },

  '&:not(:last-child)': {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}))

interface TableTypographyProps {
  bold?: boolean
}

export const TableTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'bold',
})<TableTypographyProps>(({ theme, bold }) => ({
  overflowWrap: 'break-word',
  hyphens: 'auto',
  ...theme.typography.caption,

  [theme.breakpoints.up('sm')]: {
    ...theme.typography.subtitle1,
    fontWeight: bold && 700,
  },
}))

export const ScreenReaderOnlyText = styled('span')(() => ({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: '1px',
}))
