const translations = {
  RadgivningBas: 'RadBas',
  RadgivningStandard: 'RadStd',
  RadgivningPlus: 'RadPlus',
  Avgangsersattning: 'Avgers',
} as const

const rolesToTrack = Object.keys(translations) as (keyof typeof translations)[]

type TrackableTranslatedRoles =
  (typeof translations)[keyof typeof translations][]

const translateTrackableRoles = (roles: typeof rolesToTrack) => {
  return roles.reduce((acc, role) => {
    return [...acc, translations[role]]
  }, [] as TrackableTranslatedRoles)
}

export const getFilteredTrackedRoles = (
  roles: string[]
): string | undefined => {
  const filteredRoles = roles.filter((role) =>
    rolesToTrack.some((i) => i === role)
  ) as typeof rolesToTrack
  return translateTrackableRoles(filteredRoles).join(', ') || undefined
}
