import { Cards } from '../CardsBlock'
import { CardItem } from 'apps/Site/components/Card'
import { Box, List, ListItem, styled } from '@mui/material'

interface CarouselProps {
  cards: Cards[]
  useColor: boolean
  color: string
  cardsPerPage: number
  animateCards: boolean
  setAnimateCards: (value: boolean) => void
  currentIndex: number
}

interface AnimateProp {
  animate: boolean
}

const CarouselCardsWrapper = styled(Box, {
  shouldForwardProp: (prop: string) => !['animate'].includes(prop),
})<AnimateProp>(({ animate }) => ({
  display: 'flex',
  transition: animate ? 'transform 0.5s ease-in-out' : 'none',
}))

interface CarouselContainerProp {
  component: React.ElementType
}

const CarouselContainer = styled(Box)<CarouselContainerProp>({
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
})

const Carousel = ({
  cards,
  useColor,
  color,
  cardsPerPage,
  animateCards,
  setAnimateCards,
  currentIndex,
}: CarouselProps) => {
  const getTabbableIndex = (
    currentIndex: number,
    index: number,
    cardsPerPage: number
  ) => {
    if (cardsPerPage === 1) {
      return currentIndex === index
    }
    return currentIndex <= index && index < currentIndex + cardsPerPage
  }
  return (
    <CarouselContainer data-testid="carousel-card-row" component={List}>
      <CarouselCardsWrapper
        data-testid="cardswrapper"
        animate={animateCards}
        sx={{
          flexDirection: 'row',
          transform: `translateX(${(-currentIndex * 100) / cardsPerPage}%)`,
        }}
        onTransitionEnd={() => setAnimateCards(false)}
      >
        {cards.map((card, index) => (
          <Box
            component={ListItem}
            data-testid={`card-${card.properties.heading}`}
            sx={{
              flex: cardsPerPage === 3 ? '1 0 33.33%' : '1 0 100%',
              padding: '0.33%',
            }}
            key={'card-' + card.properties.heading}
          >
            <CardItem
              isTabbable={getTabbableIndex(currentIndex, index, cardsPerPage)}
              key={'card-' + card.properties.heading}
              useImage={true}
              fullHeight
              color={color}
              useColor={useColor}
              {...card.properties}
              isEditorialCardBlock={card.type === 'EditorialCardBlock'}
            />
          </Box>
        ))}
      </CarouselCardsWrapper>
    </CarouselContainer>
  )
}

export default Carousel
