import { useCachedSettingsState } from 'state/PublicWebApi'
import { removeTrailingSlash } from 'utils/helpers'
import { useLocation } from 'react-router-dom'

const useRouteFullScreenMode = () => {
  const location = useLocation()
  const settings = useCachedSettingsState()
  return settings.pageSettings.fullScreenPageRoutes.some((route) => {
    return location.pathname
      .toLocaleLowerCase()
      .startsWith(removeTrailingSlash(route.toLocaleLowerCase()))
  })
}

export default useRouteFullScreenMode
