import { DataLayer } from 'features/Telemetry/GoogleTagManager/Types'

import config from '../config'

const extractEventData = (element: HTMLElement): DataLayer => {
  const elementConfig = config[element.tagName]

  return elementConfig.extractEventData(element)
}

export default extractEventData
