import {
  useContext,
  createContext,
  useCallback,
  useMemo,
  useState,
  type PropsWithChildren,
  useRef,
} from 'react'
import {
  ApplicationInsights,
  type Snippet,
} from '@microsoft/applicationinsights-web'

import { cookieName, getCookieValue } from 'utils/cookies'

const isTrrCookiesApproved = () => getCookieValue(cookieName.trrCookie) === '1'

export const applicationInsightsConfig: Snippet = {
  config: {
    connectionString: window.APP_CONFIGURATION.APPSHELL.AI_CONNECTION_STRING,
    enableAutoRouteTracking: true,
    disableFetchTracking: false,
    appId: 'public-web-frontend',
    maxBatchInterval: 100,
    enableCorsCorrelation: true,
    correlationHeaderDomains: ['*.trr.se', 'localhost'],
    cookieCfg: {
      enabled: isTrrCookiesApproved(),
    },
  },
}

interface IApplicationInsightsContext {
  appInsights?: ApplicationInsights
  isEnabled: boolean
  setEnabled: React.Dispatch<React.SetStateAction<boolean>>
}

const ApplicationInsightsContext = createContext<IApplicationInsightsContext>({
  appInsights: undefined,
  isEnabled: false,
  setEnabled: undefined,
})

const startApplicationInsights = (appInsights: ApplicationInsights) => {
  appInsights.core.getCookieMgr().setEnabled(true)
  appInsights.loadAppInsights()
  appInsights.trackPageView()
  appInsights.addTelemetryInitializer((envelope) => {
    envelope.tags['ai.cloud.role'] = 'trr-public-web-frontend'
  })
}

export const ApplicationInsightsProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const appInsightsRef = useRef<ApplicationInsights>(undefined)
  const [isEnabled, setIsEnabled] = useState(isTrrCookiesApproved())

  // only run once before first render
  if (!appInsightsRef.current) {
    const appInsights = new ApplicationInsights(applicationInsightsConfig)

    if (isTrrCookiesApproved()) {
      startApplicationInsights(appInsights)
    }

    appInsightsRef.current = appInsights
  }

  const setEnabled = useCallback(() => {
    if (!isEnabled) {
      startApplicationInsights(appInsightsRef.current)
      setIsEnabled(true)
    }
  }, [isEnabled, setIsEnabled])

  const contextValues = useMemo<IApplicationInsightsContext>(
    () => ({
      appInsights: appInsightsRef.current,
      isEnabled,
      setEnabled,
    }),
    [isEnabled, setEnabled]
  )

  return (
    <ApplicationInsightsContext.Provider value={contextValues}>
      {children}
    </ApplicationInsightsContext.Provider>
  )
}

export const useApplicationInsights = () => {
  const { appInsights, setEnabled, isEnabled } =
    useContext<IApplicationInsightsContext>(ApplicationInsightsContext)
  const enable = useCallback(() => {
    setEnabled(true)
  }, [setEnabled])

  return { appInsights, enable, isEnabled }
}
