import { IContentBlock } from 'apps/Site/Epi/BlockMapper/BlockMapper'
import { ScrollWrapper } from 'apps/Site/Epi/ScrollWrapper'
import blockMapper from 'apps/Site/Epi/BlockMapper'
import { Box, Typography, styled } from '@mui/material'
import ArticlesCardGrid from 'apps/Site/components/ArticlesCardGrid/ArticlesCardGrid'
import { useCallback, useRef } from 'react'
import {
  convertPageListToCards,
  convertPageListToPhotoTextBlock,
  PageListItem,
} from 'utils/helpers'

export interface PostListProps {
  heading: string
  text?: string
  blockArea?: IContentBlock[]
  pageListArea?: PageListItem[]
  highlightArea?: PageListItem[]
  fallbackUrl?: string
}

const Introduction = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  marginBottom: theme.spacing(7),
  maxWidth: '752px',
}))

const BlocksWrapper = styled(Box)(({ theme }) => ({
  '& > *': {
    marginBottom: theme.spacing(7),

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(10),
    },
  },
}))

const PostList = ({
  heading,
  text,
  blockArea,
  pageListArea,
  highlightArea,
  fallbackUrl,
}: PostListProps) => {
  const headingRef = useRef(null)

  const useArticlesCardGrid = pageListArea?.length > 0
  const useBlockWrapper = !useArticlesCardGrid && blockArea?.length > 0
  const hasHighlightArea = highlightArea?.length > 0

  const pageListAreaAsCards =
    useArticlesCardGrid &&
    convertPageListToCards(pageListArea, fallbackUrl, false)

  const pageAsPhotoTextBlock =
    useArticlesCardGrid &&
    hasHighlightArea &&
    convertPageListToPhotoTextBlock(highlightArea[0], null, false)

  const paginationCallback = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    headingRef?.current.focus()
  }, [headingRef])

  return (
    <ScrollWrapper>
      <Introduction>
        <Typography variant="h1" ref={headingRef}>
          {heading}
        </Typography>
        {text && <Typography variant="preamble">{text}</Typography>}
      </Introduction>
      {useArticlesCardGrid && (
        <ArticlesCardGrid
          articles={pageListAreaAsCards}
          firstArticle={hasHighlightArea && pageAsPhotoTextBlock}
          paginationCallback={paginationCallback}
        />
      )}
      {useBlockWrapper && (
        <BlocksWrapper>{blockMapper({ blocks: blockArea })}</BlocksWrapper>
      )}
    </ScrollWrapper>
  )
}

export default PostList
