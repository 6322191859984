import { gtmLoggerErrorClick, gtmLoggerErrorCustom } from './error'
import { gtmLoggerSuccess } from './success'

const gtmLogger = {
  success: gtmLoggerSuccess,
  errorClick: gtmLoggerErrorClick,
  errorCustom: gtmLoggerErrorCustom,
}

export default gtmLogger
