import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import createRootReducer from './rootReducer'
import { publicWebApi } from 'PublicWebApi'

export type RootState = ReturnType<typeof createRootReducer>

export const createStore = (preloadedState?: DeepPartial<RootState>) => {
  const store = configureStore({
    reducer: createRootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware().concat([publicWebApi.middleware])
    },
    preloadedState,
  })
  setupListeners(store.dispatch)
  return store
}

const store = createStore()

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T

export type AppDispatch = typeof store.dispatch

export default store
