import { handledHttpErrors } from 'PublicWebApi/Settings'
import { buildEvent } from 'features/Telemetry/GoogleTagManager/Helpers'
import TagManager from 'features/Telemetry/GoogleTagManager/TagManager'

export const trackPage = (contentError: handledHttpErrors = undefined) => {
  const dataLayer = buildEvent({
    action: 'view',
    item: 'page',
    contentError,
  })

  TagManager.dataLayer(dataLayer)
}
