import { useEffect, useState } from 'react'
import userManager from 'features/Authentication/userManager'
import { applicationInsights } from '@trr/app-shell-communication'
import { expiresAtToRoundedISOString, toRoundedISOString } from '../helpers'

const useClearExpiredUser = () => {
  const [completed, setCompleted] = useState(false)
  useEffect(() => {
    const removePotentialExpiredUser = async () => {
      const user = await userManager.getUser()
      if (user?.expired) {
        applicationInsights.trackEvent({
          name: 'removeExpiredUser',
          properties: {
            repo: 'public-web-frontend',
            userExpiresAt: expiresAtToRoundedISOString(user?.expires_at),
            clientTime: toRoundedISOString(new Date()),
            userProfileIssuedAt: expiresAtToRoundedISOString(
              user?.profile?.iat
            ),
          },
        })
        await userManager.clearStaleState()
        await userManager.removeUser()
      }
      setCompleted(true)
    }
    void removePotentialExpiredUser()
  }, [])
  return completed
}

export default useClearExpiredUser
