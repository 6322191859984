import { useEffect } from 'react'
import PageTypeMapper from 'apps/Site/Epi/PageTypeMapper'

import MetaTags from '../MetaTags'
import EnforceContactInformation from '../EnforceContactInformation'
import { useCachedContentQuery } from 'state/PublicWebApi'
import { Box } from '@mui/material'

const Content = ({ isFullScreen }: { isFullScreen: boolean }) => {
  const { data, isSuccess } = useCachedContentQuery()

  const isWide = data.contentType[1] === 'FunctionPageFull'

  const { properties, startPublish, contentType } = data
  const pageType = contentType[1]

  useEffect(() => {
    window.prerenderReady = isSuccess
  }, [isSuccess])

  if (isFullScreen) {
    return (
      <Box data-type={pageType} data-testid="content-fs">
        <MetaTags properties={properties} />
        <PageTypeMapper
          properties={{ ...properties, startPublish: startPublish }}
          pageType={pageType}
        />
      </Box>
    )
  }

  return (
    <Box
      data-type={pageType}
      data-testid="content"
      sx={
        isWide
          ? { mb: { xs: 5, sm: 10 }, mt: { xs: 0, sm: 5 } }
          : { my: { xs: 5, md: 10 } }
      }
    >
      <MetaTags properties={properties} />
      <EnforceContactInformation>
        <PageTypeMapper
          properties={{ ...properties, startPublish: startPublish }}
          pageType={pageType}
        />
      </EnforceContactInformation>
    </Box>
  )
}

export default Content
