import { useState } from 'react'

const storageIdKey = 'dashboardInformationBlockClosedIds'

const getSessionStoredIds = (): number[] => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const ids: number[] = JSON.parse(sessionStorage.getItem(storageIdKey)) ?? []
  return ids
}

const setSessionStoredIds = (ids: number[]) => {
  sessionStorage.setItem(storageIdKey, JSON.stringify(ids))
}

const useCloseDashboardInformation = (
  dashboardInformationBlockId: number
): {
  closed: boolean
  pushClose: (id: number) => void
} => {
  const [ids, setIds] = useState<number[]>(getSessionStoredIds())
  const pushClose = (idToClose: number) => {
    setSessionStoredIds([...ids, idToClose])
    setIds([...ids, idToClose])
  }
  return { closed: ids.includes(dashboardInformationBlockId), pushClose }
}

export default useCloseDashboardInformation
