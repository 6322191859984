import { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useSliceStateSelector } from 'slices/hooks'
import { CardItem, CardProps } from 'apps/Site/components/Card'

import {
  Row,
  CardsWrapper,
  HeadingAndPreambleWrapper,
} from './CardsBlock.styles'
import Carousel from './Carousel'
import CarouselArrows from './CarouselArrows'

export interface CardsBlockProps {
  content: {
    heading?: string
    preamble?: string
    color?: string
    useColor: boolean
    useCarousel: boolean
    cards: Cards[]
  }
}

export interface Cards {
  properties: CardProps
  type: string
}

export const separateCardsIntoRows = (cards: Cards[]): Cards[][] => {
  const cardsInRowsOfMaxThreeCards = cards?.reduce<Cards[][]>(
    (acc, card, index) => {
      if (index % 3 === 0) {
        acc.push([])
      }
      acc[acc.length - 1].push(card)
      return acc
    },
    []
  )

  const lastRow =
    cardsInRowsOfMaxThreeCards?.[cardsInRowsOfMaxThreeCards.length - 1]
  if (cardsInRowsOfMaxThreeCards.length > 1 && lastRow.length === 1) {
    const secondToLastCard =
      cardsInRowsOfMaxThreeCards[cardsInRowsOfMaxThreeCards.length - 2]?.pop()
    lastRow?.unshift(secondToLastCard)
  }

  return cardsInRowsOfMaxThreeCards
}

const CardsBlock = ({
  content: { heading, preamble, color, useColor, useCarousel, cards },
}: CardsBlockProps) => {
  const cardsInRows = separateCardsIntoRows(cards)
  const shouldShowImages: boolean = cards.every((card) => card.properties.image)
  const [animateCards, setAnimateCards] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)

  const { isMobile } = useSliceStateSelector(
    (state) => state.slices.device.deviceSize
  )

  const getCardsPerPage = () => {
    if (isMobile) {
      return 1
    }
    return 3
  }

  const showCarousel = useCarousel && cards.length > (isMobile ? 2 : 3)

  return (
    <Box display="flex" flexDirection="column" component="section">
      <Box display="flex" flexDirection="row" mb={{ xs: 3, md: 5 }}>
        <HeadingAndPreambleWrapper>
          {heading && (
            <Typography variant="h3" component="h2" mb={{ xs: 3, md: 1 }}>
              {heading}
            </Typography>
          )}

          {preamble && <Typography variant="body1">{preamble}</Typography>}
        </HeadingAndPreambleWrapper>
        {showCarousel && (
          <CarouselArrows
            numberOfCards={cards.length}
            setCurrentIndex={setCurrentIndex}
            setAnimateCards={setAnimateCards}
            isMobile={isMobile}
            currentIndex={currentIndex}
          />
        )}
      </Box>
      {showCarousel ? (
        <Carousel
          cards={cards}
          useColor={useColor}
          color={color}
          cardsPerPage={getCardsPerPage()}
          animateCards={animateCards}
          setAnimateCards={setAnimateCards}
          currentIndex={currentIndex}
        />
      ) : (
        cardsInRows?.length > 0 && (
          <CardsWrapper>
            {cardsInRows.map((row, index) => (
              <Row key={'row-' + `${index}`} data-testid="card-row">
                {row.map((card) => (
                  <CardItem
                    numberOfCardsInRow={row.length}
                    key={'card-' + card.properties.heading}
                    useImage={shouldShowImages}
                    color={color}
                    useColor={useColor}
                    {...card.properties}
                    isEditorialCardBlock={card.type === 'EditorialCardBlock'}
                  />
                ))}
              </Row>
            ))}
          </CardsWrapper>
        )
      )}
    </Box>
  )
}

export default CardsBlock
