import { Box, ButtonBase, Link, styled } from '@mui/material'

interface ContactLinkProps {
  href?: string
  target?: string
}

export const LogoLinkWrapper = styled(Link)(({ theme }) => ({
  border: 'solid',
  borderRadius: '2px',
  borderColor: 'transparent',
  borderWidth: '1px',

  '&:focus': {
    outline: 'none',
    borderColor: theme.palette.primary?.main,

    '&:not(:focus-visible)': {
      borderColor: 'transparent',
    },
  },
}))

export const ContactLinksColumnWrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
  padding: `0 ${theme.spacing(3)} ${theme.spacing(0)} ${theme.spacing(2)}`,

  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(5),
  },

  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(6),
    width: '356px',
  },
}))

export const ContactLink = styled(ButtonBase)<ContactLinkProps>(
  ({ theme }) => ({
    ...theme.typography.body2,
    display: 'flex',
    margin: `${theme.spacing(1.5)} 0`,
    gap: theme.spacing(1),
    border: 'solid',
    borderRadius: '2px',
    borderColor: 'transparent',
    borderWidth: '1px',

    '&:hover, &:active': {
      textDecoration: 'underline',
    },

    '&:focus': {
      outline: 'none',
      borderColor: theme.palette.primary?.main,

      '&:not(:focus-visible)': {
        borderColor: 'transparent',
      },
    },
  })
)
