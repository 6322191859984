import { ListItem } from '@mui/material'
import {
  FoldoutProps,
  MegaNavigationMenuItem,
} from 'PublicWebApi/Settings/types'
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded'

import { useContext } from 'react'
import { useTrackCustomClick } from 'features/Telemetry/GoogleTagManager/Hooks/useTrackCustomClick'
import { SubMenuContext } from '../../Navbar/Navbar'
import { StyledMenuFoldoutItem } from '../Menu.styles'

interface MenuItemFoldoutProps {
  menuItem: MegaNavigationMenuItem
  settingSubmenuItems: (items: FoldoutProps[]) => void
}

const MenuItemFoldout = ({
  menuItem,
  settingSubmenuItems,
}: MenuItemFoldoutProps) => {
  const trackCustomClick = useTrackCustomClick()
  const { setIsSubmenuOpen, setSubmenuTitle } = useContext(SubMenuContext)

  const openSubmenu = (label: string, items: FoldoutProps[]) => {
    setIsSubmenuOpen(true)
    settingSubmenuItems(items)
    setSubmenuTitle(label)
    trackCustomClick('Menu foldout', {
      label: `Open ${label}`,
    })
  }

  return (
    <ListItem
      data-testid={`menu-foldoutitem-${menuItem.title}`}
      onClick={() => openSubmenu(menuItem.title, menuItem.children)}
    >
      <StyledMenuFoldoutItem data-testid={`foldoutitem-${menuItem.title}`}>
        {menuItem.title}
        <NavigateNextRoundedIcon sx={{ color: 'text.secondary' }} />
      </StyledMenuFoldoutItem>
    </ListItem>
  )
}

export default MenuItemFoldout
