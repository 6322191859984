import { Box, ButtonBase, styled } from '@mui/material'

export interface FoldoutLinkProps {
  href?: string
  disableRipple?: boolean
}

export const FoldoutAreaWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexBasis: 'auto',
  position: 'absolute',
  zIndex: '1000',
  backgroundColor: 'white',
  width: '100%',
  padding: `${theme.spacing(8)} ${theme.spacing(6)}`,
  gap: theme.spacing(5),
  borderBottom: '1px solid rgba(26, 26, 26, 0.12)',
  boxShadow:
    '0px 8px 10px -5px rgba(0, 0, 0, 0.20), 0px 16px 24px -24px rgba(0, 0, 0, 0.14), 0px 6px 30px -30px rgba(0, 0, 0, 0.12)',
}))

export const FoldoutAreaRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(5),
  width: theme.breakpoints.values.lg,
  margin: 'auto',
}))

export const FoldoutLinksSection = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '240px',
  width: '100%',
}))

export const FoldoutLink = styled(ButtonBase)<FoldoutLinkProps>(
  ({ theme }) => ({
    fontSize: '1rem',
    fontFamily: 'Raleway',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1.75),
    padding: `${theme.spacing(0.25)}`,
    border: 'solid',
    borderRadius: '2px',
    borderColor: 'transparent',
    borderWidth: '1px',

    '&:active': {
      color: theme.palette.primary?.main,
    },

    '&:focus': {
      outline: 'none',
      borderColor: theme.palette.primary?.main,

      '&:not(:focus-visible)': {
        borderColor: 'transparent',
      },
    },

    '&:hover': {
      color: theme.palette.primary?.main,
    },
  })
)

export const FoldoutBannerSection = styled(Box)(() => ({
  display: 'flex',
  height: '100%',
  maxWidth: '360px',
  width: '100%',
}))

export const FoldoutBanner = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid transparent',
  width: '100%',
  height: '100%',
}))
