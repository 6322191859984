import { LoginFlow } from 'apps/LoginFlow'

import { useLocation } from 'react-router-dom'
import { useRedirectToLogin } from './Hooks'
import { shouldShowLoginFlow } from './Helpers'
import { Site } from 'apps/Site'

const Apps = () => {
  useRedirectToLogin()
  const location = useLocation()

  if (shouldShowLoginFlow(location.pathname)) {
    return <LoginFlow />
  }

  return <Site />
}

export default Apps
