import { Children, type PropsWithChildren } from 'react'
import { pageIsSummaryInEnglish } from 'utils/helpers'

import { Box } from '@mui/material'

const FullScreenMain = ({ children }: PropsWithChildren) => {
  const isSummaryInEnglish = pageIsSummaryInEnglish(window.location.pathname)

  return (
    <Box
      data-testid="fullScreenMain"
      component="main"
      width="100%"
      mx="auto"
      lang={isSummaryInEnglish ? 'en' : null}
    >
      {Children.only(children)}
    </Box>
  )
}

export default FullScreenMain
