import { Box, styled } from '@mui/material'
import HeroAreaPanel, {
  HeroAreaPanelProps,
} from 'apps/Site/components/HeroAreaPanel/HeroAreaPanel'

import BlockMapper from '../../BlockMapper'
import { IContentBlock } from '../../BlockMapper/BlockMapper'

export interface BlockPageProps {
  heroAreaPanel: HeroAreaPanelProps[]
  blockArea: IContentBlock[]
}

const BlocksWrapper = styled(Box)(({ theme }) => ({
  '& > *': {
    marginBottom: theme.spacing(7),

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(10),
    },
  },
}))

const Block = ({ heroAreaPanel = [], blockArea }: BlockPageProps) => {
  return (
    <>
      {heroAreaPanel?.length > 0 && (
        <HeroAreaPanel
          type={heroAreaPanel[0].type}
          properties={heroAreaPanel[0].properties}
        />
      )}
      <BlocksWrapper>
        {blockArea?.length > 0 && BlockMapper({ blocks: blockArea })}
      </BlocksWrapper>
    </>
  )
}

export default Block
