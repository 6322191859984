import { IconButton, styled } from '@mui/material'
import { FontIcon } from '@trr/internal-helpers'

interface VideoPlayButtonProps {
  onClickAction: () => void
}

interface StyledIconButtonProps {
  disableRipple?: boolean
}

const StyledIconButton = styled(IconButton)<StyledIconButtonProps>(() => ({
  alignItems: 'center',
  backgroundColor: '#fff',
  borderRadius: '50%',
  cursor: 'pointer',
  display: 'flex',
  height: '80px',
  justifyContent: 'center',
  left: 'calc(50% - 40px)',
  position: 'absolute',
  top: 'calc(50% - 40px)',
  width: '80px',
  zIndex: 10,

  '&:hover': {
    backgroundColor: '#fff',
  },
}))

const VideoPlayButton = ({ onClickAction }: VideoPlayButtonProps) => {
  return (
    <StyledIconButton
      onClick={onClickAction}
      aria-hidden="true"
      disableRipple
      sx={{ boxShadow: 3 }}
      data-testid="play-button"
    >
      <FontIcon iconName="PlayArrow" color="purple" customSize={60} />
    </StyledIconButton>
  )
}

export default VideoPlayButton
