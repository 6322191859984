import Player from '@vimeo/player'

export const getVideoId = (video: string): number => {
  if (!video) {
    return null
  }
  if (!video.includes('/')) {
    return parseInt(video)
  }
  const url = new URL(video)
  return parseInt(url.pathname.split('/').pop())
}

export const getVideoTitle = async (player: Player) => {
  return await player.getVideoTitle()
}

const setIframeTitle = async (player: Player, wrappingElementId: string) => {
  const videoTitle = await getVideoTitle(player)
  document
    .getElementById(wrappingElementId)
    ?.getElementsByTagName('iframe')?.[0]
    ?.setAttribute('title', `Video: ${videoTitle}`)
}

const defaultOptions = {
  color: '411782',
  dnt: true,
  pip: true,
  title: true,
}

const noControlsOptions = {
  loop: true,
  controls: false,
  muted: true,
}

const autoplayOptions = {
  autoplay: true,
}

export const loadVideo = async (
  videoId: number,
  elementId: string,
  useAutoplay: boolean,
  reducedMotion: boolean
) => {
  try {
    const player = new Player(elementId, {
      id: videoId,
      ...defaultOptions,
      ...(useAutoplay && !reducedMotion && autoplayOptions),
      ...(useAutoplay && noControlsOptions),
    })
    await setIframeTitle(player, elementId)
    return player
  } catch (error) {
    console.error(error)
    return null
  }
}

export const playVideo = async (player: Player) => {
  await player.play()
}

export const pauseVideo = async (player: Player) => {
  await player.pause()
}

export const getDuration = async (player: Player) => {
  return player.getDuration()
}

export const getRandomVideoElementId = () => {
  const crypto = window.crypto
  const array = new Uint32Array(1)
  return `vimeo-player-${Date.now().toString(36)}-${crypto
    .getRandomValues(array)[0]
    .toString(36)
    .slice(-7)}`
}

export const onPlayCallback = (player: Player, callback: () => void) => {
  player.on('play', callback)
}

export const onPauseCallback = (player: Player, callback: () => void) => {
  player.on('pause', callback)
}

export const onPlayingCallback = (player: Player, callback: () => void) => {
  player.on('playing', callback)
}

export const onLoadedCallback = (player: Player, callback: () => void) => {
  player.on('loaded', callback)
}
