import { Box, ButtonBase, styled } from '@mui/material'

import { NavLink } from 'react-router-dom'

interface MenuButtonProps {
  url?: string
  onClickAction?: () => void
  size?: 'small' | 'large'
  attributes?: Record<string, unknown>
}

const shouldBeExact = (url: string): boolean => {
  // Returns true if the url matches /anytext/
  const regexPattern = /^\/([^/]*?\/)?$/

  return regexPattern.test(url)
}

interface MenuButtonStyleProps {
  largeMenuButton: boolean
  to?: string
  exact?: boolean
  disableRipple?: boolean
}

const StyledMenuButtonComponent = styled(Box, {
  shouldForwardProp: (prop: string) => !['largeMenuButton'].includes(prop),
})<MenuButtonStyleProps>(({ theme, largeMenuButton }) => ({
  display: 'flex',
  alignItems: 'center',
  border: 'solid',
  borderColor: 'transparent',
  borderLeft: 0,
  borderRadius: '0px 32px 32px 0px',
  borderWidth: '2px',
  fontWeight: 500,
  gap: theme.spacing(2),
  padding: `0 ${theme.spacing(3)}`,
  textDecoration: 'none',
  width: `calc(100% - ${theme.spacing(1)})`,
  height: largeMenuButton ? theme.spacing(7) : theme.spacing(6),
  color: theme.palette.neutral?.main,

  [theme.breakpoints.up('lg')]: {
    width: '100%',
  },

  '&.active': {
    backgroundColor: theme.palette.surface?.purple,
    color: theme.palette.primary?.main,
  },

  '@media (hover: hover) and (pointer: fine)': {
    '&:hover': {
      backgroundColor: theme.palette.surface?.purple,
    },
  },

  '&:active': {
    backgroundColor: theme.palette.primary?.light,
  },

  '&:focus': {
    outline: 0,
    borderColor: theme.palette.primary?.main,

    '&:not(:focus-visible)': {
      borderColor: 'transparent',
    },
  },
}))

const MenuButton = ({
  children,
  url,
  onClickAction,
  size,
  attributes,
}: React.PropsWithChildren<MenuButtonProps>) => {
  const hasLargeButtonSize = size === 'large'
  return (
    <>
      {url ? (
        <StyledMenuButtonComponent
          as={NavLink}
          to={url}
          exact={shouldBeExact(url)}
          data-testid="menu-button-link"
          largeMenuButton={hasLargeButtonSize}
          {...(onClickAction && { onClick: onClickAction })}
          {...attributes}
        >
          {children}
        </StyledMenuButtonComponent>
      ) : (
        <StyledMenuButtonComponent
          as={ButtonBase}
          largeMenuButton={hasLargeButtonSize}
          onClick={onClickAction}
          disableRipple
          {...attributes}
        >
          {children}
        </StyledMenuButtonComponent>
      )}
    </>
  )
}

export default MenuButton
