import { FontIcon } from '@trr/internal-helpers'
import { List, ListItem } from '@mui/material'
import Language from '@mui/icons-material/Language'
import AccountCircle from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'

import { MegaNavigationCTAButton } from 'PublicWebApi/Settings/types'
import { visitorContext } from 'slices/VisitorContext/types'
import { useAuthentication } from 'features/Authentication/Hooks'
import { useTrackCustomClick } from 'features/Telemetry/GoogleTagManager/Hooks/useTrackCustomClick'

import AvatarButton from '../../AvatarButton'
import { StyledSecondaryActionButton } from '../../NewHeader.styles'
import { getLoggedInContext, getLoginUrl } from '../../NewHeader.hooks'

interface ActionButtonsProps {
  visitorContext: visitorContext
  ctaButton: MegaNavigationCTAButton
  onClick: () => void
}

const ActionButtons = ({
  visitorContext,
  ctaButton,
  onClick,
}: ActionButtonsProps) => {
  const { isLoggedIn, profile, logout } = useAuthentication()

  const userRole = profile?.idp

  const trackCustomClick = useTrackCustomClick()

  const onClickActionButton = (label: string, href: string) => {
    trackCustomClick('Menu action button', {
      label: label,
      href: href,
    })
    onClick()
  }

  const showLogoutButton = visitorContext === 'medarbetare'

  return (
    <List
      sx={{
        paddingBottom: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <ListItem>
        {isLoggedIn ? (
          <AvatarButton
            isMobile
            inMobileMenu
            onClickMobileAction={onClick}
            visitorContext={getLoggedInContext(userRole, visitorContext)}
          />
        ) : (
          <StyledSecondaryActionButton
            isMobile
            data-testid="mobile-actionbutton-login"
            href={getLoginUrl('/login', visitorContext)}
            onClick={() =>
              onClickActionButton(
                'Logga in',
                getLoginUrl('/login', visitorContext)
              )
            }
          >
            <AccountCircle />
            Logga in
          </StyledSecondaryActionButton>
        )}
      </ListItem>
      <ListItem>
        <StyledSecondaryActionButton
          isMobile
          href={'/trr-in-english'}
          disableRipple
          data-testid={`mobile-actionbutton-in-english`}
          onClick={() => onClickActionButton('In english', '/trr-in-english')}
        >
          <Language />
          In English
        </StyledSecondaryActionButton>
      </ListItem>
      {ctaButton?.buttonTitle && (
        <ListItem>
          <StyledSecondaryActionButton
            isMobile
            href={ctaButton.buttonUrl}
            disableRipple
            data-testid={`mobile-actionbutton-cta`}
            onClick={() =>
              onClickActionButton(ctaButton.buttonTitle, ctaButton.buttonUrl)
            }
          >
            <FontIcon iconName={ctaButton.buttonIcon} />
            {ctaButton.buttonTitle}
          </StyledSecondaryActionButton>
        </ListItem>
      )}
      {showLogoutButton && (
        <ListItem>
          <StyledSecondaryActionButton
            isMobile
            disableRipple
            data-testid={`mobile-actionbutton-logout`}
            onClick={logout}
          >
            <LogoutIcon />
            Logga ut
          </StyledSecondaryActionButton>
        </ListItem>
      )}
    </List>
  )
}

export default ActionButtons
