import { Box, List, ListItem, Typography } from '@mui/material'
import { FoldoutProps } from 'PublicWebApi/Settings/types'

import { StyledMenuLink } from '../Menu/Menu.styles'
import { useTrackCustomClick } from 'features/Telemetry/GoogleTagManager/Hooks/useTrackCustomClick'

interface SubmenuProps {
  SubmenuItems: FoldoutProps[]
  onClickLink: () => void
}

const Submenu = ({ SubmenuItems, onClickLink }: SubmenuProps) => {
  const trackCustomClick = useTrackCustomClick()

  const onClickSubmenuLink = (label: string, href: string) => {
    trackCustomClick('Menu foldout link', {
      label: label,
      href: href,
    })
    onClickLink()
  }

  return (
    <Box display={'flex'} flexDirection={'column'} data-testid="submenu-mobile">
      {SubmenuItems.map((item, index) => (
        <Box paddingBottom={4} key={`submenu-item-${index.toString()}`}>
          <Typography
            paddingLeft={1}
            variant="subtitle2"
            color={'text.secondary'}
            key={`submenu-header-${index.toString()}`}
            data-testid={`submenu-header-${item.title}`}
          >
            {item.title}
          </Typography>
          <List sx={{ display: 'flex', flexDirection: 'column' }}>
            {item.children?.map((link, index) => (
              <ListItem key={`submenu-link-${index.toString()}`}>
                <StyledMenuLink
                  key={`submenu-link-${index.toString()}`}
                  data-testid={`submenu-link-${link.title}`}
                  href={link.url}
                  onClick={() => onClickSubmenuLink(link.title, link.url)}
                >
                  {link.title}
                </StyledMenuLink>
              </ListItem>
            ))}
          </List>
        </Box>
      ))}
    </Box>
  )
}

export default Submenu
