import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'

export interface NavigationInformationResult {
  fromExternal: boolean
  fromInternal: boolean
}

let hasNavigated = false

export const useNavigationInformation = (): NavigationInformationResult => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { listen } = useHistory()
  useEffect(() => {
    listen(() => {
      hasNavigated = true
    })
  }, [listen])

  return {
    fromExternal: !hasNavigated,
    fromInternal: hasNavigated,
  }
}
