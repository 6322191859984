/* 
    This is a priorty list of which roles we think are the most important to show in Google Analytics
    Should not be used outside of the GTM context.
 */

const rolePrio = [
  'Medarbetare',
  'KundOmfattas',
  'KundOmfattasEj',
  'KundAvtalAvslutat',
  'TIAAktiv',
  'TIAOprovad',
  'TIOAktiv',
  'TIOOprovad',
  'TIOAvslutad',
  'TIAAvslutad',
]

const getUserGroup = (roles: string[]) => {
  let userGroup = rolePrio.find((role) => roles.includes(role))

  if (userGroup === 'TIOAvslutad' || userGroup === 'TIAAvslutad') {
    userGroup = 'Avslutad'
  }

  return userGroup
}

export { getUserGroup, rolePrio }
