import { useAuthentication } from 'features/Authentication/Hooks'
import { useCachedSettingsState } from 'state/PublicWebApi'
import { removeTrailingSlash } from 'utils/helpers'
import { useLocation } from 'react-router-dom'

export const useDashboard = () => {
  const { myTrrNavigation, trrBusinessNavigation } = useCachedSettingsState()
  const { pathname } = useLocation()
  const { isLoggedIn } = useAuthentication()

  const prAdjustedPathname = removeTrailingSlash(pathname)

  const dashboardContentUrls = [
    { url: myTrrNavigation?.button?.url, menuSettings: myTrrNavigation },
    {
      url: trrBusinessNavigation?.button?.url,
      menuSettings: trrBusinessNavigation,
    },
  ]

  const isDashboardPage = dashboardContentUrls.some(
    ({ url, menuSettings }) =>
      url &&
      prAdjustedPathname?.toLowerCase().startsWith(removeTrailingSlash(url)) &&
      menuSettings
  )

  return isLoggedIn && isDashboardPage
}
