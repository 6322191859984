import { Box, CircularProgress } from '@mui/material'
import { useContentUrl } from 'utils/hooks'
import { contentApi } from 'state/PublicWebApi/Content'
import { PropsWithChildren } from 'react'
import { ContentError } from './ContentError'

const FetchContent = ({ children }: PropsWithChildren) => {
  const url = useContentUrl()
  const { isLoading, isFetching, isError, error } =
    contentApi.endpoints.getContent.useQuery(url)

  if (!isLoading && !isError && !isFetching) {
    return <>{children}</>
  }
  if (isError) {
    return <ContentError error={error} />
  }
  return (
    <Box display="flex" flex="1 1 auto" justifyContent="center" py="10%">
      <CircularProgress />
    </Box>
  )
}

export default FetchContent
