import { Box, IconButton, styled } from '@mui/material'

export const HeaderContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#fff',
}))

export const HeaderContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '80px',
  justifyContent: 'space-between',
  padding: `0 ${theme.spacing(2)} 0 ${theme.spacing(1)}`,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    padding: `0 ${theme.spacing(3)}`,
  },
  [theme.breakpoints.up('lg')]: {
    padding: `0 ${theme.spacing(4)}`,
  },
}))

export const HeaderSegmentLeft = styled('nav')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(2),
}))

export const MenuButton = styled(IconButton)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  height: '48px',
  width: '48px',
  borderRadius: 0,

  '&:focus': {
    outline: 'none',
    boxShadow: `0 0 0 2px ${theme.palette.primary?.main}`,
    '&:not(:focus-visible)': {
      boxShadow: 'none',
    },
  },
}))

export const HeaderSegmentRight = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'flex-end',
}))
