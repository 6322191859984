import { ArticleListItemProps } from 'apps/Site/components/ArticleList/ArticleListItem'
import blockMapper from 'apps/Site/Epi/BlockMapper'
import { ScrollWrapper } from 'apps/Site/Epi/ScrollWrapper'
import { IContentBlock } from 'apps/Site/Epi/BlockMapper/BlockMapper'
import { IntroductionContentProps } from 'apps/Site/Epi/Blocks/Introduction/Introduction'
import ArticleContent from 'apps/Site/components/ArticleContent'
import { ArticleVideoBlockProps } from 'apps/Site/Epi/Blocks/ArticleVideoBlock/ArticleVideoBlock'
import { TableOfContentLink } from 'state/PublicWebApi/Content'
import { Box } from '@mui/material'

interface INewsProps {
  blocks?: IContentBlock[]
  editor?: string
  introduction?: IntroductionContentProps
  tableOfContents?: TableOfContentLink[]
  relatedContent?: ArticleListItemProps[]
  customCategory?: string
  startPublish?: string
  image?: string
  videoArea?: ArticleVideoBlockProps[]
  isPublic: boolean
}

const News = ({
  blocks,
  editor,
  introduction,
  tableOfContents,
  relatedContent,
  customCategory,
  startPublish,
  image,
  videoArea,
  isPublic,
}: INewsProps) => {
  return (
    <ScrollWrapper>
      <ArticleContent
        introduction={introduction}
        tableOfContents={tableOfContents}
        customCategory={customCategory}
        image={image}
        editor={editor}
        relatedContent={relatedContent}
        startPublish={startPublish}
        videoArea={videoArea}
        isPublic={isPublic}
      />
      <Box>{blocks.length > 0 && <>{blockMapper({ blocks })}</>}</Box>
    </ScrollWrapper>
  )
}

export default News
