import { Typography } from '@mui/material'
import classNames from 'classnames'
import MenuButton from 'apps/Site/components/MenuButton'

import s from './LeftMenu.module.scss'
import { useSliceStateSelector, useSliceDispatch } from 'slices/hooks'

interface LeftMenuProps {
  testSelector?: string
  navigationLinks?: { text: string; url: string }[]
}

const LeftMenu = ({ testSelector, navigationLinks }: LeftMenuProps) => {
  const { smallDeviceMenuStatus } = useSliceStateSelector(
    (state) => state.slices.menus
  )
  const trigger = useSliceDispatch()
  const showMenu = smallDeviceMenuStatus === 'left'
  return (
    <div
      className={classNames(s.MenuContainer, showMenu ? s.Show : s.Hidden)}
      data-testid={testSelector || 'left-menu-nav'}
    >
      <ul>
        {navigationLinks?.map((link, index) => (
          <li key={index}>
            <MenuButton
              url={link.url}
              onClickAction={() => trigger('setSmallDeviceMenuStatus', 'none')}
              size="large"
            >
              <Typography variant="h6" component="p">
                {link.text}
              </Typography>
            </MenuButton>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default LeftMenu
