import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAuthentication } from 'features/Authentication/Hooks'
import { publicWebApi } from 'state/PublicWebApi'
import { settingsApi } from 'state/PublicWebApi/Settings'

const useKeepSettingsUpToDate = () => {
  const [fetchInitialSettings, settingsData] =
    settingsApi.endpoints.getSettings.useLazyQuery()

  const dispatch = useDispatch()

  const { isLoggedIn, profile, idToken } = useAuthentication()
  const { sub, userRoles } = profile

  useEffect(() => {
    const checkUserAndPossiblyInvalidateSettings = async () => {
      if (settingsData.isUninitialized) {
        await fetchInitialSettings()
      } else {
        dispatch(publicWebApi.util.invalidateTags(['Settings']))
      }
    }
    void checkUserAndPossiblyInvalidateSettings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, sub, idToken, userRoles])
  return { isSuccess: settingsData.isSuccess, isError: settingsData.isError }
}

export default useKeepSettingsUpToDate
