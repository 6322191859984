import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CookieState } from './types'
import Cookies from 'js-cookie'
import { cookieName } from 'utils/cookies'

const cookieHasApprovedCookies = cookieName.trrCookie
const cookiesApprovedStatus = '1'

const initialState: CookieState = {
  isTrrCookiesApproved:
    Cookies.get(cookieHasApprovedCookies) === cookiesApprovedStatus,
}

const cookiesSlice = createSlice({
  name: 'cookies',
  initialState,
  reducers: {
    setCookieApproval: (
      state,
      action: PayloadAction<boolean>
    ): CookieState => ({
      ...state,
      isTrrCookiesApproved: action.payload,
    }),
  },
})

export default cookiesSlice
