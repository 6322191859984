import { Box, alpha, styled } from '@mui/material'
import { getBackgroundColor } from 'apps/Site/hooks/getBackgroundColor'

interface ColorProps {
  useColor?: boolean
  color?: string
  useColorStyling?: boolean
}

interface BigLayoutProps {
  hasBigLayout: boolean
}

interface PhotoWrapperProps extends BigLayoutProps {
  useFoldoutImage?: boolean
}

interface HeroSectionProps extends ColorProps, PhotoWrapperProps {}

export const HeroSection = styled('section', {
  shouldForwardProp: (props: string) =>
    !['useColor', 'color', 'hasBigLayout', 'useFoldoutImage'].includes(props),
})<HeroSectionProps>(
  ({ theme, useColor, color, hasBigLayout, useFoldoutImage }) => ({
    width: '100vw',
    position: 'relative',
    left: '50%',
    marginLeft: '-50vw',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(-5),

    ...(useColor &&
      color && {
        backgroundColor: getBackgroundColor(theme, color, useColor),
      }),

    ...(hasBigLayout &&
      useFoldoutImage && {
        marginBottom: theme.spacing(10),
      }),

    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(-10),
      ...(hasBigLayout &&
        useFoldoutImage && {
          marginBottom: theme.spacing(20),
        }),
    },
  })
)

export const HeroContentWrapper = styled(Box, {
  shouldForwardProp: (props: string) =>
    !['hasBigLayout', 'useFoldoutImage'].includes(props),
})<PhotoWrapperProps>(({ theme, hasBigLayout, useFoldoutImage }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'min(1536px, 100vw)',
  padding: `${theme.spacing(5)} 0`,
  ...(hasBigLayout &&
    useFoldoutImage && {
      paddingBottom: 0,
    }),

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    padding: `${theme.spacing(10)} ${theme.spacing(6)}`,
    ...(hasBigLayout &&
      useFoldoutImage && {
        padding: `0 ${theme.spacing(6)}`,
      }),
  },
}))

export const TextAreaWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    padding: `0 ${theme.spacing(10)} 0  0`,
    width: '50%',
  },
}))

export const HeadingAndIntroWrapper = styled(Box, {
  shouldForwardProp: (props: string) =>
    !['hasBigLayout', 'useFoldoutImage'].includes(props),
})<PhotoWrapperProps>(({ theme, hasBigLayout, useFoldoutImage }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `0 ${theme.spacing(4)}`,
  marginBottom: theme.spacing(5),
  width: '100%',

  ...(hasBigLayout &&
    useFoldoutImage && {
      marginBottom: 0,
    }),

  [theme.breakpoints.up('md')]: {
    padding: 0,
    marginBottom: '0',
  },
}))

export const HeadingWrapper = styled('div')(({ theme }) => ({
  margin: `0 0 ${theme.spacing(4)} 0`,
  maxWidth: '752px',
  display: 'flex',
  flexDirection: 'column-reverse',
  gap: theme.spacing(2),
}))

export const IntroWrapper = styled(Box)(({ theme }) => ({
  '& a[class*=_Button]': {
    padding: `0 ${theme.spacing(4)}`,
    height: '54px',
  },
}))

export const VideoWrapper = styled(Box, {
  shouldForwardProp: (props: string) => !['hasBigLayout'].includes(props),
})<BigLayoutProps>(({ theme, hasBigLayout }) => ({
  display: 'flex',
  flexGrow: 1,
  flexShrink: 1,
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    margin: 0,
  },

  '.LazyLoadWrapper': {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  ...(hasBigLayout
    ? {
        height: '100vw',
        [theme.breakpoints.up('md')]: {
          height: '600px',
        },
        '&:focus-visible': {
          outline: 'none',
          color: theme.palette.text?.primary,
          backgroundColor: alpha(theme.palette.primary?.main, 0.3),
        },
        '&:hover': {
          backgroundColor: theme.palette.surface?.purple,
          color: theme.palette.primary?.main,
          svg: {
            color: theme.palette.primary?.main,
          },
        },
      }
    : {
        [theme.breakpoints.up('md')]: {
          height: '440px',
          paddingLeft: theme.spacing(15),
        },
      }),
}))

export const ImageWrapper = styled(Box, {
  shouldForwardProp: (props: string) =>
    !['hasBigLayout', 'useFoldoutImage'].includes(props),
})<PhotoWrapperProps>(({ theme, hasBigLayout, useFoldoutImage }) => ({
  position: 'relative',
  padding: `0 ${theme.spacing(3)}`,
  [theme.breakpoints.up('md')]: {
    width: '50%',
    padding: 0,
  },

  ...(hasBigLayout &&
    useFoldoutImage && {
      bottom: theme.spacing(-5),
      [theme.breakpoints.up('md')]: {
        bottom: theme.spacing(-10),
      },
    }),

  '.LazyLoadWrapper': {
    width: '100%',
    height: '100%',
  },
  img: {
    objectFit: 'cover',
    verticalAlign: 'middle',
    borderRadius: '8px',
    width: '100%',
    height: '100%',

    ...(hasBigLayout
      ? {
          aspectRatio: 4 / 3,
          [theme.breakpoints.up('md')]: {
            height: '100%',
            minHeight: '600px',
            maxWidth: '720px',
          },
        }
      : {
          [theme.breakpoints.up('md')]: {
            minHeight: '440px',
            maxWidth: '720px',
          },
        }),
  },
}))
