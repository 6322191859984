import Article from 'apps/Site/Epi/PageTypes/Article/Article'
import { IContentBlock } from 'apps/Site/Epi/BlockMapper/BlockMapper'
import { IntroductionContentProps } from 'apps/Site/Epi/Blocks/Introduction/Introduction'
import { ArticleListItemProps } from 'apps/Site/components/ArticleList/ArticleListItem'
import { ArticleVideoBlockProps } from 'apps/Site/Epi/Blocks/ArticleVideoBlock/ArticleVideoBlock'
import { TableOfContentLink } from 'state/PublicWebApi/Content'

interface IGuideProps {
  blocks?: IContentBlock[]
  editor?: string
  introduction?: IntroductionContentProps
  tableOfContents?: TableOfContentLink[]
  relatedContent?: ArticleListItemProps[]
  customCategory?: string
  image?: string
  videoArea?: ArticleVideoBlockProps[]
  isPublic: boolean
}

const Guide = (props: IGuideProps) => {
  return (
    <div className="Guide">
      <Article {...props} />
    </div>
  )
}

export default Guide
