import BlockMapper from 'apps/Site/Epi/BlockMapper'
import { IContentBlock } from 'apps/Site/Epi/BlockMapper/BlockMapper'
import { Box, Typography } from '@mui/material'
import { replaceVariablesInText } from 'utils/helpers'
import { useCachedUserProfileState } from 'state/PublicWebApi/UserProfile'

export interface DashboardPageProps {
  heroAreaPanel: {
    properties: {
      heading: string
      preamble: string
    }
  }[]
  highlightArea: IContentBlock[]
  primaryArea: IContentBlock[]
  secondaryArea: IContentBlock[]
}

const Dashboard = ({
  heroAreaPanel = [],
  highlightArea,
  primaryArea,
  secondaryArea,
}: DashboardPageProps) => {
  const { firstName, preferredFirstName } = useCachedUserProfileState()
  const name = preferredFirstName || firstName
  return (
    <>
      {/* heroAreaPanel can never hold more or less than one block, it is only an array because its a content area in epi. This to be able to serve different content between roles */}
      {heroAreaPanel?.length > 0 && (
        <Box mb={{ xs: 4, md: 6 }}>
          <Typography variant="h2" component="h1" mb={1}>
            <Box component="span" sx={{ textTransform: 'capitalize' }}>
              {replaceVariablesInText(heroAreaPanel[0].properties?.heading, {
                name: name.toLocaleLowerCase(),
              })}
            </Box>
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {heroAreaPanel[0].properties?.preamble}
          </Typography>
        </Box>
      )}
      <Box
        display={{ xs: 'block', md: 'grid' }}
        gridTemplateAreas={{
          md: `"highlighted highlighted primary"
        "secondary secondary primary"
        "secondary secondary primary"`,
        }}
        gridTemplateColumns={{
          md: '1fr 1fr 1.33fr',
        }}
        gridTemplateRows={{ md: 'auto 1fr' }}
        columnGap={{ md: '40px' }}
      >
        <Box
          display="flex"
          flexDirection={'column'}
          gridArea={{ md: 'highlighted' }}
        >
          {highlightArea?.length > 0 &&
            BlockMapper({
              blocks: highlightArea,
            })}
        </Box>
        <Box
          display="flex"
          flexDirection={'column'}
          gridArea={{ md: 'primary' }}
        >
          {primaryArea?.length > 0 &&
            BlockMapper({
              blocks: primaryArea,
            })}
        </Box>
        <Box
          display="flex"
          flexDirection={'column'}
          gridArea={{ md: 'secondary' }}
        >
          {secondaryArea?.length > 0 &&
            BlockMapper({
              blocks: secondaryArea,
            })}
        </Box>
      </Box>
    </>
  )
}

export default Dashboard
