/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createElement } from 'react'

import { useAuthentication } from 'features/Authentication/Hooks'

import BlockMap from './BlockMap'

export interface IBlockMapper {
  blocks: IContentBlock[]
}

export interface IContentBlock {
  id: number
  type: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties: any
}

const BlockMapper = ({ blocks = [] }: IBlockMapper) => {
  const authState = useAuthentication()

  return (
    blocks.length > 0 &&
    blocks.map(
      ({ type, properties, id }, index) =>
        !!BlockMap[type] &&
        createElement(BlockMap[type], {
          id,
          content: {
            ...properties,
            mediaUrl: window.APP_CONFIGURATION.COMMON.MEDIA_URL,
            isLoggedIn: authState.isLoggedIn,
          },
          key: `${type}${index}`,
        })
    )
  )
}

export default BlockMapper
