import { HubConnection, HubConnectionState } from '@microsoft/signalr'
import { useEffect, useMemo } from 'react'
import { getConnection, getHubTypeFromRole, hubRoles } from './Helpers'
import { useSliceStateSelector } from 'slices/hooks'

type connectionReturnType =
  | {
      [key in hubRoles]?: HubConnection
    }
  | null

let signalRConnectionCache: connectionReturnType = null

export const useGetSignalRConnection = () => signalRConnectionCache

export const useSetupSignalRConnection = () => {
  const accessToken = useSliceStateSelector(
    (state) => state.slices.auth.accessToken
  )
  const userRoles = useSliceStateSelector(
    (state) => state.slices.auth.profile.userRoles
  )

  const hubType = getHubTypeFromRole(userRoles)

  const connection: connectionReturnType = useMemo(() => {
    if (hubType && accessToken) {
      signalRConnectionCache = {
        [hubType]: getConnection(hubType, {
          accessTokenFactory: () => accessToken,
        }),
      }
      return signalRConnectionCache
    }
    return null
  }, [hubType, accessToken])

  useEffect(() => {
    if (connection) {
      if (connection[hubType].state === HubConnectionState.Disconnected) {
        void connection[hubType].start()
      }
      return () => {
        if (connection[hubType].state === HubConnectionState.Connected) {
          void connection[hubType].stop()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection])
}
