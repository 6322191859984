import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Device, deviceSizes } from './types'
import { decideDeviceSize } from 'utils/deviceSize'

const initialState: Device = {
  deviceSize: decideDeviceSize(window.innerWidth),
}

const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setDeviceSize: (state, action: PayloadAction<deviceSizes>): Device => ({
      ...state,
      deviceSize: action.payload,
    }),
  },
})

export default deviceSlice
