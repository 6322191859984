import HTMLMapper from 'apps/Site/Epi/HTMLMapper'

import { Box } from '@mui/material'

interface IEditorial {
  content: {
    mainBody: string
  }
}

const EditorialStyle = {
  maxWidth: '752px',
  'a svg': {
    display: 'none',
  },
  p: {
    margin: 0,
  },
}

const Editorial = ({ content }: IEditorial) => (
  <Box sx={EditorialStyle}>
    <HTMLMapper body={content.mainBody} />
  </Box>
)

export default Editorial
