import { Children, type PropsWithChildren } from 'react'
import { pageIsSummaryInEnglish } from 'utils/helpers'
import { RequestContactUpdate } from '../components/RequestContactUpdate'
import { useCachedContentQuery } from 'state/PublicWebApi'
import { Box } from '@mui/material'
import { useLocation } from 'react-router-dom'

const Main = ({ children }: PropsWithChildren<unknown>) => {
  const { data } = useCachedContentQuery()
  const { pathname } = useLocation()
  const isFullFunctionPage = data.contentType[1] === 'FunctionPageFull'

  const isSummaryInEnglish = pageIsSummaryInEnglish(pathname)
  return (
    <Box
      sx={{
        mx: 'auto',
        maxWidth: isFullFunctionPage ? 1900 : 1140,
      }}
      component="main"
      lang={isSummaryInEnglish ? 'en' : null}
    >
      <RequestContactUpdate />
      {Children.only(children)}
    </Box>
  )
}

export default Main
