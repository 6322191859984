import config from '../config'

const findInteractiveElement = (element: HTMLElement): HTMLElement | null => {
  const trackableElementTypes = Object.keys(config)
  if (trackableElementTypes.includes(element.tagName)) return element

  return element?.closest(trackableElementTypes.join(', '))
}

export default findInteractiveElement
