import { store } from 'store'

import { getUserGroup } from './getUserGroup'
import { getWashedUserRoles } from './getWashedUserRoles'
import { removeTrailingSlash } from './removeTrailingSlash'
import { getWashedQueryString } from './getWashedQueryString'
import {
  BuildEventDataLayer,
  ClickArguments,
  ViewArguments,
} from '../Types/dataLayer.types'
import washUserId from './washUserId'
import { transformFastlyErrorCode } from 'apps/Site/Epi/Utils'
import { getFilteredTrackedRoles } from './getFilteredTrackedRoles'

export const buildEvent = (args: ClickArguments | ViewArguments) => {
  const { profile, isLoggedIn } = store.getState().slices.auth
  const { visitorContextStatus } = store.getState().slices.visitorContexts
  const { userRoles, sub } = profile

  const washedRoles = getWashedUserRoles(userRoles)
  const dataLayer: BuildEventDataLayer = {
    event: `${args.action}_${args.item}`,
    event_item: args.item.toLowerCase(),
    event_action: args.action,
    event_context: {
      page: {
        domain: location.hostname,
        path:
          removeTrailingSlash(window.location.pathname) +
          getWashedQueryString(window.location.href),
        referrer: document.referrer,
      },
      mfName: undefined, // We need to set it to undefined otherwise it will be persisted from the previously loaded mf
      visitorContextStatus,
      user: {
        group: washedRoles?.length > 0 ? getUserGroup(washedRoles) : undefined,
        role:
          washedRoles?.length > 0
            ? getFilteredTrackedRoles(washedRoles)
            : undefined,
        id: washUserId(sub, userRoles),
      },
    },
  }
  if (args.action === 'view') {
    if (args.contentError) {
      dataLayer.event_data = [
        {
          key: 'contentError',
          value: transformFastlyErrorCode(args.contentError, isLoggedIn),
        },
      ]
    }
  }
  if (args.action === 'click') {
    dataLayer.event_context.mfName = args.mfName
    dataLayer.event_data = [
      {
        key: 'label',
        value: args.assembledData.label,
      },
      {
        key: 'href',
        value: args.assembledData.href,
      },
    ]
  }

  return dataLayer
}
