import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import userManager, {
  setMedarbetareUserManager,
} from 'features/Authentication/userManager'
import {
  medarbetareCallbackUrl,
  medarbetarePathname,
} from 'features/Authentication/helpers'

const forceLoginMedarbetare = async () => {
  const user = await userManager.getUser()
  if (!user || user.expired) {
    await userManager.signinRedirect({
      state: {
        redirectUrl: `${window.location.pathname}${window.location.search}${window.location.hash}`,
      },
    })
  }
}

const useForceMedarbetareLogin = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    if (
      pathname.startsWith(medarbetarePathname) &&
      pathname !== medarbetareCallbackUrl
    ) {
      setMedarbetareUserManager()
      void forceLoginMedarbetare()
    }
  }, [pathname])
}

export default useForceMedarbetareLogin
