import { styled, Typography } from '@mui/material'

export interface HeadingAndPreambleBlockContent {
  heading: string
  preamble: string
}

export interface HeadingAndPreambleBlockProps {
  content: HeadingAndPreambleBlockContent
}

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  overflowWrap: 'break-word',
  hyphens: 'auto',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    fontSize: '3rem',
  },
}))

const Preamble = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.375rem',
  },
}))

const HeadingAndPreambleBlockSection = styled('section')(({ theme }) => ({
  marginBottom: theme.spacing(10),
  maxWidth: '752px',
}))

const HeadingAndPreambleBlock = ({
  content: { heading, preamble },
}: HeadingAndPreambleBlockProps) => {
  return (
    <HeadingAndPreambleBlockSection>
      {heading && (
        <Heading variant="h1" mb={2}>
          {heading}
        </Heading>
      )}
      {preamble && <Preamble variant="preamble">{preamble}</Preamble>}
    </HeadingAndPreambleBlockSection>
  )
}

export default HeadingAndPreambleBlock
