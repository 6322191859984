import { MouseEventHandler, useCallback } from 'react'
import { Box, ButtonBase, Link, Typography, alpha, styled } from '@mui/material'
import { isNil, isEmpty } from 'ramda'
import ChevronRight from '@mui/icons-material/ChevronRight'
import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded'
import { useNavigationInformation } from 'utils/hooks'

import { useBreadcrumbs } from './useBreadcrumbs'
import { Breadcrumb } from 'state/PublicWebApi/Content'

interface StyledBackButtonProps {
  href?: string
}

const StyledBackButton = styled(ButtonBase)<StyledBackButtonProps>(
  ({ theme }) => ({
    color: theme.palette.text.primary,
    gap: '4px',
    borderRadius: '24px',
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)} ${theme.spacing(1.5)} ${theme.spacing(1)}`,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary?.main, 0.04),
      color: theme.palette.primary?.main,
    },
    '&:focus-visible': {
      backgroundColor: alpha(theme.palette.primary?.main, 0.3),
      color: theme.palette.primary?.main,
    },
  })
)

const Breadcrumbs = () => {
  const { breadcrumbs, pageName, disableBreadcrumbs, backLinkText } =
    useBreadcrumbs()
  const { fromExternal } = useNavigationInformation()

  const paths = breadcrumbs?.map(({ name, url }: Breadcrumb) => (
    <Box
      className="breadCrumbList"
      component="li"
      display="inline-flex"
      alignItems="center"
      key={name}
    >
      <ChevronRight sx={{ fontSize: 18, mt: '2px', mx: 0.5 }} />
      <Link href={url} underline="hover">
        {name}
      </Link>
    </Box>
  ))

  const current = (
    <Box
      component="li"
      display="inline-flex"
      alignItems="center"
      key={pageName}
    >
      <ChevronRight sx={{ fontSize: 18, mt: '2px', mx: 0.5 }} />
      <span aria-current="page">{pageName}</span>
    </Box>
  )

  const parentUrl =
    breadcrumbs?.length > 0 ? breadcrumbs[breadcrumbs.length - 1].url : '/'
  const searchParams = window.location.search

  const renderBreadcrumbs = () => (
    <>
      {paths}
      {paths.length > 0 && current}
    </>
  )

  const backClickHandler: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault()
      window.history.back()
    },
    []
  )

  const renderBackToPage = () => {
    const backToPage = (
      <StyledBackButton
        {...(fromExternal && { href: `${parentUrl}${searchParams}` })}
        {...(!fromExternal && { onClick: backClickHandler })}
      >
        <ArrowBackRounded />
        <Typography variant="button" textTransform={'none'}>
          {backLinkText}
        </Typography>
      </StyledBackButton>
    )

    return (
      <Box component="li" display="flex" key={backLinkText}>
        {!isNil(backLinkText) && backToPage}
      </Box>
    )
  }

  return isNil(breadcrumbs) || isEmpty(breadcrumbs) ? null : (
    <Box component="nav" data-testid="breadcrumbs">
      <Box
        paddingInlineStart={0}
        display={'flex'}
        flexWrap={'wrap'}
        fontSize={15}
        component="ol"
        ml={{ xs: 2, md: 0 }}
        my={{ xs: 2, md: 4 }}
        sx={{
          '> .breadCrumbList:first-of-type svg': { display: 'none' },
          listStyle: 'none',
        }}
        aria-label="Breadcrumb"
      >
        {disableBreadcrumbs ? renderBackToPage() : renderBreadcrumbs()}
      </Box>
    </Box>
  )
}

export default Breadcrumbs
