import { deviceSizes } from 'slices/Device/types'
import { themeOptions } from '@trr/mui-theme'

export const decideDeviceSize = (size: number): deviceSizes => {
  const breakpoints = themeOptions?.breakpoints?.values
  return {
    isMobile: size < breakpoints?.sm,
    isTablet: size < breakpoints?.lg && size >= breakpoints?.sm,
    isDesktop: size < breakpoints?.xl && size >= breakpoints?.lg,
    isXL: size >= breakpoints?.xl,
  }
}
