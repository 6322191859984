import { reduce, isNil, omit } from 'ramda'
import {
  dictionaryToObject,
  filterContentBlockAreaToObject,
} from 'utils/helpers'
import { EpiBlock, EpiBlockProperties } from '@trr/internaltypes'

type MergedBlock = {
  [x: string]: EpiBlockProperties
} & EpiBlockProperties

export const mergeBlockContent = reduce(
  (acc: MergedBlock[], block: EpiBlock) => {
    const blockWithOmittedProperties = omit(
      ['customDictionary', 'blockKey', 'customLinkDictionary'],
      block.properties
    )

    return {
      ...acc,
      [block.properties.blockKey]: {
        ...blockWithOmittedProperties,
        ...(!isNil(block.properties.customDictionary) &&
          dictionaryToObject(block.properties.customDictionary)),

        ...(!isNil(block.properties.customLinkDictionary) &&
          dictionaryToObject(block.properties.customLinkDictionary)),

        // Used to map the Simple Content Area Block from EPI
        ...(!isNil(block.properties.contentBlockArea) &&
          filterContentBlockAreaToObject(block)),
      },

      // Line 41 to 46 are depricated and will be removed
      // when all MF have implemented the new way of
      // using the content object.
      [block.type]: {
        ...blockWithOmittedProperties,
        ...(!isNil(block.properties.customDictionary) &&
          dictionaryToObject(block.properties.customDictionary)),
      },
      ...blockWithOmittedProperties,
    }
  },
  []
)

export const mergeEpiBlockContent = (epiBlocks?: EpiBlock[]) => {
  if (epiBlocks) {
    const mergedBlocks = mergeBlockContent(epiBlocks)
    if (Array.isArray(mergedBlocks)) return {}

    return mergedBlocks
  }
}
