export const getWashedQueryString = (href: string) => {
  const url = new URL(href)
  const search = new URLSearchParams()
  for (const [key, value] of url.searchParams.entries()) {
    if (key.startsWith('utm_')) {
      search.append(key, value)
    }
  }
  return `${search.toString() ? '?' : ''}${search.toString()}`
}
