import { useCallback, useEffect } from 'react'

const useLockScroll = () => {
  const enableScroll = useCallback(
    () => document.documentElement.classList.remove('lockscroll'),
    []
  )
  const disableScroll = useCallback(
    () => document.documentElement.classList.add('lockscroll'),
    []
  )

  useEffect(() => {
    return () => document.documentElement.classList.remove('lockscroll')
  }, [])

  return { enableScroll, disableScroll }
}

export default useLockScroll
