import userManager from '.'

export interface RedirectionProps {
  redirectUrl: string
  arbetsgivarinfoLinkId?: string | null
  authenticationMethod: string
}

export const userManagerRedirectToAuthServer = ({
  redirectUrl,
  arbetsgivarinfoLinkId,
  authenticationMethod,
}: RedirectionProps) => {
  void userManager
    .signinRedirect({
      state: {
        redirectUrl, // Where to we will return after logging in
        arbetsgivarinfoLinkId,
      },
      extraQueryParams: { trrLogin: authenticationMethod },
    })
    .catch(() =>
      console.error(
        'UserManager couldn`t redirect to login. Is there a problem with the session or the server?'
      )
    )
}
