import { authSlice } from 'slices/Auth'
import { cookiesSlice } from 'slices/Cookies'
import { deviceSlice } from 'slices/Device'
import { menuSlice } from 'slices/Menus'
import { useDispatch } from 'react-redux'
import { visitorContextSlice } from 'slices/VisitorContext'

const SLICE_ACTIONS = {
  ...menuSlice.actions,
  ...deviceSlice.actions,
  ...cookiesSlice.actions,
  ...authSlice.actions,
  ...visitorContextSlice.actions,
} as const

const useSliceDispatch = () => {
  const dispatch = useDispatch()
  return <
    T extends keyof typeof SLICE_ACTIONS,
    K extends (typeof SLICE_ACTIONS)[T],
  >(
    type: T,
    payload: Parameters<K>[0]
  ) => {
    const actionFn: (payload: Parameters<K>[0]) => ReturnType<K> =
      SLICE_ACTIONS[type] as never
    dispatch(actionFn(payload))
  }
}

export default useSliceDispatch
