import { handledHttpErrors } from 'PublicWebApi/Settings'

/**
 * Transforms the Fastly error code based on the provided content error and login status.
 * If the content error is '403' and the user is not logged in, it returns '401'.
 * Otherwise, it returns the original content error.
 *
 * @param contentError The content error code.
 * @param isLoggedIn Indicates whether the user is logged in or not.
 * @returns The transformed error code.
 */
const transformFastlyErrorCode = (
  contentError: handledHttpErrors,
  isLoggedIn: boolean
) => {
  return contentError === '403' && !isLoggedIn ? '401' : contentError
}

export default transformFastlyErrorCode
