export type isEnforcedToSetContactInformation = {
  isEnforcedToSetContactInformation: boolean
} & Record<string, unknown>

export const goToEnforcedMissingContactInformation = (
  url: string,
  push: (path: string, state?: isEnforcedToSetContactInformation) => void,
  state: isEnforcedToSetContactInformation
): void => {
  push(url, {
    ...state,
    isEnforcedToSetContactInformation: true,
  })
}
