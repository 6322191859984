import { useEffect } from 'react'
import userManager from 'features/Authentication/userManager'

const useOnlineListener = (): boolean => {
  useEffect(() => {
    const onlineHandler = () => {
      userManager.startSilentRenew()
    }
    const offlineHandler = () => {
      userManager.stopSilentRenew()
    }
    window.addEventListener('online', onlineHandler)
    window.addEventListener('offline', offlineHandler)
    return () => {
      window.removeEventListener('online', onlineHandler)
      window.removeEventListener('offline', offlineHandler)
    }
  }, [])

  return false
}

export default useOnlineListener
