import { Box, styled } from '@mui/material'

export const DetailsBarWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(6),
  width: '100%',
}))

export const TopContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  color: theme.palette.text.primary,
  justifyContent: 'space-between',
  minHeight: theme.spacing(4),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(3),
  paddingTop: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    paddingLeft: 0,
  },
}))

export const Bar = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.neutral?.divider,
  display: 'flex',
  borderWidth: 0,
  height: '1px',
  width: '100%',
  marginTop: 0,
  marginBottom: 0,
}))

export const BottomContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  color: theme.palette.neutral?.light,
  paddingLeft: theme.spacing(3),
  marginTop: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    paddingLeft: 0,
  },
}))

export const ScreenReaderOnlyText = styled('h2')(() => ({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: '1px',
}))
