import userManager, { IdServerUser } from 'features/Authentication/userManager'
import { useEventListener } from 'utils/hooks'
import { useSliceDispatch } from 'slices/hooks'
import { resetAuthOnExpiry } from 'features/Authentication/helpers'

const onStorageChange = (
  setAuthState: (user: IdServerUser) => void,
  event: StorageEvent
): void => {
  const onStorageChangeOutcome = async () => {
    const user = await userManager.getUser()
    const { key, newValue } = event
    const isAuthEvent = key?.startsWith('oidc.user:') ?? false
    const isLoggedOutInOtherTab = newValue === null
    if (user?.expired === false && isAuthEvent) {
      setAuthState(user)
      return
    }
    if ((isAuthEvent && isLoggedOutInOtherTab) || user?.expired === true) {
      void resetAuthOnExpiry()
    }
  }

  void onStorageChangeOutcome()
}

const useLoginOrLogoutAllOpenTabs = () => {
  const trigger = useSliceDispatch()
  const setAuthState = (user: IdServerUser) => trigger('setAuthState', user)
  useEventListener('storage', (event: StorageEvent) =>
    onStorageChange(setAuthState, event)
  )
}

export default useLoginOrLogoutAllOpenTabs
