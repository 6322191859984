import { PropsWithChildren } from 'react'
import classnames from 'classnames'

import s from './DropdownMenu.module.scss'

export const DropdownListItem = ({
  children,
  className,
  ...props
}: PropsWithChildren<Partial<JSX.IntrinsicElements['li']>>) => (
  <li
    {...props}
    className={classnames(s.DropdownListItem, className)}
    data-testid="dropdownListItem"
  >
    {children}
  </li>
)
