import { AppshellLink } from '@trr/internal-helpers'
import { Link, List, styled } from '@mui/material'

export const SectionHeading = styled(Link)(({ theme }) => ({
  ...theme.typography.h6,
  color: theme.palette.primary.main,
  border: 'solid',
  borderRadius: '2px',
  borderColor: 'transparent',
  borderWidth: '1px',

  '&:hover, &:active': {
    textDecoration: 'underline',
  },

  '&:focus': {
    outline: 'none',
    borderColor: theme.palette.primary?.main,

    '&:not(:focus-visible)': {
      borderColor: 'transparent',
    },
  },
}))

export const SectionLink = styled(AppshellLink)(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.text.primary,
  border: 'solid',
  borderRadius: '2px',
  borderColor: 'transparent',
  borderWidth: '1px',

  '&:hover, &:active': {
    textDecoration: 'underline',
  },

  '&:focus': {
    outline: 'none',
    borderColor: theme.palette.primary?.main,

    '&:not(:focus-visible)': {
      borderColor: 'transparent',
    },
  },
}))

export const SectionLinksWrapper = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 0,
  paddingBottom: 0,
  gap: theme.spacing(1),
}))
