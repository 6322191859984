import { styled, Typography } from '@mui/material'
import { StyledBox } from '../StyledBox'

const TextWrapper = styled('p')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}))

export interface TextStatisticsBoxProps {
  beforeText: string
  highlightText: string
  afterText: string
  color?: string
}

export const TextStatisticsBox = ({
  beforeText,
  highlightText,
  afterText,
  color,
}: TextStatisticsBoxProps) => (
  <StyledBox color={color}>
    <TextWrapper>
      <Typography component="span" variant="subtitle1">
        {beforeText}
      </Typography>
      <Typography component="span" variant="h2">
        {highlightText}
      </Typography>
      <Typography component="span" variant="subtitle1">
        {afterText}
      </Typography>
    </TextWrapper>
  </StyledBox>
)
