import { Box, IconButton, Paper, Typography } from '@mui/material'
import WarningIcon from '@mui/icons-material/WarningRounded'
import InfoIcon from '@mui/icons-material/Info'
import ErrorIcon from '@mui/icons-material/Error'
import CloseIcon from '@mui/icons-material/Close'
import { useCloseDashboardInformation } from './hooks'

const DASHBOARD_INFORMATION_STATUS_PROPS = {
  critical: {
    color: 'surface.pink',
    icon: () => <ErrorIcon color="error" />,
  },
  warning: {
    color: 'surface.orange',
    icon: () => <WarningIcon color="warning" />,
  },
  info: { color: 'surface.blue', icon: () => <InfoIcon color="info" /> },
}

export interface DashboardInformationBlockProps {
  id: number
  content: {
    heading: string
    ingress: string
    status: keyof typeof DASHBOARD_INFORMATION_STATUS_PROPS
    notClosable: boolean
  }
}

const DashboardInformationBlock = ({
  id,
  content: { heading, ingress, status, notClosable },
}: DashboardInformationBlockProps) => {
  const statusProps = DASHBOARD_INFORMATION_STATUS_PROPS[status]
  const StatusIcon = statusProps.icon
  const { closed, pushClose } = useCloseDashboardInformation(id)
  if (closed) {
    return null
  }
  return (
    <Paper
      className="dashboardInformationBlock"
      elevation={0}
      sx={{
        mb: 3,
        backgroundColor: statusProps.color,
        display: 'flex',
        justifyContent: 'space-between',
        p: 2,
        pt: 1.5,
        pr: 1,
      }}
    >
      <StatusIcon />
      <Box flex={1} mx={1.5}>
        <Typography variant="subtitle1" component={'h2'} mb={0.5}>
          {heading}
        </Typography>
        <Typography variant="body2">{ingress}</Typography>
      </Box>
      {!notClosable && (
        <IconButton
          onClick={() => pushClose(id)}
          size="small"
          sx={{ height: 'fit-content', bottom: '4px' }}
          aria-label="stäng"
        >
          <CloseIcon />
        </IconButton>
      )}
    </Paper>
  )
}

export default DashboardInformationBlock
