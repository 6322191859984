import { Link, type LinkBaseProps } from '@mui/material'
import Launch from '@mui/icons-material/Launch'

import { isLinkExternal } from '../../imageHelpers'

export interface AppshellLinkProps extends LinkBaseProps {
  url: string
  text: string
  fontSize?: number
  fontWeight?: number
  showIcon?: boolean
}

const AppshellLink = ({
  url,
  text,
  fontSize = 16,
  fontWeight = 400,
  showIcon = true,
  ...rest
}: AppshellLinkProps): React.JSX.Element => {
  const externalLink = url ? isLinkExternal(url) : false
  const ariaLabel = externalLink ? `${text} (öppnas i ny flik)` : text

  const shouldShowIcon = showIcon && externalLink
  return (
    <>
      {url && text && (
        <Link
          target={externalLink ? '__blank' : undefined}
          rel={externalLink ? 'noreferrer' : undefined}
          href={url}
          fontSize={fontSize}
          fontWeight={fontWeight}
          display="flex"
          alignItems="center"
          columnGap="8px"
          aria-label={ariaLabel}
          data-testid="link"
          underline="hover"
          sx={{ hyphens: 'auto' }}
          {...(externalLink && { component: 'a' })}
          {...rest}
        >
          {shouldShowIcon && <Launch fontSize={'small'} />}
          {text}
        </Link>
      )}
    </>
  )
}

export default AppshellLink
