/* eslint-disable @typescript-eslint/unbound-method */
import { useState, useEffect, useRef } from 'react'
import { Box, Tab, Tabs, styled } from '@mui/material'
import { useHistory } from 'react-router-dom'

import { getPageName } from 'utils/helpers'
import MicroFrontendLoader from 'features/SystemLoaders/MicroFrontendLoader'

import FunctionPageWrapper from './FunctionPageWrapper'
import { EpiBlock } from '@trr/internaltypes'
import { FunctionPageProperties } from 'state/PublicWebApi/Content'
import { useReplaceWithoutDocumentReferrer } from 'features/Telemetry/GoogleTagManager/Hooks/useOverrideDocumentReferrer'

const TabsWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

const FunctionWithTabs = (props: FunctionPageProperties) => {
  const {
    push,
    location: { pathname, search: queryString },
    listen,
  } = useHistory()

  const { blockedReferrerReplace } = useReplaceWithoutDocumentReferrer()

  const didMount = useRef(false)
  const mfHeading = props.heading
  const { location } = useHistory()

  const [currentBlockKey, setCurrentBlockKey] = useState('')
  const [panelIndex, setPanelIndex] = useState(0)

  const setPageTitle = (heading: string, block: EpiBlock) => {
    document.title = getPageName(
      heading,
      block.properties.heading ?? block.properties.name
    )
  }

  useEffect(() => {
    didMount.current = false
  }, [props.urlKey])

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true
      const pathnameToArray = pathname.split('/')
      const lastIndexInPathname: string = pathnameToArray.pop()

      const blockIndex = props?.blocks?.findIndex(
        (block) =>
          block?.properties?.urlKey.toLowerCase() ===
          lastIndexInPathname.toLowerCase()
      )
      const block = props?.blocks[blockIndex]
      const blockKey = block?.properties?.urlKey

      if (blockKey && blockIndex) {
        // Render initial tab if url points to tab and not parent
        setCurrentBlockKey(blockKey)
        setPanelIndex(blockIndex)
        setPageTitle(mfHeading, block)
      } else {
        // Otherwise redirect to first tab
        const urlKey = props?.blocks[0].properties.urlKey
        setCurrentBlockKey(urlKey)
        blockedReferrerReplace(props.url + urlKey + queryString)
      }
    }
  }, [
    didMount,
    mfHeading,
    pathname,
    props,
    push,
    queryString,
    blockedReferrerReplace,
    setCurrentBlockKey,
  ])

  useEffect(() => {
    const unlisten = listen((location) => {
      const keys = props.blocks.map((block) => block.properties.urlKey)
      const pathnameToArray = location.pathname.split('/')
      const lastIndexInPathname: string = pathnameToArray.pop()
      const pathMatch = keys.find(
        (key) => key.toLowerCase() === lastIndexInPathname.toLowerCase()
      )

      if (pathMatch) {
        const tabIndex = keys.indexOf(pathMatch)
        setPanelIndex(tabIndex)
        setCurrentBlockKey(pathMatch)
        setPageTitle(mfHeading, props.blocks[tabIndex])
      }
    })

    return () => {
      unlisten()
    }
  }, [props.blocks, listen, mfHeading])

  const handleTabClick = (event: React.SyntheticEvent, tabIndex: number) => {
    const chosenBlock = props.blocks[tabIndex]

    if (chosenBlock) {
      const urlKey = chosenBlock.properties.urlKey
      setPanelIndex(tabIndex)
      setCurrentBlockKey(urlKey)
      const newUrl = props.url + urlKey
      const currUrl = location.pathname + location.search
      if (currUrl !== newUrl) {
        push(newUrl)
      }
    }
  }

  return (
    <FunctionPageWrapper {...props}>
      <TabsWrapper>
        <Tabs
          value={panelIndex}
          onChange={handleTabClick}
          variant="scrollable"
          allowScrollButtonsMobile
          aria-label={`${props.heading} tab navigation`}
        >
          {props.blocks.map(({ properties: { urlKey, name } }, i) => (
            <Tab
              value={i}
              label={name}
              key={`tab-key-${urlKey}`}
              id={`function-tab-${i}`}
              aria-controls={`function-tabpanel-${i}`}
            />
          ))}
        </Tabs>
      </TabsWrapper>
      <Box
        aria-labelledby={`function-tab-${panelIndex}`}
        role="tabpanel"
        id={`function-tabpanel-${panelIndex}`}
      >
        <MicroFrontendLoader
          currentKey={currentBlockKey}
          functionType={props.functionType}
        />
      </Box>
    </FunctionPageWrapper>
  )
}

export default FunctionWithTabs
