/* eslint-disable @typescript-eslint/restrict-template-expressions */
export const getImageQuery = (imageUrl: string, width: number): string => {
  const searchParams = new URLSearchParams({
    width: width.toString(),
    dpr: window.devicePixelRatio.toString(),
  })

  return `${imageUrl}?${searchParams.toString()}`
}

export const isLinkExternal = (href: string): boolean =>
  href.startsWith('http') ||
  href.startsWith('mailto:') ||
  href.startsWith('tel:')

const getFullImageUrlGenerator =
  (mediaUrl: string | undefined, imagePath: string) =>
  (width: number): string =>
    `${mediaUrl ?? ''}${imagePath}?width=${width}`

export const getFullImageUrl = (
  mediaUrl: string | undefined,
  imagePath: string,
  width: number
): string => getFullImageUrlGenerator(mediaUrl, imagePath)(width)

const defaultBreakpoints = [375, 576, 768, 1024, 1200, 1366, 1920, 2304, 3072]

export const getStandardSrcset = (
  mediaUrl: string | undefined,
  imagePath: string,
  customBreakpoints: number[] = []
): string => {
  const getUrl = getFullImageUrlGenerator(mediaUrl, imagePath)
  const breakpoints = [...defaultBreakpoints, ...customBreakpoints].sort(
    (a, b) => a - b
  )
  return breakpoints
    .map((breakpoint) => `${getUrl(breakpoint)} ${breakpoint}w`)
    .join(', ')
}

export const createImageAltTextFromFileName = (image: string): string => {
  const regexMatchSizeDescriptor = /\d{1,4}[xX]\d{1,4}/

  if (typeof image !== 'string') {
    return ''
  }
  return (
    image
      .split('/')
      .pop()
      ?.split('.')
      .shift()
      ?.replaceAll('-', ' ')
      .replaceAll('_', ' ')
      .replace(regexMatchSizeDescriptor, '')
      .trim() ?? ''
  )
}
