const port = window.location.port ? `:${window.location.port}` : ''
const currentWindow = `${window.location.protocol}//${window.location.hostname}${port}`

export const getUserManagerSilentRedirectUri = (): string => {
  const azStorageFilePath = '/public-web/'
  const localFilePath = '/'
  const filePath = location.origin.includes('trr')
    ? azStorageFilePath
    : localFilePath
  return `${currentWindow}${filePath}silent_renew.html`
}

export const getUserManagerRedirectUri = (callbackPRPrefix = ''): string => {
  return `${currentWindow}${callbackPRPrefix}/callback`
}
