import { publicWebApi } from 'state/PublicWebApi'
import { UserProfileResponse, TransformedUserProfileResponse } from './types'
import { getProfileImages } from 'utils/helpers'

const checkIfKundAndReturnPrimaryUserProfile = (
  userProfiles: UserProfileResponse
) =>
  userProfiles.find((profile) => {
    if (
      'kundProperties' in profile &&
      typeof profile.kundProperties === 'object'
    ) {
      return profile.kundProperties.primary
    }
  })

const userProfileApi = publicWebApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserProfile: builder.query<TransformedUserProfileResponse, void>({
      providesTags: ['UserProfile'],
      query: () => `/user`,
      transformResponse: (res: UserProfileResponse) => {
        const primaryUserProfileOrDefault =
          checkIfKundAndReturnPrimaryUserProfile(res) ?? res[0]
        return {
          ...primaryUserProfileOrDefault,
          profileImages: getProfileImages(
            primaryUserProfileOrDefault.imageFileName
          ),
        }
      },
    }),
    confirmUserProfileContactInformation: builder.query<void, void>({
      query: () => ({ url: `/user/contact/confirmed`, method: 'PUT' }),
    }),
    setUserProfileCompanyAsPrimary: builder.query<void, string>({
      query: (mdid) => ({ url: `/user/kund/primary/${mdid}`, method: 'PUT' }),
    }),
  }),
})

/**
 * A hook that returns the last cached user state.
 * Normally should to be ran in a component that is a child of ```<PrepareAppShell />```
 *
 * Will return {} if ran outside of ```<PrepareAppShell />``` or user is anonymous
 *
 * Note that we are using the *as* keyword here instead of making unintuitive
 * checks wether the hook is called as a child of its specified elements or not.
 *
 * @returns
 *  ```
 * TransformedUserProfileResponse | {}
 * ```
 */
export const useCachedUserProfileState = () => {
  const anonymousData = {} as TransformedUserProfileResponse
  return (
    userProfileApi.endpoints.getUserProfile.useQueryState().data ??
    anonymousData
  )
}

export default userProfileApi
