// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { themeOptions } from '@trr/mui-theme'

import { goToLogin } from '@trr/app-shell-communication'
import { Box, Paper, Typography, styled } from '@mui/material'
import ChevronRight from '@mui/icons-material/ChevronRight'
import LockOutlined from '@mui/icons-material/LockOutlined'
import React from 'react'

interface LoginProps {
  content: LoginContent
}

interface LoginContent {
  description?: string
  isLoggedIn: boolean | undefined
}

const ButtonBox = styled(Paper)(({ theme }) => ({
  display: 'flex',
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  backgroundColor: theme.palette?.surface?.green,
  color: theme.palette.text.primary,
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  marginBottom: theme.spacing(5),
  '&:hover': {
    backgroundColor: theme.palette.success.light,
  },
}))

const LoginBlock = ({
  content: { description, isLoggedIn },
}: LoginProps): React.JSX.Element | null => {
  const redirectToLogin = (): void => {
    goToLogin(`${window.location.pathname}/${window.location.search}`) // This one does the actual redirection to loginMF in new concept
  }
  if (isLoggedIn) {
    return null
  }
  return (
    <ButtonBox
      elevation={0}
      onClick={redirectToLogin}
      role="button"
      tabIndex={0}
      data-testid="loginblock"
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        <LockOutlined color="success" style={{ marginRight: '24px' }} />
        <Box display="flex" flexWrap="wrap" alignItems={'center'}>
          <Typography
            variant="body1"
            fontWeight="bold"
            style={{
              marginBottom: 0,
              marginRight: '8px',
            }}
          >
            Logga in
          </Typography>
          <Typography variant="body2" style={{ margin: 0 }}>
            {description}
          </Typography>
        </Box>
      </Box>
      <ChevronRight
        color="success"
        fontSize="medium"
        style={{ marginLeft: '24px' }}
      />
    </ButtonBox>
  )
}

export default LoginBlock
