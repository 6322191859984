import { useCachedSettingsState } from 'state/PublicWebApi'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

const compareStringsByLengthDescending = (a: string, b: string) =>
  b.length - a.length
const pathStartsWith = (path: string) => (toCompareWith: string) =>
  path.startsWith(toCompareWith)
const addTrailingSlash = (path: string) =>
  `${path}${path.endsWith('/') ? '' : '/'}`

export const matchPathWithFallback = (path: string, paths: string[]) => {
  const normalizedPathname = addTrailingSlash(path) // add trailing slash if needed
  const startsWithPathname = pathStartsWith(normalizedPathname) // generate comparer function

  const foundPath = paths
    .filter(startsWithPathname) // find paths including pathname
    .sort(compareStringsByLengthDescending)[0] // sort matches by length descending and pick first element which should be best match

  // if no match is found fallback to the normalized pathname
  const result = foundPath ?? normalizedPathname
  // remove PR prefix
  return result
}

const useContentUrl = () => {
  const { pathname } = useLocation()
  const pagesWithOwnRouter =
    useCachedSettingsState()?.pageSettings?.reservedRoutes

  const contentUrl = useMemo(
    () => matchPathWithFallback(pathname, pagesWithOwnRouter ?? []),
    [pathname, pagesWithOwnRouter]
  )

  return contentUrl
}

export default useContentUrl
