import { DataLayer } from 'features/Telemetry/GoogleTagManager/Types'

import * as buttonConfig from './elements/button'
import * as linkConfig from './elements/link'

interface TrackingConfig {
  [key: string]: {
    isTrackable: (element: HTMLElement) => boolean
    extractEventData: (element: HTMLElement) => DataLayer
    assembleDataEntries: (element: HTMLElement) => Record<string, unknown>
    item: string
  }
}

const config: TrackingConfig = {
  A: linkConfig,
  BUTTON: buttonConfig,
}

export default config
