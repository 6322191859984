import { ButtonBase, List, ListItem, alpha, styled } from '@mui/material'
import { useCallback } from 'react'
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded'
import { MegaNavigationContext } from 'PublicWebApi/Settings/types'
import { useSliceStateSelector } from 'slices/hooks'
import { visitorContext } from 'slices/VisitorContext/types'

interface ContextLinksProps {
  contexts: MegaNavigationContext[]
  onClick: () => void
}

interface ContextLinkProps {
  isContextActive?: boolean
  href?: string
}

const StyledContextLink = styled(ButtonBase, {
  shouldForwardProp: (prop: string) => !['isContextActive'].includes(prop),
})<ContextLinkProps>(({ theme, isContextActive }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.primary?.main,
  ...(isContextActive && {
    color: theme.palette.text.disabled,
  }),
  width: '100%',
  justifyContent: 'space-between',
  display: 'flex',
  padding: theme.spacing(1.5, 1),

  '&:focus-visible': {
    color: theme.palette.text?.primary,
    backgroundColor: alpha(theme.palette.primary?.main, 0.3),
    outline: 'none',
    svg: {
      color: theme.palette.primary?.main,
    },
  },

  '&:hover': {
    color: theme.palette.primary?.main,
    backgroundColor: theme.palette.surface?.purple,
    svg: {
      color: theme.palette.primary?.main,
    },
  },
}))

const ContextLinks = ({ contexts, onClick }: ContextLinksProps) => {
  const { visitorContextStatus } = useSliceStateSelector(
    (state) => state.slices.visitorContexts
  )

  const isContextActive = useCallback(
    (contextTitle: string) => {
      return visitorContextStatus === (contextTitle as visitorContext)
    },
    [visitorContextStatus]
  )

  return (
    <List
      sx={{
        paddingRight: 2,
        paddingTop: 2,
      }}
    >
      {contexts.map((context: MegaNavigationContext, index) => (
        <ListItem key={`listitem-${index.toString()}`}>
          {isContextActive(context.role) ? (
            <StyledContextLink
              isContextActive
              data-testid={`mobile-active-contextlink-${context.title}`}
              disabled
            >
              {context.title}
            </StyledContextLink>
          ) : (
            <StyledContextLink
              href={context.url}
              onClick={() => onClick()}
              data-testid={`mobile-contextlink-${context.title}`}
            >
              {context.title}
              <NavigateNextRoundedIcon sx={{ color: 'text.secondary' }} />
            </StyledContextLink>
          )}
        </ListItem>
      ))}
    </List>
  )
}

export default ContextLinks
