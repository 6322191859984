import { alpha, Link, styled } from '@mui/material'

interface TableOfContentLinkStyledProps {
  isInView: boolean
}

export const TableOfContentLinkStyled = styled(
  Link
)<TableOfContentLinkStyledProps>(({ theme, isInView }) => ({
  display: 'flex',
  scrollBehavior: 'smooth',
  color: theme.palette.text.primary,
  borderRadius: '48px',
  padding: theme.spacing(1, 2),
  ...theme.typography.body1,
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(1.5, 1.5, 1.5, 2),
    ...(isInView && {
      borderRadius: '48px',
      backgroundColor: theme.palette.surface?.purple,
      color: theme.palette.primary?.main,
    }),
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary?.light, 0.3),
  },
  '&:active': {
    backgroundColor: theme.palette.surface?.purple,
    color: theme.palette.primary?.main,
  },
  '&:focus': {
    backgroundColor: theme.palette.primary?.light,
    color: theme.palette.primary?.main,
    outline: 'none',
  },
}))
