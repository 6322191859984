import { Avatar, Box, Typography } from '@mui/material'
import { FontIcon } from '@trr/internal-helpers'

interface FeaturePuffProps {
  heading: string
  body: string
  isVerticalList?: boolean
  variant: 'icon' | 'enumerated' | 'text'
}

export interface EnumeratedFeaturePuffProps extends FeaturePuffProps {
  variant: 'enumerated'
  index: number
}
export interface IconFeaturePuffProps extends FeaturePuffProps {
  variant: 'icon'
  iconName: string
}

export interface TextFeaturePuffProps extends FeaturePuffProps {
  variant: 'text'
}

const FeaturePuff = ({
  heading,
  body,
  isVerticalList,
  ...props
}:
  | EnumeratedFeaturePuffProps
  | IconFeaturePuffProps
  | TextFeaturePuffProps) => {
  const isEnumerated = props.variant === 'enumerated'
  const isTextList = props.variant === 'text'

  return (
    <Box
      data-testid={isVerticalList ? 'vertical-feature-puff' : 'feature-puff'}
      display="flex"
      flexDirection={isVerticalList ? 'row' : 'column'}
      gap={isVerticalList && 3}
    >
      {!isTextList && (
        <Avatar
          sx={{
            marginBottom: 3,
            backgroundColor: 'surface.green',
            height: '48px',
            width: '48px',
            color: 'secondary.main',
          }}
        >
          {isEnumerated ? (
            props.index.toString()
          ) : (
            <FontIcon iconName={props.iconName} color="secondary" />
          )}
        </Avatar>
      )}
      <Box>
        <Typography mb={1} variant="h6" component="h3">
          {heading}
        </Typography>
        <Typography variant="body1">{body}</Typography>
      </Box>
    </Box>
  )
}

export default FeaturePuff
