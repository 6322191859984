import { useCachedContentQuery } from 'state/PublicWebApi'

export const useBreadcrumbs = () => {
  const { data: contentData } = useCachedContentQuery()
  return {
    breadcrumbs: contentData.properties.breadcrumbs,
    pageName: contentData.properties.name,
    backLinkText: contentData.properties.breadcrumbSettings?.backLinkText,
    disableBreadcrumbs:
      contentData.properties.breadcrumbSettings?.disableBreadcrumbs,
  }
}
