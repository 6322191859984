import { PropsWithChildren } from 'react'
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material'
import {
  getFullImageUrl,
  getStandardSrcset,
  isLinkFile,
} from '@trr/internal-helpers'

const ConditionalCardActionArea = ({
  link,
  showActionArea,
  children,
}: PropsWithChildren<{ link: string; showActionArea: boolean }>) =>
  showActionArea ? (
    <CardActionArea data-testid="conditionalCardActionArea" href={link}>
      {children}
    </CardActionArea>
  ) : (
    <>{children}</>
  )

export interface DashboardContentBlockProps {
  content: {
    heading: string
    ingress: string
    linkText?: string
    linkUrl?: string
    imageUrl?: string
    imageAlt?: string
  }
}

const DashboardContentBlock = ({
  content: {
    heading,
    ingress,
    linkText = undefined,
    linkUrl = undefined,
    imageUrl = undefined,
    imageAlt = undefined,
  },
}: DashboardContentBlockProps) => {
  const { MEDIA_URL } = window.APP_CONFIGURATION.COMMON
  const hasLinkData = !!linkUrl && !!linkText

  return (
    <Box mb={3}>
      <Card>
        <ConditionalCardActionArea
          link={isLinkFile(linkUrl) ? `${MEDIA_URL}${linkUrl}` : linkUrl}
          showActionArea={hasLinkData}
        >
          {imageUrl && (
            <CardMedia
              component="img"
              sx={{ height: { xs: '160px', md: '225px' } }}
              src={getFullImageUrl(MEDIA_URL, imageUrl, 768)}
              srcSet={getStandardSrcset(MEDIA_URL, imageUrl, [600])}
              sizes="(max-width: 600px) 100vw, 600px"
              alt={imageAlt}
              loading="lazy"
            />
          )}
          <CardContent>
            <Typography variant="h5" component={'h2'} mb={2}>
              {heading}
            </Typography>
            <Typography variant="body1">{ingress}</Typography>
          </CardContent>
          {hasLinkData && (
            <CardActions>
              <Button variant="text" size="small">
                {linkText}
              </Button>
            </CardActions>
          )}
        </ConditionalCardActionArea>
      </Card>
    </Box>
  )
}

export default DashboardContentBlock
