import { Box, styled } from '@mui/material'
import { getBackgroundColor } from 'apps/Site/hooks/getBackgroundColor'

interface StyledBoxProps {
  color?: string
}

export const StyledBox = styled(Box)<StyledBoxProps>(({ theme, color }) => ({
  alignItems: 'center',
  backgroundColor: getBackgroundColor(theme, color ? color : 'blue', true),
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
  padding: theme.spacing(4),
  textAlign: 'center',
  width: '100%',
  wordBreak: 'break-word',
}))
