import { Avatar, Typography } from '@mui/material'
import { FontIcon } from '@trr/internal-helpers'
import { StyledBox } from '../StyledBox'

export interface IconStatisticsBoxProps {
  iconName: string
  text: string
  color?: string
}

export const IconStatisticsBox = ({
  iconName,
  text,
  color,
}: IconStatisticsBoxProps) => (
  <StyledBox color={color}>
    <Avatar
      sx={{
        width: 64,
        height: 64,
        marginBottom: 2,
        backgroundColor: 'white',
      }}
    >
      <FontIcon iconName={iconName} size="large" customColor="text.primary" />
    </Avatar>
    <Typography variant="subtitle1" component="p">
      {text}
    </Typography>
  </StyledBox>
)
