import { store } from 'store'
import { DataLayer } from '../Types'
import { gtmLogsSlice } from 'slices/gtmLogsSlice'

const createSuccessLogMessage = (item: DataLayer) => {
  const distinguishData = {
    view: item.event_context.page.path,
    click: JSON.stringify(item.event_data),
  }

  return {
    origin: item.event_context.mfName ?? 'App-Shell',
    event: item.event,
    distinguisher: distinguishData[item.event_action as 'click' | 'view'],
    action: item.event_action,
    item: item.event_item,
  }
}

export const gtmLoggerSuccess = () => {
  const dataLayerWithoutInitialPush = (window.dataLayer as DataLayer[]).slice(1)
  const lastEntry =
    dataLayerWithoutInitialPush[dataLayerWithoutInitialPush.length - 1]
  const prettifiedLog = createSuccessLogMessage(lastEntry)
  store.dispatch({
    type: gtmLogsSlice.actions.setGtmSuccessLogs.type,
    payload: prettifiedLog,
  })
}
