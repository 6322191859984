import { useState, useCallback } from 'react'
import { isLinkExternal } from '@trr/internal-helpers'
import { useCachedSettingsState } from 'state/PublicWebApi'
import { useSliceStateSelector } from 'slices/hooks'
import { Box, Button, Typography, Link } from '@mui/material'
import Info from '@mui/icons-material/Info'
import OpenInNew from '@mui/icons-material/OpenInNew'

const SystemInformation = () => {
  const {
    isEnabled = false,
    heading = '',
    text = '',
    url = '',
    urlLabel = '',
  } = useCachedSettingsState().systemInformation

  const { smallDeviceMenuStatus } = useSliceStateSelector(
    (state) => state.slices.menus
  )
  const shownSideMenu = smallDeviceMenuStatus !== 'none'

  const [isInfoExpanded, setIsInfoExpanded] = useState(false)

  const toggleExpandedInfo = useCallback(() => {
    setIsInfoExpanded(!isInfoExpanded)
  }, [isInfoExpanded])

  const linkAriaLabel = isLinkExternal(url)
    ? `${urlLabel} (öppnas i ny flik)`
    : urlLabel

  return (
    isEnabled &&
    !shownSideMenu && (
      <Box
        p={3}
        display="flex"
        component="aside"
        bgcolor="surface.blue"
        data-testid="SystemInformation"
      >
        <Info sx={{ mt: '2px' }} color="info" />
        <Box maxWidth="752px" ml={2}>
          <Typography component={'h2'} variant="h6">
            {heading}
          </Typography>
          {isInfoExpanded && (
            <Typography id="SystemInformation" fontSize={'body1'} mt={1}>
              {text}
              {url !== '' && (
                <Link
                  href={url}
                  underline="hover"
                  ml={1}
                  aria-label={linkAriaLabel}
                >
                  {isLinkExternal(url) && (
                    <OpenInNew
                      fontSize={'inherit'}
                      sx={{ mr: 1, position: 'relative', top: '0.15em' }}
                    />
                  )}
                  {urlLabel}
                </Link>
              )}
            </Typography>
          )}
          {text && (
            <Button
              sx={{ mt: 2 }}
              onClick={toggleExpandedInfo}
              aria-expanded={isInfoExpanded}
              aria-controls="SystemInformation"
            >
              {isInfoExpanded ? 'Läs mindre' : 'Läs mer'}
            </Button>
          )}
        </Box>
      </Box>
    )
  )
}

export default SystemInformation
