import { lazy, Suspense, useMemo } from 'react'
import { ExternalAssetsBoundary } from 'features/ErrorHandling/ExternalAssetsBoundary'
import { type AppShellDataProps } from '@trr/app-shell-data'
import { WidgetLoaderProps } from 'features/SystemLoaders/WidgetLoader/WidgetLoader.types'
import { useWidgetSettings } from './hooks'
import { useSharedState } from 'features/SystemLoaders/MicroFrontendLoader/hooks/useSharedState'
import LazyLoading from 'features/SystemLoaders/LazyLoading'
import { LazyLoadWrapper } from '@trr/internal-helpers'
import { Box, CircularProgress } from '@mui/material'

const WidgetLoader = ({ widgetType }: WidgetLoaderProps) => {
  const { content } = useWidgetSettings(widgetType)
  const widgetContent = content ?? {}
  const appShellData = useSharedState(widgetType, {})
  const widgetData: AppShellDataProps = {
    ...appShellData,
    content: widgetContent,
  }

  const LazyWidgetLoader = useMemo(
    () =>
      lazy(() => {
        return System.import(widgetType)
      }),
    [widgetType]
  )

  const hasMfTrackableFlag =
    window.FEATURE_FLAGS.includes(`gtm.enabled.${widgetType}`) ?? false

  const isDisabled = window.FEATURE_FLAGS.includes(
    `dashboard.${widgetType}.disabled`
  )

  if (isDisabled) {
    return null
  }

  return (
    <Box>
      <LazyLoadWrapper>
        <ExternalAssetsBoundary assetType="widget">
          <Suspense fallback={<LazyLoading component={<CircularProgress />} />}>
            <div
              data-mf-loaded={widgetType}
              data-mftrackable={hasMfTrackableFlag}
            >
              <LazyWidgetLoader appShellData={widgetData} />
            </div>
          </Suspense>
        </ExternalAssetsBoundary>
      </LazyLoadWrapper>
    </Box>
  )
}

export default WidgetLoader
