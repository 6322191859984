import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded'
import ArrowForwardRounded from '@mui/icons-material/ArrowForwardRounded'
import { Box, ButtonBase, styled } from '@mui/material'

interface CarouselArrowsProps {
  currentIndex: number
  setCurrentIndex: (value: number) => void
  setAnimateCards: (value: boolean) => void
  isMobile: boolean
  numberOfCards: number
}

const Arrows = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 2,
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
}))

const ArrowWrapper = styled(ButtonBase)(({ theme }) => ({
  padding: theme.spacing(1),
  height: '40px',
  borderRadius: '50%',
  backgroundColor: theme.palette.primary?.main,
  color: theme.palette.common?.white,
  ':hover': {
    backgroundColor: theme.palette.primary?.dark,
    color: theme.palette.common?.white,
  },
  '&:focus-visible': {
    backgroundColor: theme.palette.primary?.dark,
    color: theme.palette.common?.white,
  },
  ':disabled': {
    backgroundColor: theme.palette.action?.disabledBackground,
    color: theme.palette.common?.white,
  },
}))

const CarouselArrows = ({
  currentIndex,
  setCurrentIndex,
  setAnimateCards,
  isMobile,
  numberOfCards,
}: CarouselArrowsProps) => {
  const handleNextPage = () => {
    setAnimateCards(true)
    setCurrentIndex(currentIndex + 1)
  }

  const handlePreviousPage = () => {
    setAnimateCards(true)
    setCurrentIndex(currentIndex - 1)
  }

  const setDisabledNextButton = () => {
    const newIndex = isMobile ? numberOfCards - 1 : numberOfCards - 3

    if (currentIndex >= newIndex) {
      return true
    }
    return false
  }

  return (
    <Arrows data-testid="carousel-arrows">
      <ArrowWrapper
        aria-hidden="true"
        data-testid="prev-button"
        onClick={handlePreviousPage}
        disabled={currentIndex === 0}
      >
        <ArrowBackRounded />
      </ArrowWrapper>
      <ArrowWrapper
        aria-hidden="true"
        data-testid="next-button"
        onClick={handleNextPage}
        disabled={setDisabledNextButton()}
        sx={{
          ml: 2,
        }}
      >
        <ArrowForwardRounded />
      </ArrowWrapper>
    </Arrows>
  )
}

export default CarouselArrows
