import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from '@mui/material'
import { LicenseInfo } from '@mui/x-license'
import { store } from 'store'
import {
  themeOptions,
  defaultLocale,
  responsiveFontSizeOptions,
} from '@trr/mui-theme'
import { AuthenticationWrapper } from 'features/Authentication'
import { PrepareAppShell } from 'features/PrepareAppShell'
import { ApplicationInsightsProvider } from './features/Telemetry/ApplicationInsights'
import { AppSelector } from 'features/AppSelector'
import { FeatureFlagWrapper } from 'features/FeatureFlagWrapper'
import { GoogleTagManager } from 'features/Telemetry/GoogleTagManager'

LicenseInfo.setLicenseKey(
  '0e378c79a7cbbc87763ea2c9b136092eTz04Nzg4OCxFPTE3NDM5MzA0NDUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)
//  @ts-expect-error module not defined
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
if (module?.hot?.accept) {
  //  @ts-expect-error module not defined
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  module?.hot?.accept()
}

// Check if the user agent is Prerender if so we do not set the speedy option in the emotion cache. This allows prerender to evaluate the pages with CSS.
const shouldUseSpeedyOption = !navigator.userAgent
  .toLowerCase()
  .includes('prerender')
const emotionCache = createCache({
  key: 'css',
  speedy: shouldUseSpeedyOption,
})

const container = document.getElementById('root')
const root = createRoot(container)
let theme = createTheme(themeOptions, defaultLocale)
theme = responsiveFontSizes(theme, responsiveFontSizeOptions)

const RenderApplication = () => {
  return (
    <ApplicationInsightsProvider>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Provider store={store}>
            <BrowserRouter>
              <AuthenticationWrapper>
                <PrepareAppShell>
                  <HelmetProvider>
                    <FeatureFlagWrapper featureFlag="publicWeb.googleTagManager">
                      <GoogleTagManager />
                    </FeatureFlagWrapper>
                    <AppSelector />
                  </HelmetProvider>
                </PrepareAppShell>
              </AuthenticationWrapper>
            </BrowserRouter>
          </Provider>
        </ThemeProvider>
      </CacheProvider>
    </ApplicationInsightsProvider>
  )
}

root.render(
  <div>
    <RenderApplication />
  </div>
)
