import { styled, ButtonBase, Icon } from '@mui/material'

export const StyledSearchButton = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'transparent',
  borderRadius: '24px',
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: theme.typography.subtitle1.fontWeight,
  color: theme.palette.primary?.main,
  gap: theme.spacing(1),
  height: '48px',
  justifyContent: 'center',
  width: '48px',
  padding: 0,
  boxShadow: `0 0 0 1px ${theme.palette.primary?.light}`,

  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    padding: `0 ${theme.spacing(2)}`,
  },

  '&:hover': {
    backgroundColor: theme.palette.surface?.purple,
  },

  '&:focus': {
    outline: 'none',
    boxShadow: `0 0 0 2px ${theme.palette.primary?.main}`,

    '&:not(:focus-visible)': {
      boxShadow: `0 0 0 1px ${theme.palette.primary?.light}`,
    },
  },
}))

export const StyledAvatarButton = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.surface?.purple,
  borderRadius: '24px',
  color: theme.palette.primary?.main,
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: theme.typography.subtitle1.fontWeight,
  gap: theme.spacing(1),
  height: '48px',
  justifyContent: 'center',
  width: '48px',
  border: 0,

  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    padding: `0 ${theme.spacing(2)}`,
  },

  '&:hover': {
    backgroundColor: theme.palette.primary?.light,
  },

  '&:focus': {
    outline: 'none',
    boxShadow: `0 0 0 2px ${theme.palette.primary?.main}`,

    '&:not(:focus-visible)': {
      boxShadow: 'none',
    },
  },
}))

export const StyledAvatarIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.primary?.main,
}))
