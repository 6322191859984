import { PropsWithChildren } from 'react'
import { Box } from '@mui/material'
import { useSliceStateSelector } from 'slices/hooks'
import { useDashboard } from 'apps/Site/hooks'
import MenuArea from 'apps/Site/DashboardMenu/MenuArea'

const ContentContainer = ({ children }: PropsWithChildren) => {
  const { isMobile, isTablet } = useSliceStateSelector(
    (state) => state.slices.device.deviceSize
  )

  const isDashboardPage = useDashboard()

  const isMobileOrTablet = isMobile || isTablet
  const shouldShowDashboard = isDashboardPage && !isMobileOrTablet

  const conditionalPadding = isDashboardPage
    ? {
        px: { xs: 2, sm: 3, md: 6 },
        pl: { lg: 0 },
      }
    : {
        px: { xs: 2, sm: 3, md: 6 },
      }

  const conditionalFlex = isDashboardPage
    ? { display: 'flex', flexDirection: 'row' }
    : {}

  return (
    <Box sx={{ ...conditionalFlex, ...conditionalPadding, flex: '1 1 auto' }}>
      {shouldShowDashboard && <MenuArea />}
      <Box
        sx={{
          width: '100%',
          mx: 'auto',
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default ContentContainer
