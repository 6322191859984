export interface FeatureFlagProps {
  featureFlag: string
  children: JSX.Element
}

export const FeatureFlagWrapper = ({
  featureFlag,
  children,
}: FeatureFlagProps): JSX.Element =>
  window.FEATURE_FLAGS.includes(featureFlag) && children
