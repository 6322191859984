import { useCallback, useRef } from 'react'
import { ScrollWrapper } from 'apps/Site/Epi/ScrollWrapper'
import { Box, styled, Typography } from '@mui/material'
import { date as dateFormatter } from '@trr/frontend-datetime'
import ArticlesCardGrid from 'apps/Site/components/ArticlesCardGrid/ArticlesCardGrid'
import {
  convertPageListToCards,
  convertPageListToPhotoTextBlock,
  PageListItem,
} from 'utils/helpers'

export interface NewsListProps {
  heading: string
  text?: string
  news?: PageListItem[]
  fallbackUrl?: string
}

const Introduction = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  marginBottom: theme.spacing(7),
  maxWidth: '752px',
}))

const NewsList = ({ heading, text, news, fallbackUrl }: NewsListProps) => {
  const headingRef = useRef(null)

  const latestNews = news.at(0)
  const newsAsCards = convertPageListToCards(news.slice(1), fallbackUrl, true)
  const latestNewsFormattedPublishDate = dateFormatter.format(
    latestNews.startPublish,
    {
      style: 'numeric',
    }
  )
  const latestNewsCategory = latestNews.properties.customCategory
    ? `${latestNewsFormattedPublishDate} • ${latestNews.properties.customCategory}`
    : latestNewsFormattedPublishDate

  const firstArticle = convertPageListToPhotoTextBlock(
    latestNews,
    latestNewsCategory,
    true
  )

  const paginationCallback = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    headingRef.current.focus()
  }, [headingRef])

  return (
    <ScrollWrapper>
      <Introduction>
        <Typography
          variant="h1"
          tabIndex={-1}
          ref={headingRef}
          sx={{
            '&:focus': {
              outline: 0,
            },
          }}
        >
          {heading}
        </Typography>
        {text && <Typography variant="preamble">{text}</Typography>}
      </Introduction>
      {news?.length > 0 && (
        <ArticlesCardGrid
          firstArticle={firstArticle}
          articles={newsAsCards}
          paginationCallback={paginationCallback}
        />
      )}
    </ScrollWrapper>
  )
}

export default NewsList
