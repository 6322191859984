import { gtmLogger } from 'features/Telemetry/GoogleTagManager/gtmLogger'
import config from '../config'

const isElementNotInsideTrackingPane = (e: HTMLElement) => {
  const findPane = e.closest('[data-gtm-logger-pane]')
  if (findPane === null) return true
  return false
}

const isElementWithinTrackableArea = (e: HTMLElement) => {
  const findMf = e.closest('[data-mftrackable]')
  if (findMf === null) return true

  const potentialMf = findMf as HTMLElement
  const mfTrackingDisallowed =
    potentialMf?.dataset?.mftrackable?.toLowerCase() === 'true'

  return mfTrackingDisallowed
}

const isElementTrackingAllowed = (e: HTMLElement) => {
  return e?.dataset?.trackable !== 'false'
}

const isElementDataDiscernable = (e: HTMLElement) => {
  const dataEntries = config[e.tagName].assembleDataEntries(e)
  return Object.values(dataEntries).some((i) => Boolean(i))
}

const isElementNotTelSmsOrMail = (e: HTMLElement) => {
  if ('href' in e && typeof e.href === 'string') {
    if (e.href.startsWith('tel:') === true) {
      return false
    }
    if (e.href.startsWith('mailto:') === true) {
      return false
    }
    if (e.href.startsWith('sms:') === true) {
      return false
    }
    return true
  }
  return true
}

const isElementNotWithinModalOrTrackableModal = (e: HTMLElement) => {
  if (e?.closest<HTMLElement>("[role='dialog']") === null) return true
  if (e?.closest<HTMLElement>("[role='dialog']")?.dataset?.trackable === 'true')
    return true
}

const isTrackableElementType = (element: HTMLElement) => {
  const elementConfig = config[element.tagName]

  return elementConfig?.isTrackable(element) === true
}

const isElementTrackable = (element: HTMLElement) => {
  if (element === null) return false
  return (
    isElementNotInsideTrackingPane(element) &&
    gtmLogger.errorClick(
      isTrackableElementType,
      element,
      'isTrackableElementType'
    ) &&
    gtmLogger.errorClick(
      isElementWithinTrackableArea,
      element,
      'isElementWithinTrackableArea'
    ) &&
    gtmLogger.errorClick(
      isElementTrackingAllowed,
      element,
      'isElementTrackingAllowed'
    ) &&
    gtmLogger.errorClick(
      isElementNotTelSmsOrMail,
      element,
      'isElementNotTelSmsOrMail'
    ) &&
    gtmLogger.errorClick(
      isElementDataDiscernable,
      element,
      'isElementDataDiscernable'
    ) &&
    gtmLogger.errorClick(
      isElementNotWithinModalOrTrackableModal,
      element,
      'isElementNotWithinModalOrTrackableModal'
    )
  )
}

export default isElementTrackable
