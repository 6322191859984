import { FontIcon, AppshellLink } from '@trr/internal-helpers'
import { Box, Typography, styled } from '@mui/material'
import LinkedIn from '@mui/icons-material/LinkedIn'
import { EpiFooterContactLink } from 'state/PublicWebApi/Settings'

const ContactLink = ({
  heading,
  iconName,
  linkText,
  url,
}: EpiFooterContactLink) => {
  const ContactLinkWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    color: 'rgba(255,255,255, 0.75)',
    alignItems: 'center',
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  }))

  const IconWrapper = styled('span')(() => ({
    display: 'flex',
    color: 'white',
    alignItems: 'center',
  }))

  return (
    <ContactLinkWrapper>
      <IconWrapper aria-hidden="true">
        {iconName === 'LinkedIn' ? (
          <LinkedIn data-testid="linkedin-icon" />
        ) : (
          <FontIcon iconName={iconName} />
        )}
      </IconWrapper>

      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle1" component="h2">
          {heading}
        </Typography>
        <AppshellLink
          url={url}
          text={linkText}
          data-testid="contact-link-link"
          showIcon={false}
        />
      </Box>
    </ContactLinkWrapper>
  )
}

export default ContactLink
