import { useContext, useEffect, useState } from 'react'
import { List } from '@mui/material'
import { useSliceStateSelector } from 'slices/hooks'

import {
  FoldoutProps,
  MegaNavigationContext,
} from 'PublicWebApi/Settings/types'

import MedarbetareMenuList from 'apps/Site/Header/Medarbetare/MedarbetareMenuList'
import { DrawerWrapper, MenuWrapper } from './Menu.styles'
import { SubMenuContext } from '../Navbar/Navbar'
import Submenu from '../Submenu/Submenu'
import ContextLinks from '../ContextLinks'
import ActionButtons from '../ActionButtons'
import MenuItemLink from './MenuItemLink'
import MenuItemFoldout from './MenuItemFoldout'
import TopMenu from './TopMenu'
import { useTrackCustomClick } from 'features/Telemetry/GoogleTagManager/Hooks/useTrackCustomClick'

interface MenuProps {
  menuContent: MegaNavigationContext[]
  openMenu: boolean
  onClickMenuButton: () => void
}

const Menu = ({ menuContent, openMenu, onClickMenuButton }: MenuProps) => {
  const { visitorContextStatus } = useSliceStateSelector(
    (state) => state.slices.visitorContexts
  )

  const [submenuItems, setSubmenuItems] = useState<FoldoutProps[]>([])
  const [activeContext, setActiveContext] =
    useState<MegaNavigationContext | null>()

  const handleSubmenuItems = (items: FoldoutProps[]) => {
    setSubmenuItems(items)
  }
  const trackCustomClick = useTrackCustomClick()
  const { isSubmenuOpen } = useContext(SubMenuContext)

  useEffect(() => {
    if (openMenu) {
      window?.zE?.('messenger', 'hide')
    } else {
      window?.zE?.('messenger', 'show')
    }
  }, [openMenu])

  const onClickMenuNavLink = (label: string, url: string) => {
    trackCustomClick('Menu navlink', {
      label: label,
      href: url,
    })
    onClickMenuButton()
  }

  useEffect(() => {
    menuContent?.map((items) => {
      if (visitorContextStatus === items.role) {
        setActiveContext(items)
      }
    })
  }, [visitorContextStatus, menuContent, setActiveContext])

  return (
    <DrawerWrapper
      hideBackdrop
      anchor="right"
      open={openMenu}
      onClose={onClickMenuButton}
    >
      <MenuWrapper data-testid="menu">
        <TopMenu onClickMenuButton={onClickMenuButton} />
        {isSubmenuOpen ? (
          <Submenu
            SubmenuItems={submenuItems}
            onClickLink={onClickMenuButton}
          />
        ) : (
          <>
            <List
              sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}
              data-testid="menuitems-list"
            >
              {visitorContextStatus === 'medarbetare' ? (
                <MedarbetareMenuList onClick={onClickMenuButton} />
              ) : (
                <>
                  {activeContext?.menuItems?.map((menuItem, index) =>
                    menuItem.children?.length === 0 && menuItem.url ? (
                      <MenuItemLink
                        key={`menuitem-${index.toString()}`}
                        menuItem={menuItem}
                        onClickMenuButton={() =>
                          onClickMenuNavLink(menuItem.title, menuItem.url)
                        }
                      />
                    ) : (
                      <MenuItemFoldout
                        key={`menuitem-${index.toString()}`}
                        menuItem={menuItem}
                        settingSubmenuItems={handleSubmenuItems}
                      />
                    )
                  )}
                </>
              )}
            </List>

            <ActionButtons
              ctaButton={activeContext?.ctaButton}
              visitorContext={visitorContextStatus}
              onClick={onClickMenuButton}
            />

            <ContextLinks contexts={menuContent} onClick={onClickMenuButton} />
          </>
        )}
      </MenuWrapper>
    </DrawerWrapper>
  )
}

export default Menu
