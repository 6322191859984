import { ButtonBase, Link, alpha, styled } from '@mui/material'

interface ButtonBaseLinkProps {
  href?: string
  disableRipple?: boolean
  isMobile?: boolean
}

export const LogoLinkWrapper = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  border: 'solid',
  borderRadius: '2px',
  borderColor: 'transparent',
  borderWidth: '1px',

  '&:focus': {
    outline: 'none',
    borderColor: theme.palette.primary?.main,

    '&:not(:focus-visible)': {
      borderColor: 'transparent',
    },
  },
}))

export const StyledSecondaryActionButton = styled(ButtonBase, {
  shouldForwardProp: (props: string) => !['isMobile'].includes(props),
})<ButtonBaseLinkProps>(({ theme, isMobile }) => ({
  ...theme.typography.body1,
  display: 'flex',
  gap: theme.spacing(1),
  margin: theme.spacing(1),
  color: theme.palette.text.primary,
  textDecoration: 'none',
  border: 'solid',
  padding: theme.spacing(0.25),
  borderRadius: '2px',
  borderColor: 'transparent',
  borderWidth: '1px',

  ...(isMobile
    ? {
        width: '100%',
        justifyContent: 'flex-start',
        margin: 0,
        padding: `${theme.spacing(1, 1)}`,
        '&:focus-visible': {
          outline: 'none',
          color: theme.palette.text?.primary,
          backgroundColor: alpha(theme.palette.primary?.main, 0.3),
        },
        '&:hover': {
          backgroundColor: theme.palette.surface?.purple,
          color: theme.palette.primary?.main,
          svg: {
            color: theme.palette.primary?.main,
          },
        },
      }
    : {
        '&.active': {
          color: theme.palette.primary?.dark,
        },

        '&:focus': {
          outline: 'none',
          borderColor: theme.palette.primary?.main,

          '&:not(:focus-visible)': {
            borderColor: 'transparent',
          },
        },

        '&:hover': {
          color: theme.palette.primary?.main,
        },
      }),
}))
