import { setupHeaders } from 'state/helpers'
import { JmtStatus } from './jmtStatus'

const getJmtStatus = async (
  setter: React.Dispatch<React.SetStateAction<JmtStatus | undefined>>
) => {
  const headers = await setupHeaders(new Headers())
  const signal = new AbortController().signal
  await fetch(`${window.APP_CONFIGURATION.COMMON.KLIENT_API_URL}/jmt/status`, {
    headers,
    signal,
  })
    .then((res) => res.json())
    .then((data: { status: { status: JmtStatus } }) =>
      setter(data.status.status)
    )
    .catch(() => setter(undefined))
}

export default getJmtStatus
