import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { shouldShowLoginFlow } from '../Helpers'
import useUserRules from 'utils/hooks/useUserRules'

const useRedirectToLogin = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const userRules = useUserRules()

  const shouldBeAtLoginFlow = userRules.isUserProfileIncomplete()

  useEffect(() => {
    const isAtLoginFlow = shouldShowLoginFlow(pathname)

    if (isAtLoginFlow) return // no redirect needed
    if (shouldBeAtLoginFlow) {
      history.push(userRules.getLoginUrl())
    }
  }, [pathname, history, userRules, shouldBeAtLoginFlow])
}

export default useRedirectToLogin
