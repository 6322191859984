import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AuthState } from './types'
import { IdServerUser } from 'features/Authentication/userManager'

const initialState: AuthState = {
  isLoggedIn: false,
  idToken: undefined,
  accessToken: undefined,
  profile: {
    userRoles: [],
    idp: undefined,
    fullName: undefined,
    name: undefined,
    sub: undefined,
  },
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthState: {
      prepare: (user: IdServerUser) => ({
        payload: {
          isLoggedIn: true,
          idToken: user.id_token,
          accessToken: user.access_token,
          profile: {
            userRoles: user.profile?.roles,
            idp: user.profile?.idp,
            fullName: user.profile?.name,
            name: user.profile?.given_name,
            sub: user.profile?.sub,
          },
        },
      }),
      reducer: (state, action: PayloadAction<AuthState>): AuthState => ({
        ...state,
        ...action.payload,
      }),
    },
  },
})

export default authSlice
