import { Children, cloneElement, PropsWithChildren, ReactElement } from 'react'

import s from './DropdownMenu.module.scss'

interface ListSectionProps {
  displaySeparator?: boolean
}

export const DropdownMenuListSection = ({
  children,
  displaySeparator = true,
}: PropsWithChildren<ListSectionProps>) => {
  const numlistItems = Children.count(children)

  const listItems = Children.toArray(children).map(
    (child: ReactElement, indx) => {
      const isLastElement = numlistItems - 1 === indx

      return cloneElement(child, {
        ...(isLastElement && {
          className: s.BeforeDivider,
        }),
      })
    }
  )

  return (
    listItems.length > 0 && (
      <>
        {listItems}
        {displaySeparator && (
          <li role="separator" className={s.DropdownListDivider} />
        )}
      </>
    )
  )
}
