import { RootState } from 'store'
import { useSelector } from 'react-redux'

type sliceRootState = Pick<RootState, 'slices'>

const useSliceStateSelector = <TResult>(
  selector: (state: sliceRootState) => TResult
): TResult => useSelector<RootState, TResult>(selector)

export default useSliceStateSelector
