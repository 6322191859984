import { useEffect } from 'react'

export const useEventListener = <T extends Event>(
  type: string,
  callback: (event: T) => void
) => {
  useEffect(() => {
    window.addEventListener(type, callback)

    return () => {
      window.removeEventListener(type, callback)
    }
  }, [type, callback])
}
