import { MegaNavigationMenuItem } from 'PublicWebApi/Settings/types'
import { ListItem } from '@mui/material'
import { StyledMenuLink } from '../Menu.styles'
import { useTrackCustomClick } from 'features/Telemetry/GoogleTagManager/Hooks/useTrackCustomClick'

interface MenuItemLinkProps {
  menuItem: MegaNavigationMenuItem
  onClickMenuButton: () => void
}

const MenuItemLink = ({ menuItem, onClickMenuButton }: MenuItemLinkProps) => {
  const trackCustomClick = useTrackCustomClick()

  const onClick = (label: string, url: string) => {
    onClickMenuButton()
    trackCustomClick('Mobile menu navlink', {
      label: `Klick på ${label}`,
      href: url,
    })
  }

  return (
    <ListItem
      data-testid={`menu-linkitem-${menuItem.title}`}
      onClick={() => onClick(menuItem.title, menuItem.url)}
    >
      <StyledMenuLink href={menuItem.url}>{menuItem.title}</StyledMenuLink>
    </ListItem>
  )
}

export default MenuItemLink
