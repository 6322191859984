import {
  isLinkExternal,
  getFullImageUrl,
  getStandardSrcset,
  createImageAltTextFromFileName,
  LazyLoadWrapper,
} from '@trr/internal-helpers'

import { Box, Link, Typography, styled } from '@mui/material'
import OpenInNew from '@mui/icons-material/OpenInNew'
import FormatQuote from '@mui/icons-material/FormatQuoteRounded'
import { useSliceStateSelector } from 'slices/hooks'

export interface TestimonialBlockContent {
  quote: string
  body?: string
  image?: string
  link?: {
    text: string
    url: string
  }
  mediaUrl?: string
  isImageRight: boolean
}

export interface TestimonialBlockProps {
  content: TestimonialBlockContent
}

const StyledBox = styled(Box)(({ theme }) => ({
  flex: '12 1 0',
  margin: 0,
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(3),
  },
}))

const TestimonialBlock = ({
  content: { quote, body, link, image, mediaUrl, isImageRight },
}: TestimonialBlockProps) => {
  const { isMobile, isTablet } = useSliceStateSelector(
    (state) => state.slices.device.deviceSize
  )

  return (
    <Box
      display={'flex'}
      flexDirection={{
        xs: 'column',
        md: isImageRight ? 'row-reverse' : 'row',
      }}
      gap={7}
      component={'section'}
      pt={5}
    >
      {image && !isMobile && (
        <Box display={{ xs: 'none', md: 'block' }} flex={{ md: '3 1 0' }}>
          <LazyLoadWrapper component="span">
            <Box
              sx={{
                width: '100%',
                aspectRatio: '1 / 1',
                objectFit: 'cover',
                verticalAlign: 'middle',
                borderRadius: '50%',
                overflow: 'hidden',
              }}
              component={'img'}
              src={getFullImageUrl(mediaUrl, image, 576)}
              srcSet={getStandardSrcset(mediaUrl, image, [400])}
              sizes="(max-width: 164px) 100vw, 164px"
              alt={createImageAltTextFromFileName(image)}
              data-testid="testimonial-image"
            />
          </LazyLoadWrapper>
        </Box>
      )}

      <StyledBox as={'figure'}>
        <FormatQuote sx={{ fontSize: 56, mt: -2, mx: -1 }} color={'primary'} />
        <Box component={'blockquote'} m={0}>
          {quote && (
            <Typography mb={3} component="h2" variant="h5">
              {quote}
            </Typography>
          )}
          {body && !isMobile && (
            <Typography
              variant="body1"
              sx={{ display: { xs: 'none', md: 'block' } }}
            >
              {body}
            </Typography>
          )}

          {(isMobile || isTablet) && (
            <Box
              display={'flex'}
              alignItems={'center'}
              gap={2}
              mt={3}
              flexDirection={{ xs: 'row', md: 'column' }}
            >
              {image && (
                <LazyLoadWrapper component="span">
                  <Box
                    sx={{
                      minWidth: '60px',
                      minHeight: '60px',
                      borderRadius: '50%',
                      overflow: 'hidden',
                      display: { xs: 'block', md: 'none' },
                    }}
                    component={'img'}
                    src={getFullImageUrl(mediaUrl, image, 112)}
                    alt={createImageAltTextFromFileName(image)}
                    data-testid="testimonial-image-mobile-tablet"
                    style={{
                      width: '60px',
                      height: '60px',
                      aspectRatio: '1 / 1',
                      verticalAlign: 'middle',
                      objectFit: 'cover',
                    }}
                  />
                </LazyLoadWrapper>
              )}
              {body && (
                <Typography
                  variant="body1"
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                  data-testid="testimonial-body-mobile-tablet"
                >
                  {body}
                </Typography>
              )}
            </Box>
          )}

          {link?.url && link?.text && (
            <Box mt={2} sx={{ display: { xs: 'block', md: 'block' } }}>
              <Link
                href={link.url}
                target={isLinkExternal(link.url) ? '_blank' : undefined}
                data-testid="testimonial-link"
                rel={isLinkExternal(link.url) ? 'noreferrer' : undefined}
                underline="hover"
                aria-label={
                  isLinkExternal(link.url)
                    ? `${link.text} (öppnas i ny flik)`
                    : link.text
                }
              >
                {isLinkExternal(link.url) && (
                  <OpenInNew
                    fontSize={'inherit'}
                    sx={{ mr: 1, position: 'relative', top: '0.15em' }}
                  />
                )}
                {link.text}
              </Link>
            </Box>
          )}
        </Box>
      </StyledBox>
    </Box>
  )
}

export default TestimonialBlock
