import { styled, Box } from '@mui/material'

export const FooterWrapper = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.surface?.grey,
  ul: {
    margin: 0,
    paddingLeft: 0,
  },
  li: {
    display: 'flex',
    alignItems: 'center',
    svg: {
      marginRight: theme.spacing(1),
    },
  },
}))

export const TopRowWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: `${theme.spacing(5)} ${theme.spacing(2)}`,

  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(7)} ${theme.spacing(3)}`,
  },
  [theme.breakpoints.up('lg')]: {
    padding: `${theme.spacing(7)} ${theme.spacing(9)}`,
  },
}))

export const TopRowContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexBasis: '0%',
  flexGrow: 1,
  flexShrink: 1,
  flexDirection: 'column',
  gap: theme.spacing(3),
  maxWidth: '1140px',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}))

export const ColumnHeader = styled('h2')(({ theme }) => ({
  ...theme.typography.h6,
  marginBottom: theme.spacing(2),
  marginTop: 0,
  a: {
    fontFeatureSettings: ['lnum', 'pnum'],
    fontFamily: 'Raleway',
    color: theme.palette.primary.main,
    '&:hover, &:focus': {
      textDecoration: 'underline',
    },
  },
}))

export const List = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  a: {
    color: 'black',
    textDecoration: 'none',
    columnGap: 0,
    svg: {
      marginLeft: '-3px',
    },
    '&:hover, &:focus': {
      textDecoration: 'underline',
    },
  },
}))

export const BottomRowWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary?.dark,
  display: 'flex',
  justifyContent: 'center',
  padding: `${theme.spacing(7)} ${theme.spacing(2)}`,
  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(7)} ${theme.spacing(3)}`,
  },
  [theme.breakpoints.up('lg')]: {
    padding: `${theme.spacing(7)} ${theme.spacing(6)}`,
  },
}))

export const BottomRowContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexBasis: '0%',
  flexGrow: 1,
  flexShrink: 1,
  alignItems: 'baseline',
  justifyContent: 'space-between',
  maxWidth: '1140px',

  a: {
    ...theme.typography.subtitle2,
    color: 'white',
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}))
export const BottomRowLeft = styled('span')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(8),
    marginBottom: 0,
  },
}))

export const FooterLogo = styled('span')(({ theme }) => ({
  marginBottom: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    marginBottom: 0,
    svg: {
      verticalAlign: 'middle',
    },
  },
}))

export const ContactLinksWrapper = styled('nav')(({ theme }) => ({
  display: 'flex',
  flexBasis: 0,
  flexShrink: 1,
  flexGrow: 1,
  ul: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(1),
      flexDirection: 'row',
    },
    li: {
      marginBottom: 0,
      svg: {
        marginRight: 0,
      },
    },
  },
}))

export const ScreenReaderOnly = styled('h2')(() => ({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: '1px',
}))

export const InformationLinks = styled('ul')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  a: {
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'underline',
    },
  },
}))
