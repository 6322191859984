import { useEffect } from 'react'
import { matchPath, useHistory } from 'react-router-dom'

import { useSliceDispatch } from 'slices/hooks'
import { visitorContext } from 'slices/VisitorContext/types'

const useVisitorContext = (): void => {
  const trigger = useSliceDispatch()
  const { location } = useHistory()

  const updateState = (state: visitorContext) => {
    trigger('setContextStatus', state)
  }

  const matchesContextPath = (url: string, context: string): boolean => {
    const pattern = RegExp(`/${context}(?:\\/.*)?$`)

    return pattern.test(url)
  }

  useEffect(() => {
    if (
      matchesContextPath(location.pathname, 'privatperson') ||
      matchPath(location.pathname, {
        path: '/',
        exact: true,
        strict: true,
      })
    ) {
      updateState('privatperson')
    } else if (matchesContextPath(location.pathname, 'arbetsgivare')) {
      updateState('arbetsgivare')
    } else if (matchesContextPath(location.pathname, 'fortroendevald')) {
      updateState('fortroendevald')
    } else if (matchesContextPath(location.pathname, 'medarbetare')) {
      updateState('medarbetare')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])
}

export default useVisitorContext
