import { useEffect, useRef } from 'react'
import { decideDeviceSize } from 'utils/deviceSize'
import { useSliceDispatch } from 'slices/hooks'
import { deviceSizes } from 'slices/Device/types'

const jsonStringifyCompare = (a: deviceSizes, b: deviceSizes) => {
  return JSON.stringify(a) === JSON.stringify(b)
}

const useDeviceSize = (): void => {
  const currentDevice = useRef(decideDeviceSize(window.innerWidth))
  const trigger = useSliceDispatch()
  const handleResize = () => {
    if (
      !jsonStringifyCompare(
        decideDeviceSize(window.innerWidth),
        currentDevice.current
      )
    ) {
      currentDevice.current = decideDeviceSize(window.innerWidth)
      trigger('setDeviceSize', decideDeviceSize(window.innerWidth))
    }
  }
  useEffect(() => {
    addEventListener('resize', handleResize)

    return () => {
      removeEventListener('resize', handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useDeviceSize
