import { Box, styled } from '@mui/material'

interface RowItemProp {
  variant: 'double' | 'triple' | ''
}

interface VerticalListProp {
  useVerticalListDesign: boolean
}

export const HeadingAndPreambleWrapper = styled(Box, {
  shouldForwardProp: (prop: string) =>
    !['useVerticalListDesign'].includes(prop),
})<VerticalListProp>(({ theme, useVerticalListDesign }) => ({
  marginBottom: theme.spacing(4),
  ...(useVerticalListDesign && {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    padding: theme.spacing(8),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(5, 5, 5, 0),
    },
  }),
}))

export const FeatureListWrapper = styled(Box, {
  shouldForwardProp: (prop: string) =>
    !['useVerticalListDesign'].includes(prop),
})<VerticalListProp>(({ theme, useVerticalListDesign }) => ({
  ...(useVerticalListDesign && {
    flexDirection: 'column',
    flexGrow: 2,
    flexShrink: 1,
    flexBasis: 0,
    padding: theme.spacing(8),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(5),
    },
  }),
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: theme.spacing(4),
}))

export const FeatureListItem = styled(Box)<RowItemProp>(
  ({ theme, variant }) => ({
    width: '100%',
    wordBreak: 'break-word',
    ...(variant === 'triple' && {
      [theme.breakpoints.up('sm')]: {
        width: `calc(50% - ${theme.spacing(2)})`,
      },
      [theme.breakpoints.up('md')]: {
        width: `calc(33% - ${theme.spacing(2.4)})`,
      },
    }),
    ...(variant === 'double' && {
      [theme.breakpoints.up('sm')]: {
        flexBasis: `calc(50% - ${theme.spacing(2)})`,
      },
    }),
  })
)
