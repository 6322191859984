import { useAuthentication } from 'features/Authentication/Hooks'
import ContentErrorPage from './ContentErrorPage'
import {
  narrowHandledErrorCode,
  transformFastlyErrorCode,
} from 'apps/Site/Epi/Utils'
import { type FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { type SerializedError } from '@reduxjs/toolkit'
import { handledHttpErrors } from 'state/PublicWebApi/Settings'
import { useLocation } from 'react-router-dom'
import {
  foretagPathname,
  medarbetarePathname,
  tjanstepersonPathname,
  UserType,
} from 'features/Authentication/helpers'
import { Box, CircularProgress, Typography } from '@mui/material'
import { goToLogin } from '@trr/app-shell-communication'

export const ContentError = ({
  error,
}: {
  error: FetchBaseQueryError | SerializedError
}) => {
  const { pathname } = useLocation()
  const contentErrors = narrowHandledErrorCode(error)
  const { isLoggedIn } = useAuthentication()
  const handledErrors: handledHttpErrors[] = ['401', '403', '404']
  const managedErrorsExist = handledErrors.indexOf(contentErrors) > -1
  const errorType = transformFastlyErrorCode(contentErrors, isLoggedIn)
  const medarbetareErrorShouldLogin = pathname.startsWith(medarbetarePathname)
  const redirectToLoginUserType = () => {
    if (pathname.startsWith(foretagPathname)) {
      return UserType.customer
    }
    if (pathname.startsWith(tjanstepersonPathname)) {
      return UserType.client
    }
  }

  if (
    !medarbetareErrorShouldLogin &&
    managedErrorsExist &&
    errorType === '401'
  ) {
    goToLogin(
      `${window.location.pathname}/${window.location.search}`,
      redirectToLoginUserType(),
      'replace'
    ) // go to login if 401 and not medarbetare
    return null
  }

  if (
    medarbetareErrorShouldLogin &&
    managedErrorsExist &&
    errorType === '401'
  ) {
    return (
      <Box
        display="flex"
        flex="1 1 auto"
        gap={3}
        flexDirection={'column'}
        alignItems="center"
        py="10%"
      >
        <CircularProgress />
        <Typography variant={'h2'} component={'h1'}>
          Vänta medan vi loggar in dig.
        </Typography>
      </Box>
    )
  }

  if (managedErrorsExist) {
    return <ContentErrorPage statusCode={errorType} />
  }
}

export default ContentError
