import { combineReducers } from 'redux'
import { publicWebApi } from 'state/PublicWebApi'
import { slices } from 'slices'

const createRootReducer = combineReducers({
  slices,
  [publicWebApi.reducerPath]: publicWebApi.reducer,
})

export default createRootReducer
