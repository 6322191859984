import { type PropsWithChildren, useState } from 'react'
import { InView } from 'react-intersection-observer'

interface ILazyLoadWrapper {
  component?: 'div' | 'span' | 'section'
  className?: string
}

export const LazyLoadWrapper = ({
  component,
  className,
  children,
}: PropsWithChildren<ILazyLoadWrapper>): React.JSX.Element => {
  const [render, setRender] = useState(false)
  return (
    <InView
      as={component}
      onChange={setRender}
      className={className}
      triggerOnce
    >
      {render && children}
    </InView>
  )
}
