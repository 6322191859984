import {
  ArticleListItem,
  ArticleListItemProps,
} from 'apps/Site/components/ArticleList/ArticleListItem'
import ArticleList from 'apps/Site/components/ArticleList'

export interface IRelatedContentProps {
  relatedContent: ArticleListItemProps[]
}

const RelatedContent = ({ relatedContent }: IRelatedContentProps) => (
  <section>
    <ArticleList heading="Relaterat">
      {relatedContent.map((props, index) => (
        <ArticleListItem key={`related-content-${index}`} {...props} />
      ))}
    </ArticleList>
  </section>
)

export default RelatedContent
